import { apiConfig } from "../config/Config.js";
import { apiEndPoint } from "../constant/ApiConstant.js";
import { ApiService } from "../service/ApiService.js";
import UtilDateTime from "../util/UtilDateTime.js";
import { trimValue } from "../util/UtilFieldValidation.js";
import {
  SC_COMPANY_ID,
  SC_DIVISION_ID,
  SC_USER_ID,
  SC_GROUP_ID,
} from "../constant/StorageConstant.js";
import UserSessionController from "./UserSession/UserSessionController.js";

let UserDashboardCtrlr = {
  
  // ---------------------------------- User Dashbord Ride Alerts api -------------------------------------------------- //

  async getV1RideDataSummary(filter,startDateTime,endDateTime) {

    var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
    var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userId          = UserSessionController.getLocalSessionValue(SC_USER_ID);
    var dashboardFields = { durationType: filter };
   
    if (companyId.length > 0) {

      var reqParams = {
        companyId       : companyId,
        groupId         : groupId,
        divisionId      : divisionId,       
        userId          : userId,  
        dashboardType   : "V1_RIDE_SUMMARY",
        dashboardFields : JSON.stringify(dashboardFields),
        startDateTime   : startDateTime, 
        endDateTime     : endDateTime,
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );

    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }

  },

  // --------------------------------------------------------------------------------------------- //

  // ---------------------------------- User Dashbord Periodical Comparison Summary api -------------------------------------------------- //

  async  getV1UserPeriodicalComparisonSummary (period1StartDateTime,period2StartDateTime){
   
    var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
    var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userId          = UserSessionController.getLocalSessionValue(SC_USER_ID);    
    var dashboardFields = {durationType :"SELECTED_MONTH","period1StartDateTime": period1StartDateTime, "period2StartDateTime": period2StartDateTime}

    if (companyId.length > 0) {

      var reqPayload = {
          companyId       : companyId,
          groupId         : groupId,
          divisionId      : divisionId,
          userId          : userId,        
          dashboardType   : "V1_USER_PERIODICAL_COMPARISON_SUMMARY",
          dashboardFields : JSON.stringify(dashboardFields),          
      };

      return await ApiService.encryptCallGET(
          apiConfig.API_VERSION_1,
          apiEndPoint.DASHBOARD,
          1,
          reqPayload
      );

  } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
  }

  },

  // --------------------------------------------------------------------------------------------- //

  // ---------------------------------- User Dashbord Multi User Driving Score List api -------------------------------------------------- //

  async getV1MultiUserDrivingScoreList(filterDay,startDateTime,endDateTime) {
    
    var dashboardFields = {durationType : filterDay }
    var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
    var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userId          = UserSessionController.getLocalSessionValue(SC_USER_ID);

    if (companyId.length >0) {

        var reqParams = {
            companyId       : companyId,
            groupId         : groupId,
            divisionId      : divisionId,
            userId          : userId,
            dashboardType   : "V1_MULTI_USER_DRIVING_SCORE_LIST",           
            dashboardFields : JSON.stringify(dashboardFields),  
            startDateTime   : startDateTime,
            endDateTime     : endDateTime          
        };

        return await ApiService.encryptCallGET(
            apiConfig.API_VERSION_1,
            apiEndPoint.DASHBOARD,
            1,
            reqParams
        );

    } else {
        return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }

},

 // --------------------------------------------------------------------------------------------- //

 // ---------------------------------- User Dashbord User List table api -------------------------------------------------- //

 async getV1UserList (filter,startDateTime,endDateTime){

  var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
  var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
  var userId          = UserSessionController.getLocalSessionValue(SC_USER_ID);  
  var dashboardFields = {durationType : filter}

  if (companyId.length > 0) {

    var reqPayload = {
        companyId       : companyId,
        groupId         : groupId,
        divisionId      : divisionId,            
        dashboardType   : "V1_USER_LIST",
        dashboardFields : JSON.stringify(dashboardFields),   
        userId          : userId,
        startDateTime   : startDateTime, 
        endDateTime     : endDateTime,
    };

    return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
    );

} else {
    return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
}

},

// --------------------------------------------------------------------------------------------- //

// ---------------------------------- Vehicle Crash List api -------------------------------------------------- //

async getV1VehicleCrashList(
  indexStart,
  filter,
  deviceFilter,
  startDateTime,
  endDateTime,
  accidentTypeFilter,
  userId,
  pageSize
) {

  var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
  var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
  var reportFields    = {
                          durationType  : filter,
                          accidentType  : accidentTypeFilter,
                          deviceType    : deviceFilter,
                        };

  if (companyId.length > 0 && divisionId.length > 0) {

    var reqPayload = {
      companyId       : companyId,
      groupId         : groupId,
      divisionId      : divisionId,
      userId          : userId, 
      offset          : indexStart,
      limit           : pageSize,     
      dashboardType   : "V1_VEHICLE_CRASH_LIST",
      startDateTime   : startDateTime,
      endDateTime     : endDateTime,
      dashboardFields : JSON.stringify(reportFields),
    };

    return await ApiService.encryptCallGET(
      apiConfig.API_VERSION_1,
      apiEndPoint.DASHBOARD,
      1,
      reqPayload
    );

  } else {
    return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
  }

},

// --------------------------------------------------------------------------------------------- //

};

export default UserDashboardCtrlr;
