import React, { useState } from "react";
import LoaderPdf from "../../Common/loaderPdf";
import {
  Document,
  Line,
  PDFViewer,
  Page,
  Rect,
  Svg,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import PdfFooterV1 from "../Components/pdfFooterV1";
import PdfHeaderV1 from "../Components/pdfHeaderV1";
import PdfHeading from "../Components/pdfHeading";
import ValueFormat from "../../../util/ValueFormat";
import UtilDateTime from "../../../util/UtilDateTime";

import rank from "../../../assets/images/adminDashboard/pdf/pdf_rank.png";
import firstName from "../../../assets/images/adminDashboard/pdf/pdf_firstname.png";
import username from "../../../assets/images/adminDashboard/pdf/pdf_username.png";
import efficiency from "../../../assets/images/adminDashboard/pdf/pdf_efficiency.png";
import totalRides from "../../../assets/images/adminDashboard/pdf/pdf_total_rides.png";
import drivingScore from "../../../assets/images/adminDashboard/pdf/pdf_driving_score.png";
import anticipation from "../../../assets/images/adminDashboard/pdf/pdf_anticipation.png";
import selfConfidence from "../../../assets/images/adminDashboard/pdf/pdf_self_confidence.png";
import drivingSkill from "../../../assets/images/adminDashboard/pdf/pdf_driving_skill.png";
import drivingStyle from "../../../assets/images/adminDashboard/pdf/pdf_driving_style.png";
import drivingState from "../../../assets/images/adminDashboard/pdf/pdf_driver_state.png";
import mobScreen from "../../../assets/images/adminDashboard/pdf/pdf_mobile_screen.png";
import mobCall from "../../../assets/images/adminDashboard/pdf/pdf_mobile_call.png";
import kmsDriven from "../../../assets/images/adminDashboard/pdf/pdf_kms_driven.png";
import stressStrain from "../../../assets/images/adminDashboard/pdf/pdf_stress_strain.png";
import tdistance from "../../../assets/images/adminDashboard/pdf_distance.png";
import tduration from "../../../assets/images/adminDashboard/pdf_duration.png";
import i5 from "../../../assets/images/adminDashboard/pdf_high_alerts.png";
import i6 from "../../../assets/images/adminDashboard/pdf_medium_alerts.png";
import i7 from "../../../assets/images/adminDashboard/pdf_overspeed.png";
import i8 from "../../../assets/images/adminDashboard/pdf_mobile_call.png";
import i9 from "../../../assets/images/adminDashboard/pdf_mobile_screen.png";
import i10 from "../../../assets/images/adminDashboard/pdf_acceleration.png";
import i11 from "../../../assets/images/adminDashboard/pdf_braking.png";
import i12 from "../../../assets/images/adminDashboard/pdf_cornering.png";
import crash from "../../../assets/images/adminDashboard/pdf/pdf_crash.png";
import { displayText } from "../../../constant/MessageConstant";
import { globalPDF } from "../../../assets/css/commonCssTextForPdf";

import i4 from "../../../assets/images/adminDashboard/totalRides.svg";
import UserNameIcon from "../../../assets/images/adminDashboard/pdf/userNameImagePdf.png";
import LocationIcon from "../../../assets/images/adminDashboard/pdf/locationIconPdf.png";
import MinsIcon from "../../../assets/images/adminDashboard/pdf/minsIconPdf.png";
import AndroidIcon from "../../../assets/images/adminDashboard/pdf/androidIconPdf.png";
import WheelerIcon from "../../../assets/images/adminDashboard/pdf/wheelerIconPdf.png";
import ActivedateIcon from "../../../assets/images/adminDashboard/pdf/ActiveDateIconPdf.png";
import LastRideIcon from "../../../assets/images/adminDashboard/pdf/lastRideDateIcon.png";
import AnticipationIcon from "../../../assets/images/adminDashboard/pdf/AnticipationIconPdf.png";
import SelfConfidenceIcon from "../../../assets/images/adminDashboard/pdf/SelfconfidenceIconPdf.png";
import DrivingSkillcon from "../../../assets/images/adminDashboard/pdf/DrivingskillIconPdf.png";
import DrivingStylelcon from "../../../assets/images/adminDashboard/pdf/DrivingstyleIcon.png";
import DrivingStatelcon from "../../../assets/images/adminDashboard/pdf/DrivingStateIcon.png";
import MobileUsagelcon from "../../../assets/images/adminDashboard/pdf/MobileUsageIcon.png";
import OverSpeedlcon from "../../../assets/images/adminDashboard/pdf/OverSpeedIcon.png";
import Abclcon from "../../../assets/images/adminDashboard/pdf/AbcIcon.png";
import Countlcon from "../../../assets/images/adminDashboard/pdf/countIcon.png";
import CallCountlcon from "../../../assets/images/adminDashboard/pdf/CallcountIcon.png";
import ScreenCountlcon from "../../../assets/images/adminDashboard/pdf/ScreencountIcon.png";
import steeringWheel from "../../../assets/images/adminDashboard/pdf/steeringWheelIcon.png";
import IosIcon from "../../../assets/images/adminDashboard/pdf/iosIconPdf.png";

import totalRidesIcon from "../../../assets/images/adminDashboard/pdf/totalRidesIcon.png";
import userIconPdf from "../../../assets/images/adminDashboard/pdf/userIconPdf.png";
import totalDistanceIcon from "../../../assets/images/adminDashboard/pdf/totalDistanceIcon.png";
import totalDurationIcon from "../../../assets/images/adminDashboard/pdf/totalDurationIcon.png";
import highRiskIconPdf from "../../../assets/images/adminDashboard/pdf/highRiskIconPdf.png";



export default function AdOverallSummaryyPdf({
  ADTopRowItemsTemp,
  drivingDataList,  
  summaryData,
  aggregateData1,
  totalDays1,
  filtersADOS,
  selectedValue
}) {
  // console.log(ADRideBoxCount, "ADRideBoxCount");
  const [showLoader, setShowLoader] = useState();
  const heading = "Admin Dashboard (Overall Summary)";
  const fontBold = { fontFamily: "Helvetica-Bold" };
  console.log(aggregateData1,"selectedValue")
  var valueForSelect = "";
  if(selectedValue == "ACTIVE_USER_LIST"){
    valueForSelect = "Active Users"
  }else if(selectedValue == "ALL_USER_LIST"){
    valueForSelect = "Total Users"
  }else if(selectedValue == "UNREGISTERED_USER_LIST"){
    valueForSelect = "Unregistered Users"
  }else if(selectedValue == "DEACTIVATED_USER_LIST"){
    valueForSelect = "Deactivated Users"
  }else if(selectedValue == "INACTIVE_USER_LIST"){
    valueForSelect = "Inactive Users"
  }

  const months1 = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  var currentDate = new Date();
  const reversedDateFormat =
    currentDate.getDate().toString().padStart(2, "0") +
    "-" +
    months1[currentDate.getMonth()] +
    "-" +
    currentDate.getFullYear();
  let period = "";
  let startDate = "";
  let endDate = "";
  if (filtersADOS.durationFilter === "CURRENT_YEAR") {
    period = ValueFormat.smallLettersFormat(filtersADOS.durationFilter);
    startDate = UtilDateTime.getCurrentYear(filtersADOS.startDateTime);
    endDate = "";
  } else if (filtersADOS.durationFilter === "CURRENT_MONTH") {
    period = ValueFormat.smallLettersFormat(filtersADOS.durationFilter);
    startDate = UtilDateTime.getCurrentMonth(filtersADOS.startDateTime);
    endDate = "";
  } else if (filtersADOS.durationFilter === "CURRENT_WEEK") {
    period = ValueFormat.smallLettersFormat(filtersADOS.durationFilter);
    startDate = UtilDateTime.getCurrentWeek1(filtersADOS.startDateTime);
    endDate = "";
  } else if (filtersADOS.durationFilter === "CURRENT_DAY") {
    period = ValueFormat.smallLettersFormat(filtersADOS.durationFilter);
    startDate = UtilDateTime.getCurrentDay(filtersADOS.startDateTime);
    endDate = "";
  } else {
    period = ValueFormat.smallLettersFormat(filtersADOS.durationFilter);
    startDate = filtersADOS.startDateTime.split(" ")[0];
    endDate = filtersADOS.endDateTime.split(" ")[0];
  }
  const periodFormat = [period, startDate, endDate];

  const widget1 = ADTopRowItemsTemp.map((item) => {
    console.log(ADTopRowItemsTemp, "ADTopRowItemsTemp");
    const totalValue =
      ADTopRowItemsTemp.length > 0 ? ADTopRowItemsTemp[0].totalCount : 0;
    const percentage =
      totalValue !== 0 ? ((item.totalCount / totalValue) * 100).toFixed(2) : 0;
    return {
      title: item.title,
      value: item.totalCount,
      desc: item.description,
      percentage: percentage,
      totalValue: totalValue,
    };
  });
  const modifiedWidget1 = () => {
    const maxVal = widget1.length > 0 ? widget1[0].value : 0;
    return widget1.map((item) => {
      // Create a new object to avoid modifying the original data
      const sItem = { ...item };

      // Apply your conditions to each item
      // if (sItem.value > 0) {
        
      // } else {
      //   sItem.value = 0;
      // }
      if (sItem.value < maxVal) {
        sItem.value = ((sItem.value / maxVal) * 250).toFixed(1);
      } else {
        sItem.value = 250;
      }

      return sItem;
    });
  };
  const w1 = modifiedWidget1();
  
 
  
  const titleHead = [
    "Category",
    "Good Driving",
    "Quite Safe Driving",
    "Dangerous Driving",
    "Bad Driving",
  ];
  let goodDrivingCount = (drivingDataList || []).map(
    (item) => item.goodDrivingCount
  );
  let quietSafeDrivingCount = (drivingDataList || []).map(
    (item) => item.quietSafeDrivingCount
  );
  let dangerousDrivingCount = (drivingDataList || []).map(
    (item) => item.dangerousDrivingCount
  );
  let badDrivingCount = (drivingDataList || []).map(
    (item) => item.badDrivingCount
  );
  const T1 = {
    title: titleHead.map((title) => ({ title })),
    totalKm: [
      "Count",
      goodDrivingCount,
      quietSafeDrivingCount,
      dangerousDrivingCount,
      badDrivingCount,
    ],
    // totalTt: [
    //   "Minutes",
    //   ...(ADRideBoxCount.count || []).map((item) =>
    //     item && !isNaN(item.totalTravelTime)
    //       ? Math.round(item.totalTravelTime)
    //       : 0
    //   ),
    // ],
  };
  const titleHead1 = [
    "Total Days",
    "Total Users",
    "Total Rides",
    "Total Distance",
    "Total Duration",
    "High Risk Alerts",
    // "Total Amber Alerts",
  ];
  
  const originalData = summaryData.data || [
    {
      ABCPoint: "-",
      DSP: "-",
      DSTP: "-",
      drivingScore: "-",
      calculatedDrivingScore: "-",
      efficiency: "0",
      efficiencyTooltipValue: "0",
      firstName: "-",
      mobileScreenPointNew: "-",
      mobileUsePointNew: "-",
      overallAnticipation: "-",
      overallDrivingSkill: "-",
      drivingBehaviour: "-",
      overallSelfConfidence: "-",
      rank: "-",
      totalKmSPoint: "-",
      totalRides: "-",
      userId: "-",
      username: "-",
      tKiloMeterTV: "-",
      tTravelTimeTV: "-",
      totalHighRiskCount: "-",
      totalMediumRiskCount: "-",
      totalOverSpeedDurationTV: "-",
      totalOverSpeedCount: "-",
      totalMobileScreenScreenOnDurationTV: "-",
      totalMobileScreenScreenOnCount: "-",
      totalMobileUseInAcceptedDurationTV: "-",
      totalMobileUseInAcceptedCount: "-",
      totalAccelerationCount: "-",
      totalBrakingCount: "-",
      totalCorneringCount: "-",
    },
  ];
  const dupData = [...originalData, ...originalData, ...originalData];
  const data = originalData.map((item) => {
    // Extract and parse the necessary values
    const efficiencyValues = [
      parseFloat(item.calculatedDrivingScore),
      parseFloat(item.overallAnticipation),
      parseFloat(item.overallSelfConfidence),
      parseFloat(item.overallDrivingSkill),
      parseFloat(item.drivingBehaviourScore),
      parseFloat(item.DSTP),
      parseFloat(item.DSP),
      parseFloat(item.mobileScreenPointNew),
      parseFloat(item.mobileUsePointNew),
      parseFloat(item.totalKmSPoint),
      parseFloat(item.ABCPoint),
    ];

    // Calculate the sum of efficiency values
    const sum = efficiencyValues.reduce(
      (total, value) => total + (value || 0),
      0
    );

    // Determine rank based on sum
    let rank;
    if (
      parseFloat(item.calculatedDrivingScore) >= 0 &&
      parseFloat(item.calculatedDrivingScore) <= 80
    ) {
      rank = "Poor";
    } else if (
      parseFloat(item.calculatedDrivingScore) >= 81 &&
      parseFloat(item.calculatedDrivingScore) <= 93
    ) {
      rank = "Fair";
    } else {
      rank = "Good";
    }

    return {
      ...item,
      efficiency: `${item.calculatedDrivingScore}`,
      rank, // Update rank
      drivingScore: `${item.drivingScore}`,
      overallAnticipation: `${item.overallAnticipation}`,
      overallSelfConfidence: `${item.overallSelfConfidence}`,
      overallDrivingSkill: `${item.overallDrivingSkill}`,
      drivingBehaviour: `${item.drivingBehaviourScore}`,
      DSTP: `${item.DSTP}`,
      DSP: `${item.DSP}`,
      mobileScreenPointNew: `${item.mobileScreenPointNew}`,
      mobileUsePointNew: `${item.mobileUsePointNew}`,
      totalKmSPoint: `${item.totalKmSPoint}`,
      ABCPoint: `${item.ABCPoint}`,
    };
  });

  const chunks = [];
const firstChunkSize = 9;
const subsequentChunkSize = 11;

for (let i = 0; i < summaryData.data.length; i += subsequentChunkSize) {
  if (i === 0) {
    chunks.push(summaryData.data.slice(0, firstChunkSize));
    i = firstChunkSize - subsequentChunkSize; // adjust to align with subsequent chunking
  } else {
    chunks.push(summaryData.data.slice(i, i + subsequentChunkSize));
  }
}

  const drColNew = [
    "userDetails",
    "drivingScore",
    "drivingRisk",
    "drivingRiskData",
    "drivingBehaviour",
    "drivingBehaviourData",
    "crashProbability",
    "accBrakCorCounts",
    "overSpeedData",
    "mobileUsage",
    "redAlertCounts",
  ];

  const drTitleNew = {
    userDetails: "\nUser \nDetails",
    drivingScore: "\nDriving \nScore",
    drivingRisk: "\nDriving \nRisk",
    drivingRiskData: "\nDriving Risk \nData",
    drivingBehaviour: "\nDriving \nBehaviour ",
    drivingBehaviourData: "\nDriving Behaviour\nData",
    crashProbability: "\nCrash \nProbability",
    accBrakCorCounts: "\nAccBrakCor \nCounts",
    overSpeedData: "\nOver Speed \nData",
    mobileUsage: "\nMobile \nUsage",
    redAlertCounts: "\nRed Alert \nCounts",
  };

  const drCol = [
    "rank",
    "efficiency",
    "firstName",
    "username",
    "totalRides",
    "drivingScore",
    "overallAnticipation",
    "overallSelfConfidence",
    "overallDrivingSkill",
    "drivingBehaviour",
    "DSTP",
    "DSP",
    // "overSpeedPoint",
    "mobileScreenPointNew",
    "mobileUsePointNew",
    "totalKmSPoint",
    "ABCPoint",
  ];
  const drTitle = {
    rank: "\n \n",
    efficiency: "\n \n \n",
    firstName: "\n\n",
    username: "\n\n",
    totalRides: "\n \n ",
    drivingScore: "\nDriving \nRisk",
    overallAnticipation: "\n \nAnticipation",
    overallSelfConfidence: "\nSelf Confidence\n",
    overallDrivingSkill: "\n \nDriving Skill",
    drivingBehaviour: "\nDriving \nBehaviour",
    DSTP: "\n \nDriving Style",
    DSP: "\n \nDriver State",
    // overSpeedPoint: "\nOverspeed\nPoints",
    mobileScreenPointNew: "\nMobile Screen\nUsage",
    mobileUsePointNew: "\nMobile Call\nUsage",
    totalKmSPoint: "\nOverspeed \nKMS Driven",
    ABCPoint: "\n \nAcc / Brak / Cor",
  };

  const drColTwo = [
    "rating",
    "drivingScore",
    "name",
    "username",
    "totalRides",
    "drivingRiskData",
    "drivingBehaviorData",
  ];

  const drTitleTwo = {
    rating: "Rating",
    drivingScore: "Driving Score",
    name: "Name",
    username: "Username",
    totalRides: "Total Rides",
    drivingRiskData: "Driving Risk Data",
    drivingBehaviorData: "Driving Behavior Data",
  };

  const drImages = {
    rank: rank,
    firstName: firstName,
    username: username,
    efficiency: efficiency,
    totalRides: totalRides,
    drivingScore: drivingScore,
    overallAnticipation: anticipation,
    overallSelfConfidence: selfConfidence,
    overallDrivingSkill: drivingSkill,
    drivingBehaviour: drivingSkill,
    DSTP: drivingStyle,
    DSP: drivingState,
    // overSpeedPoint: i7,
    mobileScreenPointNew: mobScreen,
    mobileUsePointNew: mobCall,
    totalKmSPoint: kmsDriven,
    ABCPoint: stressStrain,
  };

  const chunksViolation = [];
  const firstChunkSizeViolation = 56;
  const subsequentChunkSizeViolation = 56;

  for (let i = 0; i < data.length; i += subsequentChunkSizeViolation) {
    if (i === 0) {
      chunksViolation.push(data.slice(0, firstChunkSizeViolation));
      i = firstChunkSizeViolation - subsequentChunkSizeViolation;
    } else {
      chunksViolation.push(data.slice(i, i + subsequentChunkSizeViolation));
    }
  }

  const violationCol = [
    "rank",
    "efficiency",
    "firstName",
    "username",
    "totalRides",
    "tKiloMeterTV",
    "tTravelTimeTV",
    "crashCount",
    "totalHighRiskCount",
    "totalMediumRiskCount",
    "totalOverSpeedDurationTV",
    "totalOverSpeedCount",
    "totalMobileScreenScreenOnDurationTV",
    "totalMobileScreenScreenOnCount",
    "totalMobileUseInAcceptedDurationTV",
    "totalMobileUseInAcceptedCount",
    "totalAccelerationCount",
    "totalBrakingCount",
    "totalCorneringCount",
  ];

  const violationTitle = {
    rank: "\n \nRating",
    efficiency: "\n Driving \nScore",
    firstName: "\n\nFirst Name",
    username: "\n\nUsername",
    totalRides: "\nTotal \nRides",
    tKiloMeterTV: "Total \nDistance\n (kms)",
    tTravelTimeTV: "Total\n Duration \n(Mins)",
    crashCount: "\nCrash\n(Count)",
    totalHighRiskCount: "\nHigh Risk\n (Count)",
    totalMediumRiskCount: "\nMedium Risk\n (Count)",
    totalOverSpeedDurationTV: "\nOverspeed\n (Mins)",
    totalOverSpeedCount: "\nOverspeed\n (Count)",
    totalMobileScreenScreenOnDurationTV: "Mobile\nScreen\n(Mins)",
    totalMobileScreenScreenOnCount: "Mobile\nScreen\n(Count)",
    totalMobileUseInAcceptedDurationTV: "\nMobile Call \n(Mins)",
    totalMobileUseInAcceptedCount: "\nMobile Call \n(Count)",
    totalAccelerationCount: "\nAcceleration\n (Count)",
    totalBrakingCount: "\nBraking\n (Count)",
    totalCorneringCount: "\nCornering \n(Count)",
  };

  const violationImages = {
    rank: rank,
    firstName: firstName,
    username: username,
    efficiency: efficiency,
    totalRides: totalRides,
    tKiloMeterTV: tdistance,
    tTravelTimeTV: tduration,
    crashCount: crash,
    totalHighRiskCount: i5,
    totalMediumRiskCount: i6,
    totalOverSpeedDurationTV: i7,
    totalOverSpeedCount: i7,
    totalMobileScreenScreenOnDurationTV: i9,
    totalMobileScreenScreenOnCount: i9,
    totalMobileUseInAcceptedDurationTV: i8,
    totalMobileUseInAcceptedCount: i8,
    totalAccelerationCount: i10,
    totalBrakingCount: i11,
    totalCorneringCount: i12,
  };

  return (
    <React.Fragment>
      {showLoader > 0 ? <LoaderPdf /> : ""}

      <PDFViewer
        style={{
          height: "96%",
          width: "100%",
          marginTop: "20px",
          marginBottom: "5px",
        }}
      >
        <Document style={{ marginTop: "15px", marginBottom: "5px" }}>
          {chunks.map((chunk, pageIndex) => (
            <Page key={pageIndex} size="A2">
              <PdfHeaderV1 />
              <View style={{ padding: 30, paddingTop: 10 }}>
                <PdfHeading
                  heading={heading}
                  periodFormat={periodFormat}
                  reversedDateFormat={reversedDateFormat}
                />

                {pageIndex === 0 && (
                  <View
                    style={{
                      marginTop: 10,
                      marginBottom: 5,
                      paddingVertical: 15,
                    }}
                  >
                   

                      <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        // justifyContent: "space-between",
                      }}
                    >
                    <View style={{width:"25%"}}>
                    <Text style={{ fontSize: 13, marginBottom: 15 }}>
                          1. User Summary
                        </Text>
                      <View style={{display:"flex",flexDirection:"column",justifyContent:"center",height:"30px",border:"1px solid lightgrey",padding:3}}>
                        <View style={{display:"flex",justifyContent:"space-between",flexDirection:"row",}}>
                        <Text style={{fontSize:"10px"}}>Total Users</Text>
                        <Text style={{fontSize:"10px",fontFamily: "Helvetica-Bold"}}> {widget1[0].value}</Text>
                        </View>
                      </View>
                      <View style={{ddisplay:"flex",flexDirection:"column",justifyContent:"center",height:"25px",border:"1px solid lightgrey",padding:3,borderTop:"0px"}}>
                      <View style={{display:"flex",justifyContent:"space-between",flexDirection:"row",}}>
                        <Text style={{fontSize:"10px"}}>Registered Users</Text>
                        <Text style={{fontSize:"10px",fontFamily: "Helvetica-Bold"}}>{widget1[1].value}</Text>
                        </View>
                      </View>
                      <View style={{display:"flex",flexDirection:"column",justifyContent:"center",height:"25px",border:"1px solid lightgrey",padding:3,borderTop:"0px"}}>
                      <View style={{display:"flex",justifyContent:"space-between",flexDirection:"row",}}>
                        <Text style={{fontSize:"10px"}}>Active Users</Text>
                        <Text style={{fontSize:"10px",fontFamily: "Helvetica-Bold"}}>{widget1[2].value}</Text>
                        </View>
                      </View>
                      <View style={{display:"flex",flexDirection:"column",justifyContent:"center",height:"25px",border:"1px solid lightgrey",padding:3,borderTop:"0px"}}>
                      <View style={{display:"flex",justifyContent:"space-between",flexDirection:"row",}}>
                        <Text style={{fontSize:"10px"}}>Inactive Users</Text>
                        <Text style={{fontSize:"10px",fontFamily: "Helvetica-Bold"}}>{widget1[3].value}</Text>
                        </View>
                      </View>
                      <View style={{display:"flex",flexDirection:"column",justifyContent:"center",height:"25px",border:"1px solid lightgrey",padding:3,borderTop:"0px"}}>
                      <View style={{display:"flex",justifyContent:"space-between",flexDirection:"row",}}>
                        <Text style={{fontSize:"10px"}}>Unregistered Users</Text>
                        <Text style={{fontSize:"10px",fontFamily: "Helvetica-Bold"}}>{widget1[4].value}</Text>
                        </View>
                      </View>
                      <View style={{display:"flex",flexDirection:"column",justifyContent:"center",height:"25px",border:"1px solid lightgrey",padding:3,borderTop:"0px"}}>
                      <View style={{display:"flex",justifyContent:"space-between",flexDirection:"row",}}>
                        <Text style={{fontSize:"10px"}}>Deactivated Users</Text>
                        <Text style={{fontSize:"10px",fontFamily: "Helvetica-Bold"}}>{widget1[5].value}</Text>
                        </View>
                      </View>
                    </View>
                    <View>
                    <Text style={{ fontSize: 13, marginBottom: 15,marginLeft:"8x" }}>
                            2. Driving Summary
                          </Text>
                          
                   
                    <View style={{display:"flex",flexDirection:"column",width:"100%",height:"155px"}}>                    
                    {drivingDataList.map((val, index) => (
                    <View key={index} style={{width:"100%",marginLeft:"7px",display:"flex",flexDirection:"row",border:"1px solid lightgrey",padding:5,height:'74px'}}>
                      
                      <View style={{display:"flex",flexDirection:"column",justifyContent:"center",width:"35%"}}> 
                     <View style={{marginLeft:"40px"}}>
                      <Svg
                            width="100%"
                            height="20"
                            // style={{ marginTop: 20}}
                          >
                            
                            <Rect
                              x="0"
                              y="0"
                              width="100"
                              height="15"
                              fill="#d9d9d9"
                            />
                            <Rect
                              x="0"
                              y="0"
                              width={val.goodDrivingPercentage}
                              height="15"
                              fill="#2ab57d"
                            />
                            <Text
                              x={45}
                              y={11}
                              textAnchor="middle"
                              alignmentBaseline="middle"
                              style={{ fontSize: "10px" }}
                            >
                              {val.goodDrivingPercentage == "NaN"
                                ? "0"
                                : val.goodDrivingPercentage + "%"}
                            </Text>
                            
                           
                          </Svg>          
                      <Text style={{fontSize:"10px",fontFamily: "Helvetica-Bold"}}>Good Driving</Text>
                      <Text style={{fontSize:"10px",marginTop:"5px",fontFamily: "Helvetica-Bold"}}>{`${val.goodDrivingCount} / ${val.drivingSummaryTotalRides}`}</Text>
                      
                      </View>
                      </View>


                      <View style={{display:"flex",flexDirection:"column",justifyContent:"center",width:"35%"}}>
                      <View style={{marginLeft:"60px"}}>
                      <Svg
                            width="100%"
                            height="20"
                            // style={{ marginTop: 20}}
                          >
                            
                            <Rect
                              x="0"
                              y="0"
                              width="100"
                              height="15"
                              fill="#d9d9d9"
                            />
                            <Rect
                              x="0"
                              y="0"
                              width={val.quitSafeDrivingPercentage}
                              height="15"
                              fill="#2ab57d"
                            />
                            <Text
                              x={45}
                              y={11}
                              textAnchor="middle"
                              alignmentBaseline="middle"
                              style={{ fontSize: "10px" }}
                            >
                             {val.quitSafeDrivingPercentage == "NaN"
                                ? "0"
                                : val.quitSafeDrivingPercentage + "%"}
                            </Text>
                            
                           
                          </Svg>     
                      <Text style={{fontSize:"10px",fontFamily: "Helvetica-Bold"}}>Quite Safe Driving</Text>
                      <Text style={{fontSize:"10px",marginTop:"5px",fontFamily: "Helvetica-Bold"}}>{`${val.quietSafeDrivingCount} / ${val.drivingSummaryTotalRides}`}</Text>
                     </View>
                      </View>


                      <View style={{display:"flex",flexDirection:"column",justifyContent:"center",width:"35%"}}>
                      <View style={{marginLeft:"70px"}}>
                      <Svg
                            width="100%"
                            height="20"
                            // style={{ marginTop: 20}}
                          >
                            
                            <Rect
                              x="0"
                              y="0"
                              width="100"
                              height="15"
                              fill="#d9d9d9"
                            />
                            <Rect
                              x="0"
                              y="0"
                              width={val.dangerousDrivingPercentage}
                              height="15"
                              fill="#ffbf53"
                            />
                            <Text
                              x={45}
                              y={11}
                              textAnchor="middle"
                              alignmentBaseline="middle"
                              style={{ fontSize: "10px" }}
                            >
                               {val.dangerousDrivingPercentage == "NaN"
                                ? "0"
                                : val.dangerousDrivingPercentage + "%"}
                            </Text>
                            
                           
                          </Svg>     
                      <Text style={{fontSize:"10px",fontFamily: "Helvetica-Bold"}}>Dangerous Driving</Text>
                      <Text style={{fontSize:"10px",marginTop:"5px",fontFamily: "Helvetica-Bold"}}>{`${val.dangerousDrivingCount} / ${val.drivingSummaryTotalRides}`}</Text>
                     </View>
                      </View>


                      <View style={{display:"flex",flexDirection:"column",justifyContent:"center",width:"35%"}}>
                      <View style={{marginLeft:"90px"}}>
                      <Svg
                            width="100%"
                            height="20"
                            // style={{ marginTop: 20}}
                          >
                            
                            <Rect
                              x="0"
                              y="0"
                              width="100"
                              height="15"
                              fill="#d9d9d9"
                            />
                            <Rect
                              x="0"
                              y="0"
                              width={val.badDrivingPercentage}
                              height="15"
                               fill="#ffbf53"
                            />
                            <Text
                              x={45}
                              y={11}
                              textAnchor="middle"
                              alignmentBaseline="middle"
                              style={{ fontSize: "10px" }}
                            >
                              {val.badDrivingPercentage == "NaN"
                                ? "0"
                                : val.badDrivingPercentage + "%"}
                            </Text>
                            
                           
                          </Svg>     
                      <Text style={{fontSize:"10px",fontFamily: "Helvetica-Bold"}}>Bad Driving</Text>
                      <Text style={{fontSize:"10px",marginTop:"5px",fontFamily: "Helvetica-Bold"}}>{`${val.badDrivingCount} / ${val.drivingSummaryTotalRides}`}</Text>
                      </View>
                      </View>
                    </View>
                  ))}
                    
                     <View style={{width:"100%",marginLeft:"7px",display:"flex",flexDirection:"row",height:"75px",marginTop:"5px"}}>
                      <View style={{border:"1px solid lightgrey",width:"160px",padding:5}}>
                      <Text style={{fontSize:"10px",fontFamily: "Helvetica-Bold",marginTop:"10px"}}>Total Days</Text>
                      <View style={{display:"flex",flexDirection:"row",marginTop:"20px" }}>
                        <Image src={ActivedateIcon} style={{ width: "15px", height: "15px",}} />
                        <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px"}}>{totalDays1}</Text>
                        </View>

                      </View>

                      <View style={{border:"1px solid lightgrey",width:"160px",marginLeft:"5px",padding:5}}>
                      <Text style={{fontSize:"10px",fontFamily: "Helvetica-Bold",marginTop:"10px"}}>Total Users</Text>
                      <View style={{display:"flex",flexDirection:"row",marginTop:"20px" }}>
                        <Image src={userIconPdf} style={{ width: "15px", height: "15px",}} />
                        <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px",fontFamily: "Helvetica-Bold"}}>{aggregateData1.totalUsers || 0}</Text>
                        </View>

                      </View>

                      <View style={{border:"1px solid lightgrey",width:"160px",marginLeft:"5px",padding:5}}>
                      <Text style={{fontSize:"10px",fontFamily: "Helvetica-Bold",marginTop:"10px"}}>Total Rides</Text>
                      <View style={{display:"flex",flexDirection:"row",marginTop:"20px" }}>
                        <Image src={totalRidesIcon} style={{ width: "15px", height: "15px",}} />
                        <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px",fontFamily: "Helvetica-Bold"}}>{aggregateData1.drivingSummaryTotalRides || 0}</Text>
                        </View>

                      </View>

                      <View style={{border:"1px solid lightgrey",width:"160px",marginLeft:"5px",padding:5}}>
                      <Text style={{fontSize:"10px",fontFamily: "Helvetica-Bold",marginTop:"10px"}}>Total Distance</Text>
                      <View style={{display:"flex",flexDirection:"row",marginTop:"20px" }}>
                        <Image src={totalDistanceIcon} style={{ width: "15px", height: "15px",}} />
                        <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px",fontFamily: "Helvetica-Bold"}}>{ aggregateData1.totalDistance + " Kms" || 0 + " Kms"}</Text>
                        </View>

                      </View>

                      <View style={{border:"1px solid lightgrey",width:"160px",marginLeft:"5px",padding:5}}>
                      <Text style={{fontSize:"10px",fontFamily: "Helvetica-Bold",marginTop:"10px"}}>Total Duration</Text>
                      <View style={{display:"flex",flexDirection:"row",marginTop:"20px" }}>
                        <Image src={totalDurationIcon} style={{ width: "15px", height: "15px",}} />
                        <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px",fontFamily: "Helvetica-Bold"}}>{aggregateData1.totalDuration ? (aggregateData1.totalDuration / 60).toFixed(2) + " hrs" : 0 + " hrs"}</Text>
                        </View>

                      </View>

                      <View style={{border:"1px solid lightgrey",width:"160px",marginLeft:"5px",padding:5}}>
                      <Text style={{fontSize:"10px",fontFamily: "Helvetica-Bold",marginTop:"10px"}}>High Risk Alerts</Text>
                      <View style={{display:"flex",flexDirection:"row",marginTop:"20px" }}>
                        <Image src={highRiskIconPdf} style={{ width: "15px", height: "15px",}} />
                        <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px",fontFamily: "Helvetica-Bold"}}>{ aggregateData1.totalHighRiskCountNew || 0}</Text>
                        </View>

                      </View>
                     </View>
                    
                    </View>
                    </View>

                    </View>

                   
                  </View>
                )}

                <View
                  style={{
                    marginBottom: 5,
                    paddingTop: 7,
                  }}
                >
                  <Text style={{ fontSize: 13, marginBottom: 7 }}>
                    3. Driving Score Summary {`(${valueForSelect} - ${summaryData.data.length})`}
                  </Text>

                  {/* <Text style={{ fontSize: 10, marginBottom: 7 }}>
                    {displayText.DRIVER_BEHAVIOUR_DESCRIPTION}
                  </Text>
                   */}
                  <View
                    style={{
                      // display:'flex',
                      flexDirection: "row",
                      backgroundColor: globalPDF.GREY_COLOR,
                      // justifyContent:"space-between",
                      height: "65px",
                      marginTop: 10,
                      
                    }}
                  >
                    <View 
                    style={{width:"253px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>User</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Details</Text>
                                              </View>
                    </View>

                    

                    <View 
                    style={{width:"77px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Score</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"60px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Risk</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"133px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving Risk</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Data</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"60px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Behaviour</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"133px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving Behaviour</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Data</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"67px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Crash</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Probability</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"97px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Acc/Brak/Cor</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Counts</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"87px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Over Speed</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Data</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"87px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Mobile</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Usage</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"77px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Red Alert</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Counts</Text>
                                              </View>
                    </View>

                  </View>

                  {chunk.map((apiData, index) => (
                    <View  key={index}>

                 
                  <View
                 
                    style={{
                      // display:'flex',
                      flexDirection: "row",
                      // justifyContent:"space-between",
                      height: "120px",
                      marginBottom:"0px",
                      border: "1px solid lightgrey",
                      // marginTop: 10,
                      
                    }}
                  >
                    
                    <View 
                    style={{width:"260px",height:"120px",padding:5,}}
                    >
                      <View style={{display:"flex",flexDirection:"row"}}>
                      <Image src={UserNameIcon} style={{ width: "15px", height: "15px" }} />
                      <Text style={{fontSize: 10,marginLeft:"5px",fontWeight:700,color:"#5156BE",fontFamily: "Helvetica-Bold",marginTop:"2px"}}>{apiData.firstName}</Text>
                      <Text style={{fontSize: 10,marginLeft:"5px",fontWeight:700,marginTop:"2px"}}>|</Text>
                      <Text style={{fontSize: 10,marginLeft:"5px",fontWeight:700,fontFamily: "Helvetica-Bold",marginTop:"2px"}}>{apiData.username}</Text>
                      <View style={{backgroundColor:"#D7EC23",marginLeft:"5px",padding:2}}>
                      <Text style={{fontSize: 10}}>{apiData.status}</Text>
                      </View>
                     
                      </View>

                      <View style={{display:"flex",flexDirection:"row",justifyContent:"space-between",marginTop:"5px"}}>
                        
                          <View style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                          <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                        <Image src={totalRides} style={{ width: "15px", height: "15px" }} />
                        <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px"}}>{apiData.totalRides}{" "}Rides</Text>
                        </View>
                        </View>

                        <View>
                          <View style={{display:"flex",flexDirection:"row"}}>
                          <Text style={{fontSize: 9,marginLeft:"5px",color:"#00A74B"}}>Good</Text>
                          <Text style={{fontSize: 9,marginLeft:"30px"}}>{apiData.goodDrivingCount == "NaN" ? "0" : apiData.goodDrivingCount}{" "}%</Text>
                          <Text style={{fontSize: 9,marginLeft:"2px"}}>{`(${apiData?.goodDrivingCountValue})`}</Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"3px"}}>
                          <Text style={{fontSize: 9,marginLeft:"5px",color:"#D1C600"}}>Quiet Safe</Text>
                          <Text style={{fontSize: 9,marginLeft:"10px"}}>{apiData.quietSafeDrivingCount == "NaN" ? "0" : apiData.quietSafeDrivingCount}{" "}%</Text>
                          <Text style={{fontSize: 9,marginLeft:"2px"}}>{`(${apiData?.quietSafeDrivingCountValue})`}</Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"3px"}}>
                          <Text style={{fontSize: 9,marginLeft:"5px",color:"#F14A21"}}>Dangerous</Text>
                          <Text style={{fontSize: 9,marginLeft:"8px"}}>{apiData.dangerousDrivingCount == "NaN" ? "0" : apiData.dangerousDrivingCount}{" "}%</Text>
                          <Text style={{fontSize: 9,marginLeft:"2px"}}>{`(${apiData?.dangerousDrivingCountValue})`}</Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"3px"}}>
                          <Text style={{fontSize: 9,marginLeft:"5px",color:"#CB0206"}}>Bad</Text>
                          <Text style={{fontSize: 9,marginLeft:"35px"}}>{apiData.badDrivingCount == "NaN" ? "0" : apiData.badDrivingCount}{" "}%</Text>
                          <Text style={{fontSize: 9,marginLeft:"2px"}}>{`(${apiData?.badDrivingCountValue})`}</Text>
                          </View>
                        </View>

                        <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                        <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                        <Image src={LocationIcon} style={{ width: "15px", height: "15px" }} />
                        <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px"}}>{apiData.tKiloMeter + " Kms"}</Text>
                        </View>
                        <View style={{display:"flex",flexDirection:"row",justifyContent:"center",marginTop:"5px"}}>
                        <Image src={MinsIcon} style={{ width: "15px", height: "15px" }} />
                        <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px"}}>{apiData.tTravelTime + " Mins"}</Text>
                        </View>

                        </View>
                      
                      </View>

                      <View style={{display:"flex",flexDirection:"row",justifyContent:"space-between",marginTop:"10px"}}>
                        {apiData.deviceType == "ANDROID" ?<View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                         <Image src={AndroidIcon} style={{ width: "15px", height: "15px" }} />
                        </View> : 
                        <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                        <Image src={IosIcon} style={{ width: "15px", height: "15px" }} />
                       </View>
}

                        
                          <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                          <View style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                        <Image src={WheelerIcon} style={{ width: "15px", height: "15px" }} />
                        <Text style={{fontSize: 9,marginLeft:"3px",marginTop:"2px"}}>{apiData.vehicleType}</Text>
                        </View>
                        </View>

                        <View style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                        <Image src={ActivedateIcon} style={{ width: "15px", height: "15px",marginTop:"5px" }} />
                        <View style={{marginLeft:"5px"}}>
                        <Text style={{fontSize: 9,color:"#5156BE"}}>Act.Date</Text>
                        <Text style={{fontSize: 9,marginTop:"5px",color:"#5156BE"}}>{apiData.activationDate}</Text>
                        </View>
                        </View>

                        <View style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                        <Image src={LastRideIcon} style={{ width: "15px", height: "15px",marginTop:"5px" }} />
                        <View style={{marginLeft:"5px"}}>
                        <Text style={{fontSize: 9,color:"#5156BE"}}>Last Ride</Text>
                        <Text style={{fontSize: 9,marginTop:"5px",color:"#5156BE"}}>{apiData.lastRideData}</Text>
                        </View>
                        </View>

                      </View>
                    </View>

                    <View  style={{width:"80px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"12px",fontWeight:700,fontFamily: "Helvetica-Bold"}}>{apiData.calculatedDrivingScore}</Text>
                        </View>
                        <View style={{display:"flex",flexDirection:"row",marginTop:"5px",justifyContent:"center"}}>
                        <Image src={steeringWheel} style={{ width: "15px", height: "15px" }} />
                        <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px"}}>{apiData.drivingCategory}</Text>
                        </View>                 
                    </View>


                    <View  style={{width:"60px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"12px",fontWeight:700,fontFamily: "Helvetica-Bold"}}>{ValueFormat.formatDecimalIfRounded(
                                      apiData.drivingScore
                                    )}</Text>
                        </View>                 
                    </View>

                    <View 
                    style={{width:"140px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",}}
                    >           
                    {/* <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                      <Text style={{fontSize:"12px",fontWeight:700,fontFamily: "Helvetica-Bold"}}>{ValueFormat.formatDecimalIfRounded(
                                    apiData.drivingScore
                                  )}</Text>
                      </View>     */}
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={AnticipationIcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Anticipation</Text>
                          <Text style={{fontSize: 9,marginLeft:"26px"}}>{`${ValueFormat.formatDecimalIfRounded(
                                        apiData.overallAnticipation
                                      )}`}</Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={SelfConfidenceIcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Self Confidence</Text>
                          <Text style={{fontSize: 9,marginLeft:"10px"}}>{ValueFormat.formatDecimalIfRounded(
                                        apiData.overallSelfConfidence
                                      )}</Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={DrivingSkillcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Driving Skill</Text>
                          <Text style={{fontSize: 9,marginLeft:"26px"}}> {ValueFormat.formatDecimalIfRounded(
                                        apiData.overallDrivingSkill
                                      )}</Text>
                          </View>                                
                    </View>



                    <View  style={{width:"60px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"12px",fontWeight:700,fontFamily: "Helvetica-Bold"}}>{apiData.drivingBehaviourScore}</Text>
                        </View>                 
                    </View>

                    <View 
                    style={{width:"140px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center"}}
                    >           
                    {/* <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                      <Text style={{fontSize:"12px",fontWeight:700,fontFamily: "Helvetica-Bold"}}>{apiData.drivingBehaviourScore}</Text>
                      </View>      */}
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={DrivingStylelcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Driving Style</Text>
                          <Text style={{fontSize: 9,marginLeft:"22px"}}>{`${ValueFormat.formatDecimalIfRounded(
                                        apiData.DSTP
                                      )}`}</Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={DrivingStatelcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Driving State</Text>
                          <Text style={{fontSize: 9,marginLeft:"21px"}}>{ValueFormat.formatDecimalIfRounded(
                                        apiData.DSP
                                      )}</Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={MobileUsagelcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Mobile Usage</Text>
                          <Text style={{fontSize: 9,marginLeft:"19px"}}>{apiData.mobileUsage}</Text>
                          </View>     
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={OverSpeedlcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Over Speed</Text>
                          <Text style={{fontSize: 9,marginLeft:"26px"}}>{ValueFormat.formatDecimalIfRounded(
                                        apiData.totalKmSPoint
                                      )}</Text>
                          </View>     
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={Abclcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>ABC</Text>
                          <Text style={{fontSize: 9,marginLeft:"56px"}}>{ValueFormat.formatDecimalIfRounded(
                                        apiData.ABCPoint
                                      )}</Text>
                          </View>                                
                    </View>

                    <View  style={{width:"70px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       
                    <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                      <Text style={{fontSize:"12px",fontWeight:700,fontFamily: "Helvetica-Bold"}}>-</Text>
                      </View>   

                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center",marginTop:"8px"}}>
                      
                        <Text style={{fontSize:"10px"}}>L0 is Safe</Text>
                        </View>  
                        <View style={{display:"flex",flexDirection:"row",justifyContent:"center",marginTop:"8px"}}>
                      
                        <Text style={{fontSize:"10px"}}>L6 is Unsafe</Text>
                        </View>                 
                    </View>

                    <View 
                    style={{width:"100px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}
                    >              
                          <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                          <Image src={DrivingStylelcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"8px"}}>Acc</Text>
                          <Text style={{fontSize: 9,marginLeft:"17px"}}> {apiData.totalAccelerationCount}</Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px",justifyContent:"center"}}>
                          <Image src={DrivingStatelcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"8px"}}>Brak</Text>
                          <Text style={{fontSize: 9,marginLeft:"15px"}}>{apiData.totalBrakingCount}</Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px",justifyContent:"center"}}>
                          <Image src={MobileUsagelcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"8px"}}>Cor
                          </Text>
                          <Text style={{fontSize: 9,marginLeft:"19px"}}>{apiData.totalCorneringCount}</Text>
                          </View>     
                                                      
                    </View>


                    <View 
                    style={{width:"90px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}
                    >              
                          <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                          <Image src={Countlcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"3px"}}>{apiData.totalOverSpeedCount}{" "}{"Count"}</Text>
                         
                          </View>

                          <View style={{display:"flex",flexDirection:"row",marginTop:"8px",justifyContent:"center"}}>
                          <Image src={MinsIcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>{apiData.totalOverSpeedDuration}{" "}{"Mins"}</Text>
                         
                          </View>
                          
                                                      
                    </View>

                    <View 
                    style={{width:"90px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}
                    >              
                          <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                          <Image src={ScreenCountlcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>{apiData.totalMobileScreenScreenOnCount}{" "}{"Count"}</Text>
                         
                          </View>

                          <View style={{display:"flex",flexDirection:"row",marginTop:"8px",justifyContent:"center"}}>
                          <Image src={MinsIcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>{apiData.totalMobileScreenScreenOnDuration}{" "}{"Mins"}</Text>
                         
                          </View>

                          <View style={{display:"flex",flexDirection:"row",marginTop:"8x",justifyContent:"center"}}>
                          <Image src={CallCountlcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>{apiData.totalMobileUseInAcceptedCount}{" "}{"Count"}</Text>
                         
                          </View>

                          <View style={{display:"flex",flexDirection:"row",marginTop:"8px",justifyContent:"center"}}>
                          <Image src={MinsIcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>{apiData.totalMobileUseInAcceptedDuration}{" "}{"Mins"}</Text>
                         
                          </View>
                          
                                                      
                    </View>

                    <View 
                    style={{width:"80px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}
                    >              
                          <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                          <Image src={Countlcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>{apiData.totalHighRiskCount}{" "}{"Count"}</Text>
                         
                          </View>

                          
                          
                                                      
                    </View>


                  </View>
                  </View>
                   ))}
                  {/* <View> */}
                    {/* <View
                                            style={{
                                                flexDirection: "row",
                                                height: "30px",
                                                marginTop: 5,
                                            }}
                                        >
                                            {drColTwo.map((column, index) => (
                                                <>
                                                <View
                                                    key={index}
                                                    style={{
                                                        textAlign: 'center',
                                                        flex: column === "rating" ? 0.54 : column === "drivingScore" ? 0.55 : column === "name" ? 0.93 : column === "username" ? 0.54 :  column === "totalRides" ? 0.55 : column === "drivingRiskData" ? 2.2 : 3.87,
                                                        justifyContent: "center",
                                                        backgroundColor: globalPDF.GREY_COLOR,
                                                        // alignItems: "flex-start",
                                                        // paddingLeft: 5,
                                                        fontSize: "9px",
                                                        border: "1px solid lightgrey",
                                                        borderBottom:"none",
                                                        borderLeft:
                                                            index === 0 ? "1px solid lightgrey" : "none",
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5, textAlign: "center" }}>
                                                        <View>
                                                            <Text style={fontBold} break> {drTitleTwo[column]} </Text>
                                                        </View>                                                       
                                                    </View>
                                                </View>
                                                </>
                                            ))}
                                        </View> */}
                    {/* <View
                      style={{
                        flexDirection: "row",
                        height: "70px",
                        marginTop: 0,
                      }}
                    >
                      {drCol.map((column, index) => (
                        <View
                          key={index}
                          style={{
                            flex: column === "firstName" ? 1.7 : 1,
                            justifyContent: "center",
                            backgroundColor: globalPDF.GREY_COLOR,
                           
                            fontSize: "9px",
                            border: "1px solid lightgrey",
                            borderLeft:
                              index === 0 ? "1px solid lightgrey" : "none",
                            flexDirection: 'column'
                          }}
                        >
                          <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5, textAlign: "center" }}>
                            <View style={{ height: '45px', paddingTop: 7 }}>
                              <Text style={fontBold} break> {drTitle[column]} </Text>
                            </View>
                            <View style={{ height: '25px' }}>
                              <Image src={drImages[column]} style={{ width: "15px", height: "15px" }} />
                            </View>
                          </View>
                        </View>
                      ))}
                    </View> */}
                    {/* {chunk.map((item, rowIndex) => (
                      <View
                        key={rowIndex}
                        style={{ flexDirection: "row", height: "25px" }}
                      >
                        {drCol.map((column, colIndex) => (
                          <View
                            key={colIndex}
                            style={{
                              flex: column === "firstName" ? 1.7 : 1,
                              justifyContent: "center",
                              fontSize: 9,
                              backgroundColor: column === "drivingScore" || column === "drivingBehaviour"  ?  globalPDF.LIGHT_BLUE_COLOR : "transparent",
                              borderLeft:
                                item[column] !== undefined
                                  ? "1px solid lightgrey"
                                  : "none",
                              borderRight:
                                colIndex === 6
                                  ? item[column] !== undefined
                                    ? "1px solid lightgrey"
                                    : "none"
                                  : colIndex === drCol.length - 1
                                    ? "1px solid lightgrey"
                                    : "none",
                              borderBottom:
                                item[column] !== undefined
                                  ? "1px solid lightgrey"
                                  : "none",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 9,
                                textAlign: "left",
                                paddingLeft: 10
                              }}
                              break
                            >
                              {item[column]}{item[column.username]}
                            </Text>
                          </View>
                        ))}
                      </View>
                    ))} */}
                  {/* </View> */}
                </View>
              </View>
              <PdfFooterV1
                pageNumber={pageIndex + 1}
                totalPages={chunks.length}
              />
            </Page>
          ))}

          {/* {chunksViolation.map((chunk, pageIndex) => (
            <Page key={pageIndex} size="A2">
              <PdfHeaderV1 />
              <View style={{ padding: 30, paddingTop: 10 }}>
                <PdfHeading
                  heading={heading}
                  periodFormat={periodFormat}
                  reversedDateFormat={reversedDateFormat}
                />
               
                <View style={{ marginBottom: 5, paddingVertical: 15 }}>
                  <Text style={{ fontSize: 13, marginBottom: 7 }}>
                    4. Dashboard for Violation
                  </Text>
                  <View>
                    <View
                      style={{
                        flexDirection: "row",
                        height: "70px",
                        marginTop: 5,
                      }}
                    >
                      {violationCol.map((column, index) => (
                        <View
                          key={index}
                          style={{
                            flex: column === "firstName" ? 1.7 : 1,
                            textAlign: 'center',
                            justifyContent: "center",
                            backgroundColor: globalPDF.GREY_COLOR,
                            fontSize: "9px",
                            border: "1px solid lightgrey",
                            borderLeft:
                              index === 0 ? "1px solid lightgrey" : "none",
                          }}
                        >
                          <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5, textAlign: "center" }}>
                            <View style={{ height: '45px', paddingTop: 7 }}>
                              <Text style={fontBold} break> {violationTitle[column]} </Text>
                            </View>
                            <View style={{ height: '25px' }}>
                              <Image src={violationImages[column]} style={{ width: "15px", height: "15px" }} />
                            </View>
                          </View>
                        </View>
                      ))}
                    </View>
                    {chunk.map((item, rowIndex) => (
                      <View
                        key={rowIndex}
                        style={{ flexDirection: "row", height: "25px" }}
                      >
                        {violationCol.map((column, colIndex) => (
                          <View
                            key={colIndex}
                            style={{
                              flex: column === "firstName" ? 1.7 : 1,
                              justifyContent: "center",
                              fontSize: 9,
                              backgroundColor: column === "totalRides" || column === "tKiloMeterTV" || column === "tTravelTimeTV" || column === "firstName" || column === "username" ? globalPDF.LIGHT_BLUE_COLOR : "transparent",
                              borderLeft:
                                item[column] !== undefined
                                  ? "1px solid lightgrey"
                                  : "none",
                              borderRight:
                                colIndex === 6
                                  ? item[column] !== undefined
                                    ? "1px solid lightgrey"
                                    : "none"
                                  : colIndex === violationCol.length - 1
                                    ? "1px solid lightgrey"
                                    : "none",
                              borderBottom:
                                item[column] !== undefined
                                  ? "1px solid lightgrey"
                                  : "none",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 9,
                                textAlign: "left",
                                paddingLeft: 10
                              }}
                              break
                            >
                              {item[column]}
                            </Text>
                          </View>
                        ))}
                      </View>
                    ))}
                  </View>
                </View>

              </View>
              <PdfFooterV1 pageNumber={chunks.length + pageIndex + 1} totalPages={chunks.length + chunksViolation.length} />
            </Page>
          ))} */}
        </Document>
      </PDFViewer>
    </React.Fragment>
  );
}
