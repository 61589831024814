import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Collapse } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import withRouter from "../Common/withRouter";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

import MyRideIcon from "../../assets/images/MyRideIcon.svg";
import ActiveMyRideIcon from "../../assets/images/ActiveMyRideIcon.svg";

import { SC_FCM_NOTIFICATION_COUNT , SC_FCM_VEHICLECRASHES_COUNT } from "../../constant/StorageConstant";

import Bell from "../../assets/images/bell.svg";
import ActiveBell from "../../assets/images/bellBlue.svg";

import CarCrashBlue from "../../assets/images/carCrashBlue.svg";
import CarCrash from "../../assets/images/carCrash.svg";

import ActiveDashboardIcon from "../../assets/images/ActiveDashboardIcon.svg";
import DashboardIcon from "../../assets/images/DashboardIcon.svg";

import UBIActiveDashboardIcon from "../../assets/images/UBIActiveDashboardIcon.svg";
import UBIDashboardIcon from "../../assets/images/UBIDashboardIcon.svg";

import NewUBIActiveDashboardIcon from "../../assets/images/UBIActiveDashboardIcon.svg";
import NewUBIDashboardIcon from "../../assets/images/UBIDashboardIcon.svg";


import ActiveSettingIcon from "../../assets/images/ActiveSettingIcon.svg";
import SettingIcon from "../../assets/images/SettingIcon.svg";
import classNames from "classnames";

import Badge from '@mui/material/Badge';



const Navbar = (props) => {
  const [app, setApp] = useState(false);
  // const [ubiApp, setUbiApp] = useState(false);
  const [previouspath, setPreviouspath] = useState(null);
  const { pathname } = useLocation();
  const dropdownRef = useRef(null); 
  // const dropdownUbiRef = useRef(null);

  const notificationValue = localStorage.getItem(SC_FCM_NOTIFICATION_COUNT) != "" ? parseInt(localStorage.getItem(SC_FCM_NOTIFICATION_COUNT)) : 0;
  const vehiclecrashValue = localStorage.getItem(SC_FCM_VEHICLECRASHES_COUNT) != "" ? parseInt(localStorage.getItem(SC_FCM_VEHICLECRASHES_COUNT)) : 0;

  const toggleAppDropdown = (e) => {
    e.preventDefault();
    setApp((prevApp) => !prevApp);
  };

  // const toggleUbiAppDropdown = (e) => {
  //   e.preventDefault();
  //   setUbiApp((prevUbiApp) => !prevUbiApp);
  // };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setApp(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if(pathname != previouspath) {
      if(previouspath == '/notifications') {
        if(parseInt(localStorage.getItem(SC_FCM_NOTIFICATION_COUNT)) > 0) {
          localStorage.setItem(SC_FCM_NOTIFICATION_COUNT,"0");
        }
      } else if (previouspath == '/vehicleCrashList') {
        if(parseInt(localStorage.getItem(SC_FCM_VEHICLECRASHES_COUNT)) > 0) {
          localStorage.setItem(SC_FCM_VEHICLECRASHES_COUNT,"0");
        }
      }
    }
    setPreviouspath(pathname);
  },[pathname]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownUbiRef.current && !dropdownUbiRef.current.contains(event.target)) {
  //       setApp(false);
  //     }
  //   };

  //   // Add event listener
  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     // Remove event listener on cleanup
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [dropdownUbiRef]);


  return (
    <React.Fragment>
      <div className="topnav cardHeader">

        <div className="container-fluid ps-0">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation" >
            <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content" >
              <ul className="navbar-nav">

                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none  p-0 m-3 ms-0" to="/dashboard" style={{ display: "flex", alignItems: "center" }} >
                    {(pathname === "/dashboard" || pathname === "/dashboard/RiskAlertSummary" || pathname === "/dashboard/CategorySummary") ? <img src={ActiveDashboardIcon} alt="ActiveDashboardIcon" height={30} width={30} /> : <img src={DashboardIcon} alt="DashboardIcon" height={30} width={30} />}
                    <span style={{ color: (pathname === "/dashboard" || pathname === "/dashboard/RiskAlertSummary" || pathname === "/dashboard/CategorySummary") ? "#5156be" : "#313533", paddingLeft: "4px", }}>{props.t("Dashboard")}</span>
                  </Link>
                </li>

                {/* <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none p-0 m-3" to="/drivingEfficeiency" style={{ display: "flex", alignItems: "center" }} >
                    {pathname === "/drivingEfficeiency" ? <img src={UBIActiveDashboardIcon} alt="UBIActiveDashboardIcon" height={30} width={30} /> : <img src={UBIDashboardIcon} alt="UBIDashboardIcon" height={30} width={30} />}
                    <span style={{ color: pathname === "/drivingEfficeiency" ? "#5156be" : "#313533", paddingLeft: "4px" }}>{props.t("Driving Behaviour")}</span>
                  </Link>
                </li> */}

                {/* <li className="nav-item dropdown">
                  <Link 
                    className="nav-link dropdown-toggle arrow-none p-0 m-3" 
                    to="/drivingEfficeiency" 
                    style={{ display: "flex", alignItems: "center", position: "relative" }}
                  >
                    {pathname === "/drivingEfficeiency" ? (
                      <img src={NewUBIActiveDashboardIcon} alt="UBIActiveDashboardIcon" height={30} width={30} />
                    ) : (
                      <img src={NewUBIDashboardIcon} alt="UBIDashboardIcon" height={30} width={30} />
                    )}
                    <span style={{ color: pathname === "/drivingEfficeiency" ? "#5156be" : "#313533", paddingLeft: "4px" }}>
                      {props.t("UBI Dashboard")}
                    </span>
                    <span 
                      style={{backgroundColor: "#ff0000", color: "#ffffff", borderRadius: "10px", padding: "2px 6px", fontSize: "10px", fontWeight: "bold",
                        marginLeft: "8px", position: "absolute", top: "-6px", right: "-25px"}}>
                      New
                    </span>
                  </Link>
                </li> */}


                {/* <li className="nav-item" ref={dropdownUbiRef}>
                  <Link to="/#" onClick={toggleUbiAppDropdown} className="nav-link dropdown-togglez arrow-none p-0 m-3" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {pathname === "/ubi-dashboard" || pathname === "/drivingEfficeiency" ?
                        <img src={UBIActiveDashboardIcon} alt="UbiIcon" height={30} width={30} /> :
                        <img src={UBIDashboardIcon} alt="NewUbiIcon" height={30} width={30} />}
                      <span style={{ color: pathname === "/ubi-dashboard" || pathname === "/drivingEfficeiency" ? "#5156be" : "#313533", paddingLeft: "4px" }}>{props.t("UBI Dashboard")} <div className="arrow-down"></div></span>
                    </div>
                  </Link>

                  <div className={classNames("dropdown-menu", { show: ubiApp })}>
                    <Link to="/ubi-dashboard" className="dropdown-item" onClick={() => setUbiApp(false)}>
                      {props.t("UBI Dashboard")}
                    </Link>
                    <Link to="/drivingEfficeiency" className="dropdown-item" onClick={() => setUbiApp(false)}>
                      {props.t("New UBI Dashboard")}
                    </Link>
                  </div>
                </li> */}

                {/* <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none p-0 m-3" to="/myRides" style={{ display: "flex", alignItems: "center" }} >
                    {pathname == "/myRides" ? <img src={ActiveMyRideIcon} alt="ride" height={30} width={30} /> : <img src={MyRideIcon} alt="ride" height={30} width={30} />}
                    <span style={{ color: pathname === "/myRides" ? "#5156be" : "#313533", paddingLeft: "4px" }}> {props.t("Rides")} </span>
                  </Link>
                </li> */}

                {/* <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none p-0 m-3" to="/notifications" style={{ display: "flex", alignItems: "center" }} >
                  {pathname == "/notifications" ? (
                          <>
                            {notificationValue > 0 ? (
                              <Badge badgeContent={`${notificationValue}`} color="error">
                                <img src={ActiveBell} alt="notifications" height={30} width={30} />
                              </Badge>
                            ) : (
                              <img src={ActiveBell} alt="notifications" height={30} width={30} />
                            )}
                          </>
                    ) : (
                          <>
                            {notificationValue > 0 ? (
                              <Badge badgeContent={`${notificationValue}`} color="error">
                                <img src={Bell} alt="Notifications" height={30} width={30} />
                              </Badge>
                            ) : (
                              <img src={Bell} alt="Notifications" height={30} width={30} />
                            )}
                          </>
                  )}
                    <span style={{ color: pathname === "/notifications" ? "#5156be" : "#313533", paddingLeft: "4px" }}> {props.t("Notifications")} </span>
                  </Link>
                </li> */}

                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none  p-0 m-3" to="/vehicleCrashList" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                    {pathname == "/vehicleCrashList" ? (
                        <>
                        { vehiclecrashValue > 0 ?(
                          <Badge badgeContent={`${vehiclecrashValue}`} color="error">
                            <img src={CarCrashBlue} alt="crashride" height={30} width={30} />
                          </Badge>
                        ) : (
                          <img src={CarCrashBlue} alt="crashride" height={30} width={30} />
                        )
                        }
                      </>
                      ) : (
                        <>
                          { vehiclecrashValue > 0 ?(
                            <Badge badgeContent={`${vehiclecrashValue}`} color="error">
                              <img src={CarCrash} alt="crashride" height={30} width={30} />
                            </Badge>
                          ) : (
                            <img src={CarCrash} alt="crashride" height={30} width={30} />
                          )
                          }
                        </>
                      )}
                      <span style={{ paddingLeft: "4px", color: pathname === "/vehicleCrashList" ? "#5156be" : "#313533" }} > {props.t("Vehicle Crashes")} </span>
                    </div>
                  </Link>
                </li>

                {/* <li className="nav-item" ref={dropdownRef}>
                  <Link to="/#" onClick={toggleAppDropdown} className="nav-link arrow-none p-0 m-3" style={{ display: "flex", alignItems: "center" }}>
                    {pathname === "/appBootSettings" ?
                      <img src={ActiveSettingIcon} alt="ActiveSettingIcon" height={30} width={30} /> :
                      <img src={SettingIcon} alt="SettingIcon" height={30} width={30} />
                    }
                    <span style={{ color: pathname === "/appBootSettings" ? "#5156be" : "#313533", paddingLeft: "4px" }}> {props.t("Settings")}
                      <div className="arrow-down"></div> </span>
                  </Link>
                  <div className={classNames("dropdown-menu", { show: app })}>
                    <Link to="/appBootSettings" className="dropdown-item" onClick={() => setApp(false)} > {props.t("App Boot Settings")} </Link>
                  </div>
                </li> */}

              </ul>
            </Collapse>
          </nav>
        </div>

      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
