import React, { useEffect, useState } from "react";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import ReactEcharts from "echarts-for-react";
import { useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Progress,
  Row,
  Table,
} from "reactstrap";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link, useNavigate } from "react-router-dom";
import { RideSetailsTopRowItems } from "../../common/data/ridedetaillist";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import CountUp from "react-countup";
import LineColumnAreaTwo from "../AllCharts/apex/lineColumnAreaTwo";
import CardImg1 from "../../assets/images/t_animal_crossing.png";
import Caution from "../../assets/images/t_caution.png";
import Curve from "../../assets/images/t_curves.png";
import Round from "../../assets/images/Round-about.svg";
import Hillimg from "../../assets/images/t_hill.png";
import HillDownWards from "../../assets/images/t_hill_downwards.png";
import hillUp from "../../assets/images/t_hill_upwards.png";
import icyConditions from "../../assets/images/t_icy_conditions.png";
import Intersection from "../../assets/images/t_intersection.png";
import LaneMerge from "../../assets/images/t_lane_merge.png";
import LowGearArea from "../../assets/images/t_low_gear_area.png";
import NarrowRoad from "../../assets/images/t_narrow_road.png";
import NoOverTaking from "../../assets/images/t_no_overtaking.png";
import PedestrianCrossing from "../../assets/images/t_pedestrian_crossing.png";
import Priority from "../../assets/images/t_priority.png";
import RailwayCrossing from "../../assets/images/t_railway_crossing.png";
import RiskOfGrounding from "../../assets/images/t_risk_of_grouping.png";
import School from "../../assets/images/t_school_zone.png";
import SlipperyRoads from "../../assets/images/t_slippery_road.png";
import Stopimg from "../../assets/images/t_stop.png";
import TrafficLight from "../../assets/images/t_traffic_light.png";
import WindImg from "../../assets/images/t_wind.png";
import WindingRoad from "../../assets/images/t_winding_road.png";
import YieldImg from "../../assets/images/t_yield.png";
import NormalImg from "../../assets/images/Ride_Details/Normall.png";
import TiredImg from "../../assets/images/Ride_Details/Tired.png";
import AngryImg from "../../assets/images/Ride_Details/Angry.png";
import SportiveImge from "../../assets/images/Ride_Details/Normal.png";
import EcoImg from "../../assets/images/Ride_Details/Eco.png";
import ChaoticImg from "../../assets/images/Ride_Details/Chaotic.png";
import AggressiveImg from "../../assets/images/Ride_Details/Aggressive.png";
import "../../pages/RideDetails/rideDetailstyle.css";
import { useLocation } from "react-router-dom";
import MyRideDetailController from "../../controller/MyRideDetail/MyRideDetail.js";
import ValueFormat from "../../util/ValueFormat";
import UtilDateTime from "../../util/UtilDateTime.js";
import AnalyticalController from "../../controller/Analytical/AnalyticalController";
import { alertDataType } from "../../constant/ModelConstant.js";
import Loader from "../../components/Common/loader";
import ExportScreenPdf from "../../components/ExportScreenPdf/index.js";
import { overSpeedDataTemps } from "./RideDetailOtherAlertData";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.heat/dist/leaflet-heat.js";
import OverSpeedIconLimit from "../../assets/images/adminDashboard/speedLimitIcon.svg";
import {
  SC_LOGIN_USER_FIRST_NAME,
  SC_USER_FIRST_NAME,
  SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import { SC_DIVISION_ID, SC_USER_ID } from "../../constant/StorageConstant";
import UserDrivingScoreController from "../../controller/Dashboard/UserDashboardController";
import { jsPDF } from "jspdf";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { displayText } from "../../constant/MessageConstant";
import ToastService from "../../components/Common/toastService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Heremap from "./Heremap";
import { protectionText } from "../../constant/protectionConstant.js";
import ViewPdf from "../../components/ExportScreenPdf/indexReact2Pdf.js";
import SpeedandRiskViolation from "./SpeedandRiskViolation.js";
import Converter from "../../components/Common/EncodeDecode.js";
import locationIcon from "../../assets/images/adminDashboard/myRidesPageIcons/overSpeedIcon.svg";
import minsIcon from "../../assets/images/adminDashboard/myRidesPageIcons/myRidesMins.svg";
import OverSpeedIcon from "../../assets/images/adminDashboard/myRidesPageIcons/overSpeedIconNew.svg";
import subTypeIcons from "../../assets/images/adminDashboard/myRidesPageIcons/subTypeIcons.svg";
import AccelerationIcon from "../../assets/images/adminDashboard/myRidesPageIcons/AccelerationIcon.svg";
import BreakingIcon from "../../assets/images/adminDashboard/myRidesPageIcons/BrakingIcon.svg";
import CorneringIcon from "../../assets/images/adminDashboard/myRidesPageIcons/CorneringIcon.svg";
import ScreenCountIcon from "../../assets/images/adminDashboard/myRidesPageIcons/ScreenCountIcon.svg";
import CountImage from "../../assets/images/adminDashboard/myRidesPageIcons/CountImage.svg";
import CallCountIconNewOne from "../../assets/images/adminDashboard/myRidesPageIcons/CallCountIconNew.svg";
import steeringWheel from "../../assets/images/adminDashboard/steering-wheel.svg";
import TripIconNew from "../../assets/images/adminDashboard/myRidesPageIcons/tripIsnoNew.svg";
import poiIconNew from "../../assets/images/adminDashboard/myRidesPageIcons/poiIconNew.svg";
import zipCodeIconNew from "../../assets/images/adminDashboard/myRidesPageIcons/zipCodeIconNew.svg";
import alertIconNew from "../../assets/images/adminDashboard/myRidesPageIcons/alertIconNew.svg";
import UBIAnticipation from "../../assets/images/adminDashboard/myRidesPageIcons/AnticipationIcon.svg";
import UBISelfConfidence from "../../assets/images/adminDashboard/myRidesPageIcons/SelfConfidenceIcon.svg";
import UBIDrivingSkill from "../../assets/images/adminDashboard/myRidesPageIcons/DrivingSkillIcon.svg";
import UBIDrivingStyle from "../../assets/images/adminDashboard/myRidesPageIcons/DrivingStyleIcon.svg";
import UBIDrivingState from "../../assets/images/adminDashboard/myRidesPageIcons/DrivingStateIcon.svg";
import UBIMobileCallUse from "../../assets/images/adminDashboard/myRidesPageIcons/MobileUsageIcon.svg";
import UBIStressStrain from "../../assets/images/adminDashboard/myRidesPageIcons/AccBrakingCorIcon.svg";
import DownloadIcon from "../../assets/images/adminDashboard/DownloadIconNew.svg";
import clock from "../../assets/images/adminDashboard/myRidesPageIcons/clockIcon.svg";
import battery from "../../assets/images/adminDashboard/myRidesPageIcons/chargerIcon.svg";
import DetailViewd from "../../assets/images/adminDashboard/myRidesPageIcons/journeyViewIconNew.svg";
import dayjs from "dayjs";
import ExportScreenDownloadPdf from "../../components/ExportScreenPdf/exportScreenDownloadPdf.js";
import MyRidesCtrlr from "../../controller/RideDashboardCtrlr.js";


const TableStyle = {
  table: {
    width: "100%",
    display: "table",
    borderSpacing: 0,
    border: "0.2px",
    margin: "0px",
  },
  th: {
    top: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: "#fff",
    height: "0px",
    fontWeight: 600,
    fontSize: "12px",
  },
};


const getOption = {
  color: ["#cc3333"],
  title: {
    left: "1%",
  },

  tooltip: {
    show: true,
    trigger: "item",
    formatter: function (parms) {
      const customToolTip =
        '<div style="padding:10px,textAlign:left;> <div class="mb-2">Speed : ' +
        parms.data.value +
        " kmph" +
        "</div>" +
        '<div class="mb-0">Date/Time : ' +
        parms.data.startDate +
        "</div>" +
        "<div> Distance : " +
        parms.data.kiloMeter +
        " kms" +
        "</div> </div>";
      return customToolTip;
    },
  },

  grid: {
    top: "8%",
    bottom: "35%",
    left: 35,
    right: 55,
  },

  xAxis: {
    data: [0],
    axisLabel: {
      rotate: 90,
      fontSize: "10px",      
      color:"#0095FF",
    },
    axisTick: {
      alignWithLabel: true,
    },
    type: "category",
    name: "All Data",
    nameLocation: "bottom",
    nameGap: 0,
    nameTextStyle: {
      align: "center",
      fontSize: "10px",  
      color:"#0095FF",
      verticalAlign: "top",
      padding: [128, 0, 0, 0],
    },
  },

  yAxis: {
    name: "Speed (Kms/hr)",
    type: "value",
    min: 0,
    max: 150,
    axisLabel: {
      align: "center",
      fontSize: "10px",      
      color:"#0095FF",
      padding: [0, 8, 0, 0],
    },
    nameTextStyle: {
      fontSize: "10px",  
      color:"#0095FF",
      padding: [60, 0, 0, 10]
    },
  },

  dataZoom: [
    {
      type: "inside",
      xAxisIndex: [0, 1],
      start: 0,
      end: 100,
      height: 10,      
    },

    {
      show: true,
      xAxisIndex: [0, 1],
      type: "slider",
      top: "90%",
      left: "10%",
      right: "25%",
      start: 0,
      end: 100,
      height: 10,      
    },
  ],

  series: {
    animationDuration: 4000,
    showSymbol: false,
    smooth: false,
    lineStyle: { normal: { width: 1.4 } },
    animationEasing: "easein",
    name: "Speed",
    type: "line",
    data: [0],
    data2: [0],
    markLine: {
      silent: false,
      lineStyle: {
        color: "#d3d3d3",
      },
    },
  },
};

const dataListNew = [
  {
    type: "ANIMAL_CROSSING",
    title: "Animal Crossing",
    overallData: 0,
    currentMonthGraphData: [0],
    image: CardImg1,
  },
  {
    type: "CAUTION",
    title: "Caution",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Caution,
  },
  {
    type: "CURVE",
    title: "Curve",
    overall: 0,
    currentMonthGraphData: [0],
    image: Curve,
  },  
  {
    type: "HILL",
    title: "Hill",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Hillimg,
  },
  {
    type: "HILL_DOWN",
    title: "Hill Downwards",
    overallData: 0,
    currentMonthGraphData: [0],
    image: HillDownWards,
  },
  {
    type: "HILL_UP",
    title: "Hill Upwards",
    overallData: 0,
    currentMonthGraphData: [0],
    image: hillUp,
  },
  {
    type: "ICY",
    title: "Icy Conditions",
    overallData: 0,
    currentMonthGraphData: [0],
    image: icyConditions,
  },
  {
    type: "INTERSECTION",
    title: "Intersection",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Intersection,
  },
  {
    type: "LANE_MERGE",
    title: "Lane Merge",
    overallData: 0,
    currentMonthGraphData: [0],
    image: LaneMerge,
  },
  {
    type: "LOW_GEAR",
    title: "Low Gear Area",
    overallData: 0,
    currentMonthGraphData: [0],
    image: LowGearArea,
  },

  {
    type: "NARROW_ROAD",
    title: "Narrow Road",
    overallData: 0,
    currentMonthGraphData: [0],
    image: NarrowRoad,
  },
  {
    type: "OVERTAKING",
    title: "No Overtaking",
    overallData: 0,
    currentMonthGraphData: [0],
    image: NoOverTaking,
  },

  {
    type: "CROSSING",
    title: "Pedestrian Crossing",
    overallData: 0,
    currentMonthGraphData: [0],
    image: PedestrianCrossing,
  },
  {
    type: "PRIORITY",
    title: "Priority",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Priority,
  },
  {
    type: "RAILWAY",
    title: "Railway Crossing",
    overallData: 0,
    currentMonthGraphData: [0],
    image: RailwayCrossing,
  },
  {
    type: "RISK_GROUNDING",
    title: "Risk Of Grounding",
    overallData: 0,
    currentMonthGraphData: [0],
    image: RiskOfGrounding,
  },
  {
    type: "ROUND",
    title: "Round About",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Round,
  },
  {
    type: "SCHOOL",
    title: "School",
    overallData: 0,
    currentMonthGraphData: [0],
    image: School,
  },
  {
    type: "SLIPPERY_ROADS",
    title: "Slippery Roads",
    overallData: 0,
    currentMonthGraphData: [0],
    image: SlipperyRoads,
  },
  {
    type: "STOP",
    title: "Stop",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Stopimg,
  },

  {
    type: "TRAFFIC_LIGHT",
    title: "Traffic Light",
    overallData: 0,
    currentMonthGraphData: [0],
    image: TrafficLight,
  },
  {
    type: "WIND",
    title: "Wind",
    overallData: 0,
    currentMonthGraphData: [0],
    image: WindImg,
  },
  {
    type: "WINDING_ROAD",
    title: "Winding Road",
    overallData: 0,
    currentMonthGraphData: [0],
    image: WindingRoad,
  },
  {
    type: "YIELD",
    title: "Yield",
    overallData: 0,
    currentMonthGraphData: [0],
    image: YieldImg,
  },
];

const otherDataList = [
  {
    type: "OVER_SPEED",
    name: "Overspeed",
    img: "overspeed.svg",
    count: 0,
    duration: 0,
    kilometer: 0,
  },
  {
    type: "MOBILE_CALL_USE",
    name: "Mobile call use",
    img: "mobilecallImage.svg",
    count: 0,
    duration: 0,
    kilometer: 0,
  },
  {
    type: "MOBILE_SCREEN_USE",
    name: "Mobile screen use",
    img: "mobileScreen.svg",
    count: 0,
    duration: 0,
    kilometer: 0,
  },
];

const GOOGLE_MAP_API_KEY = "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE";

const RideDetails = () => {
  const history = useNavigate();  
  const [redRiskDistanceData, setRedRiskDistanceData] = useState(0);
  const [dataLength, setDataLength] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [rideNameData, setRideNameData] = useState("");
  const [loginType, setLoginType] = useState(
    localStorage.getItem(SC_USER_LOGIN_TYPE)
  ); 
  const [locationParams, setLocationParams] = useState({
    rideId: "",
    userId: "",
    divisionId: "",
  }); 
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_LOGIN_USER_FIRST_NAME)
  );
  const [userName, setUserName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  useEffect(() => {
    setUserFirstName(localStorage.getItem(SC_LOGIN_USER_FIRST_NAME));
  }, [userFirstName]);

  const WScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  useEffect(() => {
    WScroll();
  }, []);
  const Location = useLocation();
  const [rideCategory, setRideCategory] = useState(""); 

  useEffect(() => {
    const queryParams = new URLSearchParams(Location.search);

    const UID = queryParams.get("userId");
    const DID = queryParams.get("divisionId");
    const RID = queryParams.get("rideId");
    const RIDE_CATEGORY = queryParams.get("rideCategory"); // ridecategory added

    const decodedUID = Converter.decrypt(decodeURIComponent(UID));
    const decodedDID = Converter.decrypt(decodeURIComponent(DID));
    const decodedRID = Converter.decrypt(decodeURIComponent(RID));
    const decodedRIDE_CATEGORY = Converter.decrypt(decodeURIComponent(RIDE_CATEGORY));
    setRideCategory(decodedRIDE_CATEGORY); // ridecategory added
    setLocationParams((prev) => {
      return {
        ...prev,
        rideId: decodedRID,
        userId: decodedUID,
        divisionId: decodedDID,
      };
    });
  }, [Location]);
  // _______________________________________________________
   
  const [stressData, setstressData] = useState([]);
  const [mobileScreenUse, setMobileScreenUse] = useState([]);
  const [screenUseData, setScreenUseData] = useState(false);
  const [callUseData, setCallUseData] = useState(false);
  const [mobileCallUseDataList, setMobileCallUseDataList] = useState([]);
  const [overSpeedTableList, setOverSpeedTableList] = useState([]);
  const [speedData, setSpeedData] = useState(false);
  const [lineOption, setLineOption] = useState(getOption);  
  const [chartWidthSpeedChart, setChartWidthSpeedChart] = useState("98vw");
  const [rideDataDisplay, setRideDataDisplay] = useState(false);
  const [riskAlertsDataDisplay, setRiskAlertsDataDisplay] = useState(false);
  const [mapDisplay, setMapDisplay] = useState(false);  
  const [drivingStyleDataCount, setDrivingStyleDataCount] = useState(null);
 
  const passLatlog = () => {
    const element = document.getElementById("mapContainerDisplay");

    if (element) {
      element.scrollIntoView({
        behavior: "smooth", 
        block: "start",
      });
    }
  };  

  const [distributionData, setDistributionData] = useState({
    xaxis: [],
    data: [],
    customData: [],
  }); 
  const [driverStateList, setDriverStateList] = useState({
    driverNormal: 0,
    driverTried: 0,
    driverAngry: 0, 
    
    distanceStateNormal: 0,
    distanceStateTired: 0,
    distanceStateAngry: 0,
  
    durationStateNormal: 0,
    durationStateTired: 0,
    durationStateAngry: 0,
   
  });
  const [driverStyleList, setDriverStyleList] = useState({
    drivingSportive: 0,
    drivingAggressive: 0,
    drivingChaotic: 0,
    drivingNormal: 0,
    drivingEco: 0,
   

    distanceStyleSportive: 0,
    distanceStyleAggressive: 0,
    distanceStyleChaotic: 0,
    distanceStyleNormal: 0,
    distanceStyleEco: 0,


    durationStyleSportive: 0,
    durationStyleAggressive: 0,
    durationStyleChaotic: 0,
    durationStyleNormal: 0,
    durationStyleEco: 0,   
  }); 

  const [riskDataTable, setRiskDataTable] = useState([]);
 
  function splitArrayIntoChunks(arr) {
    const chunkSize = 2;
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }

  const [riskDataList, setRiskDataList] = useState(splitArrayIntoChunks(dataListNew) || []);
  const [riskDataListleng, setRiskDataListleng] = useState(null);
  const [alertCount, setAlertCount] = useState(0);  
  const [totalAlerts, setTotalAlerts] = useState(0); 
  const [showLoader, setShowLoader] = useState(0); 
  const [histogramData, setHistogramData] = useState(false); 
  const [acbTableData, setAcbTableData] = useState(false);  
  const [driverStateDetectionData, setDriverStateDetection] = useState(null);
 

  const cppFunction = (data) => {
    let value = "";
    let colorCode = "";
    let levelDescription = "";

    if (data > 0 && data <= 50) {
      value = "L1";
      colorCode = "#ff9b9b";
      levelDescription = "Range (1-50)";
    } else if (data > 50 && data <= 100) {
      value = "L2";
      colorCode = "#ff8585";
      levelDescription = "Range (50-100)";
    } else if (data > 100 && data <= 150) {
      value = "L3";
      colorCode = "#ff6e6e";
      levelDescription = "Range (100-150)";
    } else if (data > 150 && data <= 200) {
      value = "L4";
      colorCode = "#ff5757";
      levelDescription = "Range (150-200)";
    } else if (data > 200 && data <= 250) {
      value = "L5";
      colorCode = "#ff4040";
      levelDescription = "Range (200-250)";
    } else if (data == 0) {
      value = "L0";
      colorCode = "#7ABA78";
      levelDescription = "Range (0)";
    } else if (data > 250) {
      value = "L6";
      colorCode = "#ff2929";
      levelDescription = "Range (>250)";
    } else {
      value = "-";
      colorCode = "#ffff";
      levelDescription = "No point available";
    }

    return { value, colorCode, levelDescription };
  }; 


  const getRideDeatilsData = async () => {  
    
    var result = await MyRidesCtrlr.getV1RideDeatils(
      locationParams.divisionId,
      locationParams.userId,
      locationParams.rideId,     
    );
    
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    var updatedTableData = [];

    if (status == "SUCCESS") {

      var resultJSONData = resultJSON.data;

      setDataLength(resultJSONData.length);

      console.log(resultJSONData, "resultJSONDataresultJSONData");

      resultJSONData.map((value) => { 

        var riskSlot10count                   = ValueFormat.parseAsInt(value.riskSlot10count);       
        var riskSlot10Distance                = ValueFormat.parseAsFloat(value.riskSlot10DistanceInKm,2);
        var riskSlot10Duration                = ValueFormat.parseAsFloat(value.riskSlot10DurationInMin,2);  
        var userId                            = ValueFormat.parseAsString(value.userId);
        var divisionId                        = ValueFormat.parseAsString(value.divisionId);
        var rideId                            = ValueFormat.parseAsString(value.deviceId); 
        var rideName                          = ValueFormat.parseAsString(value.deviceName);
        setRideNameData(rideName)
        var drivingCategory                   = ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(value.calculatedDrivingCategory));        
        var calculatedDrivingScore            = ValueFormat.parseAsFloat(value.calculatedDrivingScore,0) || 0;        
        var calculatedDrivingScoreValue       = ValueFormat.parseAsString(value.calculatedDrivingScore) || 0;
        var userId                            = ValueFormat.parseAsString(value.userId);
        var overallDrivingScore               = ValueFormat.parseAsFloat(value.drivingScore, 0) || 0;
        var overallAnticipation               = ValueFormat.parseAsFloat(value.anticipationScore, 0) || 0;
        var overallSelfConfidence             = ValueFormat.parseAsFloat(value.selfConfidenceScore, 0) || 0;
        var overallDrivingSkill               = ValueFormat.parseAsFloat(value.drivingSkillScore, 0) || 0;
        var dStyleValue                       = ValueFormat.parseAsFloat(value.drivingStyleScore, 0) || 0;
        var dStateValue                       = ValueFormat.parseAsFloat(value.drivingStateScore, 0) || 0;
        var kmsDriven                         = ValueFormat.parseAsFloat(value.overspeedScore, 0) || 0;       
        var mobileUsage                       = parseFloat(value.mobileCallScore) + parseFloat(value.mobileScreenScore);      
        var abcPoint                          = ValueFormat.parseAsFloat(value.accBrakCorScore, 0) || 0;       
        var cpp                               = ValueFormat.parseAsFloat(value.crashProbabilityScore, 0) || 0;  
        var totalAccelerationCount            = parseFloat(ValueFormat.parseAsFloat(value.severeAccelerationLowCount,0)) 
                                                +
                                                parseFloat(ValueFormat.parseAsFloat(value.severeAccelerationMediumCount,0)) 
                                                +
                                                parseFloat(ValueFormat.parseAsFloat(value.severeAccelerationHighCount,0));
        var totalBrakingCount                 = parseFloat(ValueFormat.parseAsFloat(value.severeBrakingLowCount, 0)) 
                                                +
                                                parseFloat(ValueFormat.parseAsFloat(value.severeBrakingMediumCount,0)) 
                                                +
                                                parseFloat(ValueFormat.parseAsFloat(value.severeBrakingHighCount, 0)); 
        var totalCorneringCount               = parseFloat(ValueFormat.parseAsFloat(value.severeCorneringLowCount,0)) 
                                                +
                                                parseFloat(ValueFormat.parseAsFloat(value.severeCorneringMediumCount,0)) 
                                                +
                                                parseFloat(ValueFormat.parseAsFloat(value.severeCorneringHighCount,0));
        var totalMobileUseInAcceptedCount     = ValueFormat.parseAsFloat(value.mobileCallCount,0) || "-";       
        const parsedNum                       = parseFloat(value.mobileCallDurationInMin);
        var totalMobileUseInAcceptedDuration  = Number.isInteger(parsedNum) ? parsedNum : ValueFormat.parseAsFloat(value.mobileCallDurationInMin,2) || "-";
        var totalMobileScreenScreenOnCount    = ValueFormat.parseAsFloat(value.mobileScreenCount,0) || "-";       
        const parsedNumMobileScreen           = parseFloat(value.mobileScreenDurationInMin);
        var totalMobileScreenScreenOnDuration = Number.isInteger(parsedNumMobileScreen) ? parsedNumMobileScreen : ValueFormat.parseAsFloat(value.mobileScreenDurationInMin,2) || "-";
        var totalOverSpeedCount               = ValueFormat.parseAsFloat(value.overSpeedCount, 0) || "-";
        const parsedNumOverSpeed              = parseFloat(value.overSpeedDurationInMin );
        var totalOverSpeedDuration            = Number.isInteger(parsedNumOverSpeed) ? parsedNumOverSpeed : ValueFormat.parseAsFloat(value.overSpeedDurationInMin, 2) || "-";
        var tKiloMeter                        = ValueFormat.parseAsFloat(value.distanceInKm, 2) || "-";       
        var tTravelTime                       = ValueFormat.parseAsFloat(value.durationInMin, 2) || "-";      
        var rideId                            = ValueFormat.parseAsString(value.deviceId);      
        var divisionId                        = ValueFormat.parseAsString(value.divisionId);
        var rideCategory                      = ValueFormat.parseAsString(value.category);
        var startTime                         = ValueFormat.parseAsString(value.startDateTimeToTimeZone) || "-";
        var endTime                           = ValueFormat.parseAsString(value.endDateTimeToTimeZone) || "-";
        var batterylevelStart                 = ValueFormat.parseAsFloat(value?.batteryLevelStart, 0) || "-";
        var batterylevelEnd                   = ValueFormat.parseAsFloat(value?.batteryLevelEnd, 0) || "-";
        var longEvent                         = ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(value.subType));
        var event                             = ValueFormat.parseAsString(value.subType) || "";
        let drivingBehaviourScore             = ValueFormat.parseAsFloat(value.calculatedBehaviourScore) || "-";
        var dayPercentage                     = ValueFormat.parseAsFloat(value.dayPercentage, 0);
        var nightPercentage                   = ValueFormat.parseAsFloat(value.nightPercentage, 0);        
        var urbanKilometer                    = ValueFormat.parseAsFloat(value.urbanDistanceInKm, 2);
        var ruralKilometer                    = ValueFormat.parseAsFloat(value.ruralDistanceInKm, 2);      
        var highwayKilometer                  = ValueFormat.parseAsFloat(value.highwayDistanceInKm, 2);

        var shortEvent = "";
        if (event === "BACKGROUND_AUTO") {
          shortEvent = "BGA";
        } else if (event === "BACKGROUND_MANUAL") {
          shortEvent = "BGM";
        } else if (event === "FOREGROUND_AUTO") {
          shortEvent = "FGA";
        } else if (event === "FOREGROUND_MANUAL") {
          shortEvent = "FGM";
        } else {
          shortEvent = "-";
        }        
      
        var resultObjectNewOne = {

            rideName                          : rideName,
            drivingCategory                   : drivingCategory,
            drivingBehaviourScore             : drivingBehaviourScore,
            calculatedDrivingScore            : calculatedDrivingScore,
            calculatedDrivingScoreValue       : calculatedDrivingScoreValue,      
            drivingScore                      : overallDrivingScore,
            rideId                            : rideId,
            userId                            : userId,
            divisionId                        : divisionId,         
            ABCPoint                          : abcPoint,           
            overallAnticipation               : overallAnticipation,
            overallSelfConfidence             : overallSelfConfidence,
            overallDrivingSkill               : overallDrivingSkill,
            totalKmSPoint                     : kmsDriven,
            DSP                               : dStateValue,
            DSTP                              : dStyleValue,            
            totalAccelerationCount            : totalAccelerationCount,
            totalBrakingCount                 : totalBrakingCount,
            totalCorneringCount               : totalCorneringCount,
            totalOverSpeedDuration            : totalOverSpeedDuration,
            totalMobileUseInAcceptedCount     : totalMobileUseInAcceptedCount,          
            totalMobileUseInAcceptedDuration  : totalMobileUseInAcceptedDuration,
            totalMobileScreenScreenOnCount    : totalMobileScreenScreenOnCount,           
            totalMobileScreenScreenOnDuration : totalMobileScreenScreenOnDuration,
            totalOverSpeedCount               : totalOverSpeedCount,        
            tKiloMeter                        : tKiloMeter,
            tTravelTime                       : tTravelTime,
            rideCategory                      : rideCategory,
            startTime                         : startTime,
            endTime                           : endTime,
            batterylevelStart                 : batterylevelStart,
            batterylevelEnd                   : batterylevelEnd,
            batterylevelTotalValue            : (batterylevelEnd - batterylevelStart),
            event                             : shortEvent,
            longEvent                         : longEvent,             
            cppLevel                          : cppFunction(cpp).value,
            cppLevelColor                     : cppFunction(cpp).colorCode,          
            mobileUsage                       : ValueFormat.parseAsFloat(mobileUsage, 0),           
            day                               : dayPercentage,
            night                             : nightPercentage,           
            urbanKilometers                   : urbanKilometer,
            ruralKilometers                   : ruralKilometer,
            highwayKilometers                 : highwayKilometer,          
            riskSlot10count                   : riskSlot10count,
            riskSlot10Distance                : riskSlot10Distance,
            riskSlot10Duration                : riskSlot10Duration,
            cpp                               : cpp,          
          
        };
       
        updatedTableData.push(resultObjectNewOne);
      
        setApiData(updatedTableData);       

        setUserName((prev) =>
          loginType == "ADMIN" || loginType == "SUPER_ADMIN"
            ? ValueFormat.parseAsString(value.name)
            : prev + "- " + ValueFormat.parseAsString(value.name)
        );     

      });

    } else {

      console.log("Status failed");
     
    }

  };
// work start

  const getSummaryDeatilsData = async () => {

    setShowLoader((current) => current + 1);

    try {
      
      var riskSlot1count  = "0",
          riskSlot2count  = "0",
          riskSlot3count  = "0",
          riskSlot4count  = "0",
          riskSlot5count  = "0";
      var riskSlot6count  = "0",
          riskSlot7count  = "0",
          riskSlot8count  = "0",
          riskSlot9count  = "0",
          riskSlot10count = "0";

      var rdResult = await MyRidesCtrlr.getV1RideSummaryDetails(
        locationParams.divisionId,
        locationParams.userId,
        locationParams.rideId,
        rideCategory // ridecategory added
      );
      setShowLoader((current) => current - 1);

      var resultJSON = JSON.parse(rdResult);

      var status = resultJSON.status;

      if (status == "SUCCESS") {

        var resultJSONData = resultJSON?.data;

        if (resultJSONData.length > 0) {

        console.log("riskCountSlot",resultJSONData);

        var rideSummary = resultJSONData[0]?.deviceDataSummary;
        
        console.log(rideSummary,"drivingStateNormalCount")
        
        // Driver State Detection

        var driverStateNormal              = ValueFormat.parseAsInt(rideSummary.drivingStateNormalCount);
        var driverStateTried               = ValueFormat.parseAsInt(rideSummary.drivingStateTiredCount);
        var driverStateAngry               = ValueFormat.parseAsInt(rideSummary.drivingStateAngryCount);

        var driverDistanceStateNormal      = ValueFormat.parseAsFloat(rideSummary.drivingStateNormalDistanceInKm,2);
        var driverDistanceStateTired       = ValueFormat.parseAsFloat(rideSummary.drivingStateTiredDistanceInKm,2);
        var driverDistanceStateAngry       = ValueFormat.parseAsFloat(rideSummary.drivingStateAngryDistanceInKm,2);

        var driverDurationStateNormal      = ValueFormat.parseAsFloat(rideSummary.drivingStateNormalDurationInMin,2);
        var driverDurationStateTired       = ValueFormat.parseAsFloat(rideSummary.drivingStateTiredDurationInMin,2);
        var driverDurationStateAngry       = ValueFormat.parseAsFloat(rideSummary.drivingStateAngryDurationInMin,2);
        
        // Driving Style Estimation

        var drivingStyleNormal             = ValueFormat.parseAsInt(rideSummary.drivingStyleNormalCount);
        var drivingStyleSportive           = ValueFormat.parseAsInt(rideSummary.drivingStyleSportiveCount);
        var drivingStyleAggressive         = ValueFormat.parseAsInt(rideSummary.drivingStyleAggressiveCount);
        var drivingStyleChaotic            = ValueFormat.parseAsInt(rideSummary.drivingStyleChaoticCount);
        var drivingStyleEco                = ValueFormat.parseAsInt(rideSummary.drivingStyleEcoCount);

        var drivingStyleSportiveDistance   = ValueFormat.parseAsFloat(rideSummary.drivingStyleSportiveDistanceInKm,2);
        var drivingStyleAggressiveDistance = ValueFormat.parseAsFloat(rideSummary.drivingStyleAggressiveDistanceInKm,2);
        var drivingStyleChaoticDistance    = ValueFormat.parseAsFloat(rideSummary.drivingStyleChaoticDistanceInKm,2);
        var drivingStyleNormalDistance     = ValueFormat.parseAsFloat(rideSummary.drivingStyleNormalDistanceInKm,2);
        var drivingStyleEcoDistance        = ValueFormat.parseAsFloat(rideSummary.drivingStyleEcoDistanceInKm,2);

        var drivingStyleSportiveDuration   = ValueFormat.parseAsFloat(rideSummary.drivingStyleSportiveDurationInMin,2);
        var drivingStyleAggressiveDuration = ValueFormat.parseAsFloat(rideSummary.drivingStyleAggressiveDurationInMin,2);
        var drivingStyleChaoticDuration    = ValueFormat.parseAsFloat(rideSummary.drivingStyleChaoticDurationInMin,2);
        var drivingStyleNormalDuration     = ValueFormat.parseAsFloat(rideSummary.drivingStyleNormalDurationInMin,2);
        var drivingStyleEcoDuration        = ValueFormat.parseAsFloat(rideSummary.drivingStyleEcoDurationInMin,2);
       
        for (let rdc = 0; rdc < resultJSONData.length; rdc++) {
          var resultDetailTemp = resultJSONData[rdc];
          
          if (resultDetailTemp.deviceDataSummary != null) {
            var riskObjet = resultDetailTemp?.deviceDataSummary;

            riskSlot1count  = riskObjet.riskSlot1Count != null  ? riskObjet.riskSlot1Count  : riskSlot1count;
            riskSlot2count  = riskObjet.riskSlot2Count != null  ? riskObjet.riskSlot2Count  : riskSlot2count;
            riskSlot3count  = riskObjet.riskSlot3Count != null  ? riskObjet.riskSlot3Count  : riskSlot3count;
            riskSlot4count  = riskObjet.riskSlot4Count != null  ? riskObjet.riskSlot4Count  : riskSlot4count;
            riskSlot5count  = riskObjet.riskSlot5Count != null  ? riskObjet.riskSlot5Count  : riskSlot5count;
            riskSlot6count  = riskObjet.riskSlot6Count != null  ? riskObjet.riskSlot6Count  : riskSlot6count;
            riskSlot7count  = riskObjet.riskSlot7Count != null  ? riskObjet.riskSlot7Count  : riskSlot7count;
            riskSlot8count  = riskObjet.riskSlot8Count != null  ? riskObjet.riskSlot8Count  : riskSlot8count;
            riskSlot9count  = riskObjet.riskSlot9Count != null  ? riskObjet.riskSlot9Count  : riskSlot9count;
            riskSlot10count = riskObjet.riskSlot10Count != null ? riskObjet.riskSlot10Count : riskSlot10count;
            
          }         
        }         
        
        var driverStateObjectNew = {

          driverNormal        : driverStateNormal,
          driverTried         : driverStateTried,
          driverAngry         : driverStateAngry,

          distanceStateNormal : driverDistanceStateNormal,
          distanceStateTired  : driverDistanceStateTired,
          distanceStateAngry  : driverDistanceStateAngry,
         
          durationStateNormal : driverDurationStateNormal,
          durationStateTired  : driverDurationStateTired,
          durationStateAngry  : driverDurationStateAngry, 

        };

        var driverStyleObjectNew = {

          drivingSportive         : drivingStyleSportive,
          drivingAggressive       : drivingStyleAggressive,
          drivingChaotic          : drivingStyleChaotic,
          drivingNormal           : drivingStyleNormal,
          drivingEco              : drivingStyleEco,         

          distanceStyleSportive   : drivingStyleSportiveDistance,
          distanceStyleAggressive : drivingStyleAggressiveDistance,
          distanceStyleChaotic    : drivingStyleChaoticDistance,
          distanceStyleNormal     : drivingStyleNormalDistance,
          distanceStyleEco        : drivingStyleEcoDistance,

          durationStyleSportive   : drivingStyleSportiveDuration,
          durationStyleAggressive : drivingStyleAggressiveDuration,
          durationStyleChaotic    : drivingStyleChaoticDuration,
          durationStyleNormal     : drivingStyleNormalDuration,
          durationStyleEco        : drivingStyleEcoDuration,

        };
        console.log(driverStateObjectNew,"driverStateObjectNew")
        
        var driverStateObjectLengthNew  = driverStateNormal + driverStateTried + driverStateAngry;
        setDriverStateDetection(driverStateObjectLengthNew > 0 ? true : false);

        var driverStyleObjectLength     = drivingStyleSportive + drivingStyleAggressive + drivingStyleChaotic + drivingStyleNormal + drivingStyleEco;        
        setDrivingStyleDataCount(driverStyleObjectLength > 0 ? true : false);
        setDriverStateList((staticData) => {
          return { ...staticData, ...driverStateObjectNew};
        });
        setDriverStyleList((staticData) => {
          return { ...staticData, ...driverStyleObjectNew };
        });

        const {
         
          animalCrossingCount,
          cautionCount,         
          curveCount,
          hillCount,
          hillDownwardsCount,
          hillUpwardsCount,
          icyConditionsCount,
          intersectionCount,
          laneMergeCount,
          lowGearAreaCount,         
          narrowRoadCount,         
          noOvertakingCount,         
          pedestrianCrossingCount,
          priorityCount,         
          railwayCrossingCount,
          riskOfGroundingCount,
          roundAboutCount,
          schoolZoneCount,         
          slipperyRoadsCount,
          stopSignCount,
          trafficLightCount,          
          windCount,
          windingRoadCount,
          yieldCount,

        } = rideSummary;
       
        const updatedDataNew = dataListNew?.map((item) => {

          switch (item.type) {
            case "ANIMAL_CROSSING":
              return { ...item, overallData : animalCrossingCount };
            case "CAUTION":
              return { ...item, overallData : cautionCount };
            case "CURVE":
              return { ...item, overallData : curveCount };
            case "ROUND":
              return { ...item, overallData : roundAboutCount };
            case "HILL":
              return { ...item, overallData : hillCount };
            case "HILL_DOWN":
              return { ...item, overallData : hillDownwardsCount };
            case "HILL_UP":
              return { ...item, overallData : hillUpwardsCount };
            case "ICY":
              return { ...item, overallData : icyConditionsCount };
            case "INTERSECTION":
              return { ...item, overallData : intersectionCount };
            case "LANE_MERGE":
              return { ...item, overallData : laneMergeCount };
            case "LOW_GEAR":
              return { ...item, overallData : lowGearAreaCount };
            case "NARROW_ROAD":
              return { ...item, overallData : narrowRoadCount };
            case "OVERTAKING":
              return { ...item, overallData : noOvertakingCount };
            case "CROSSING":
              return { ...item, overallData : pedestrianCrossingCount };
            case "PRIORITY":
              return { ...item, overallData : priorityCount };
            case "RAILWAY":
              return { ...item, overallData : railwayCrossingCount };
            case "RISK_GROUNDING":
              return { ...item, overallData : riskOfGroundingCount };
            case "SCHOOL":
              return { ...item, overallData : schoolZoneCount };
            case "SLIPPERY_ROADS":
              return { ...item, overallData : slipperyRoadsCount };
            case "STOP":
              return { ...item, overallData : stopSignCount };
            case "TRAFFIC_LIGHT":
              return { ...item, overallData : trafficLightCount };
            case "WIND":
              return { ...item, overallData : windCount };
            case "WINDING_ROAD":
              return { ...item, overallData : windingRoadCount };
            case "YIELD":
              return { ...item, overallData : yieldCount };
            default:
              return item;
          }

        });
       
        setRiskDataList(splitArrayIntoChunks(updatedDataNew) || []);
        setTotalAlerts(updatedDataNew.reduce((sum, item) => sum + item.overallData, 0));
        setRiskDataListleng(updatedDataNew.reduce((sum, item) => sum + item.overallData, 0) > 0 ? true : false);  
      
      }else{

        setDriverStateDetection(false);
        setDrivingStyleDataCount(false);

      }
      
      } else {
        
        var code = resultJSON.error.code;

        if (code == "E1206") {

          setHistogramData("failed");
          setDriverStateDetection("failed");
          setDrivingStyleDataCount("failed");
          setRiskDataListleng("failed");

        }

      }
      
    } catch (err) {
      console.log(err);
    }

    var dataList = [];
    
    const data = {
      "90 - 100"  : riskSlot10count,
      "80 - 90"   : riskSlot9count,
      "70 - 80"   : riskSlot8count,
      "60 - 70"   : riskSlot7count,
      "50 - 60"   : riskSlot6count,
      "40 - 50"   : riskSlot5count,
      "30 - 40"   : riskSlot4count,
      "20 - 30"   : riskSlot3count,
      "10 - 20"   : riskSlot2count,
      "0 - 10"    : riskSlot1count,
    };

    const data2 = {
      "90 - 100"  : riskSlot10count,
      "80 - 90"   : riskSlot9count,
      "70 - 80"   : riskSlot8count,
      "60 - 70"   : riskSlot7count,
      "50 - 60"   : riskSlot6count,
      "40 - 50"   : riskSlot5count,
      "30 - 40"   : riskSlot4count,
      "20 - 30"   : riskSlot3count,
      "10 - 20"   : riskSlot2count,
    };   

    if (
      parseFloat(riskSlot2count) === 0 &&
      parseFloat(riskSlot3count) === 0 &&
      parseFloat(riskSlot4count) === 0 &&
      parseFloat(riskSlot5count) === 0 &&
      parseFloat(riskSlot6count) === 0 &&
      parseFloat(riskSlot7count) === 0 &&
      parseFloat(riskSlot8count) === 0 &&
      parseFloat(riskSlot9count) === 0 &&
      parseFloat(riskSlot10count) === 0
    ) {
      data2["0 - 10"] = riskSlot1count;
    }

    // Calculate the total count combining all slots
    const totalCount = Object.values(data).reduce(
      (sum, count) => sum + count,
      0
    );

    const slotLabels = Object.entries(data2).map(
      ([range, count]) => `${range} (${count})`
    );

    const slotLabels1 = Object.entries(data2).map(
      ([time, count]) => `${"Time"} : ${(count / 20).toFixed(2)} seconds`
    );

    // Calculate the percentage for each slot count
    const slotNames = Object.keys(data2);

    const slotPercentages = slotNames.map((slot) => {
      if (totalCount === 0) {
        return 0; // or any other default value you prefer
      } else {
        return ((data2[slot] / totalCount) * 100).toFixed(2);
      }
    });

    slotPercentages.map((value, i) => {
      var custom = slotLabels1[i];
      var result = { value: value, customData: custom };
      dataList.push(result);
    });
   
    const resultDistributionData = {
      xaxis: slotLabels,
      data: dataList,
      customData: slotLabels1,
    };

    setDistributionData(resultDistributionData);

    setHistogramData(
      Object.values(slotPercentages).some(
        (value) => !isNaN(value) && value !== 0
      )
    );

  };

  const containerIds = ["container1"];

  const getRiskAlertsDataList = async () => {
    setShowLoader((current) => current + 1);
    setRiskAlertsDataDisplay(null);
    setMapDisplay(null);

    var result = await MyRidesCtrlr.getRideDetailV1RideDataList(
      locationParams.divisionId,
      locationParams.userId,
      locationParams.rideId, 
      
    );

    setShowLoader((current) => current - 1);

    var resultJSON = JSON.parse(result);

    console.log("mapDetails", resultJSON);

    var status = resultJSON.status;

    if (status == "SUCCESS") {

      var resultJSONData = resultJSON.data;
      
      setRiskAlertsDataDisplay(resultJSONData.length > 0 ? true : false);
      setMapDisplay(resultJSONData.length > 0 ? true : false);
     
      var alertDataCount       = 0;
      var tripIcon             = "";
      var alertImage           = "";
      var tripRiskList         = [];
      var tripRiskLatList      = [];
      var tripRiskLonList      = [];
      var tripRiskSigList      = [];
      var tripRiskLatLongList  = [];     
      var mapTootipDetails     = [];
      var tripStartLatLong     = "";
      var tripEndLatLong       = "";      
      var tripLatLngObjectList = [];
      var centerLatLong        = { lat: 11.004556, lng: 76.961632 };
      var startLng             = 0;
      var endLng               = 0;
      var startLat             = 0;
      var endLat               = 0;

      // if (resultJSONData.length > 0) {
      //   const lastEntry = resultJSONData[resultJSONData.length - 1];
      //   // const lastDataa = resultJSONData[0].lastDeviceData;
      //   const lastDataa = resultJSONData[resultJSONData.length - 1];
      //   if (lastEntry.category !== "END_DATA") {
      //     const endDataEntry = {
      //       ...lastEntry,
      //       category: "END_DATA",
      //       subCategory: "",
      //       createdAtToTimeZone: lastDataa.createdAtToTimeZone,
      //       latitude: ValueFormat.parseAsString(lastDataa.latitude),
      //       longitude: ValueFormat.parseAsString(lastDataa.longitude),        
      //       distanceInKm: lastDataa.distanceInKm,
      //       speed: lastDataa.speed,                 
      //       zipCode: lastDataa.zipCode,
      //       pointOfInterestInMtr: lastDataa.pointOfInterestInMtr,
      //     };
      //     console.log(endDataEntry,"sasdfghjb")
      //     resultJSONData.push(endDataEntry);
      //   }
      // }
      
     

      if (resultJSONData.length > 0) {

        var overSpeedData        = [];
        var mobileScreenData     = [];
        var stressStrainDataTemp = [];
        var mobileUseData        = [];
        var alertSummeryXAxis    = [];
        var alertSummeryData     = [];

        for (let rdCount = 0; rdCount < resultJSONData.length; rdCount++) {
          tripIcon = "";
          try {
            var createdAtToTimeZone = resultJSONData[rdCount].createdAtToTimeZone;
            var category            = resultJSONData[rdCount]?.category;           
            var subCategory         = resultJSONData[rdCount].subCategory;
            var deviceDataField     = resultJSONData[rdCount];
            var dDataField          = ValueFormat.parseAsObject(deviceDataField);
            var riskValueTemp       = ValueFormat.parseAsInt(dDataField.risk);
            var rideTimeTemp        = ValueFormat.parseAsFloat(dDataField.rideTime, 0);
            var alertKMTemp         = ValueFormat.parseAsFloat(dDataField.distanceInKm, 2);
            var kiloMeterData       = dDataField.distanceInKm != "" && dDataField.distanceInKm != null ? dDataField.distanceInKm : "";
            var speedTemp           = ValueFormat.parseAsFloat(dDataField.speed, 0);
            var speedABC            =   dDataField.speed != "" && dDataField.speed != null ? dDataField.speed : "0";
            var zipCodeTemp         = ValueFormat.parseAsFloat(dDataField.zipCode, 0);
            var alertValTemp        = ValueFormat.parseAsFloat(dDataField.alert, 0);
            var rideId              = ValueFormat.parseAsString(deviceDataField.deviceId);
            var rideName            = ValueFormat.parseAsString(deviceDataField.name);
            var startDate           = ValueFormat.parseAsString(deviceDataField.createdAtToTimeZone);
            var subCategoryABC      = deviceDataField.subCategory != "" && deviceDataField.subCategory != null
                                      ? ValueFormat.capitalizeFirstLetterAndFormat(deviceDataField.subCategory)
                                      : "";
            var subType             = ValueFormat.parseAsString(deviceDataField.subType);
            var subCategoryLevel    = ValueFormat.parseAsString(deviceDataField.subCategoryLevel);
            var subCategoryLowData  = deviceDataField.subCategoryLevel;   
            var speedLimit          = ValueFormat.parseAsFloat(deviceDataField.speedLimit, 2); 
            var alertsType          = dDataField.category != "" && dDataField.category != null
                                      ? ValueFormat.capitalizeFirstLetterAndFormat(dDataField.category)
                                      : "";
            var previousSpeedTemp   = ValueFormat.parseAsFloat(dDataField.previousSpeed,0);            
            var pointOfInterest     = ValueFormat.parseAsFloat(resultJSONData[rdCount].pointOfInterestInMtr,2);

            var overSpeedDataResult = {
                                       date        : startDate,
                                       distance    : alertKMTemp,
                                       speedLimit  : speedLimit,
                                       speed       : speedABC,
                                      };

            var stressStrainData    = {
                                        rideId    : rideId,
                                        rideName  : "",
                                        date      : startDate,
                                        type      : subCategoryABC,
                                        subType   : subType,
                                        distance  : alertKMTemp,
                                        speed     : speedABC,
                                        subCategoryLevel: subCategoryLevel,
                                      };
            
            var mobileScreenDataResult = {
                                          date      : startDate,
                                          distance  : alertKMTemp,
                                          speed     : speedABC,
                                        };

           console.log(subCategory,"subCategory")
           var xAxisLabel = "-";

           if (category == "START_DATA" || category == "END_DATA") {

             xAxisLabel = alertsType;

           } else if (
             category == "ALERT_DATA" ||
             category == "STRESS_STRAIN_DATA" ||
             category == "MANUAL_DATA"
           ) {

             if (subCategoryABC === "Pedestrian crossing") {

               xAxisLabel = "Ped.Crossing";

             } else {

               xAxisLabel = subCategoryABC;

             }

           } else if (category == "DISTANCE_DATA") {
             xAxisLabel = kiloMeterData + " Km";
           }
 
           console.log(xAxisLabel,"xAxisLabel")
           if ((subCategoryLowData != "LOW") && (xAxisLabel != "Start data") && (xAxisLabel != "End data")) {
            
             alertSummeryXAxis.push(xAxisLabel);

             const result = {
               value: speedABC,
               kiloMeter: kiloMeterData,
               startDate: startDate,
             };
             console.log(result,"looooooo")
            
             alertSummeryData.push(result);
           }     
 
         
         setRideDataDisplay(
           Object.values(alertSummeryData).some((value) => value !== 0)
         );

         setLineOption((updateData) => {

           const updatedParentObject = {
             ...updateData,
             xAxis: { ...updateData.xAxis, data: alertSummeryXAxis },
             series: { ...updateData.series, data: alertSummeryData },
           };
          
           return {
             ...updateData,
             ...updatedParentObject,
           };

          });


            if (subCategory == "OVER_SPEED") {
              overSpeedData.push(overSpeedDataResult);
            } else if (subCategory == "MOBILE_SCREEN") {              
              mobileScreenData.push(mobileScreenDataResult);
            } else if (subCategory == "MOBILE_USE") {
              mobileUseData.push(mobileScreenDataResult);
            } else if (category == "STRESS_STRAIN_DATA") {
              
              stressStrainDataTemp.push(stressStrainData);
            }
           
            setSpeedData(Object.values(overSpeedData).some((value) => value !== 0));
            setOverSpeedTableList(overSpeedData)

            setCallUseData(Object.values(mobileUseData).some((value) => value !== 0));
            setMobileCallUseDataList(mobileUseData);

            setScreenUseData(Object.values(mobileScreenData).some((value) => value !== 0));
           
            setMobileScreenUse(mobileScreenData);
          
            setAcbTableData(
            Object.values(stressStrainDataTemp).some((value) => value !== 0)
            );        
  
           
            setstressData(stressStrainDataTemp);
        

            var setspeedValue = "-";
            if (category == "ALERT_DATA") {
             
              alertDataCount = alertDataCount + 1;
              if (
                subCategory == "SEVERE_BRAKING" ||
                subCategory == "SEVERE_ACCELERATION"
              ) {
                setspeedValue = previousSpeedTemp;
              } else if (
                subCategory == "PEDESTRIAN_CROSSING" ||
                subCategory == "SCHOOL_ZONE"
              ) {
                setspeedValue = 25;
                if (speedTemp >= 25) {
                  speedTemp = speedTemp;
                } else {
                  // speedTemp = "-";
                  speedTemp = speedTemp;
                }
              } else if (subCategory == "OVER_SPEED") {
                setspeedValue = alertValTemp > 0 ? alertValTemp : "-";
              }
            }
            rideTimeTemp          = UtilDateTime.formatTimeAsHHMMSS(createdAtToTimeZone);
            var latitudeTempMap   = dDataField.latitude.length > 0  ? parseFloat(dDataField.latitude).toFixed(5)  : "";
            var longitudeTempMap  = dDataField.longitude.length > 0 ? parseFloat(dDataField.longitude).toFixed(5) : "";
            var latitudeTemp      = parseFloat(dDataField.latitude).toFixed(5);          
            var longitudeTemp     =  parseFloat(dDataField.longitude).toFixed(5);  
            var latLongTempMap    = latitudeTemp + "," + longitudeTemp;

            tripRiskLatList.push(latitudeTempMap);

            tripRiskLonList.push(longitudeTempMap);

            const latLng          = { lat: latitudeTemp, lng: longitudeTemp };

            var alertType = "";

            if (category != "STRESS_STRAIN_DATA") {

              if (subCategory.length > 0) {
                alertType = subCategory;
              } else {
                alertType = category;
              }
              var tooltipDetails = {
                alertType: alertType,
                speed: speedTemp,
                time: rideTimeTemp,
              };
              tripRiskSigList.push(tooltipDetails);

            }

            if (rdCount == 0) {

              startLat = latitudeTemp;
              startLng = longitudeTemp;
              tripStartLatLong = latLongTempMap;

            } else if (rdCount == resultJSONData.length - 1) {

              endLat = latitudeTemp;
              endLng = longitudeTemp;
              tripEndLatLong = latLongTempMap;

            } else if (category != "STRESS_STRAIN_DATA") {

              tripRiskLatLongList.push(latLongTempMap);
              tripLatLngObjectList.push(latLng);

            }

            if (subCategory == alertDataType.ANIMAL_CROSSING) {
              alertImage = "t_animal_crossing.png";
            } else if (subCategory == alertDataType.CAUTION) {
              alertImage = "t_caution.png";
            } else if (subCategory == alertDataType.CURVE) {
              alertImage = "t_curves.png";
            } else if (subCategory == alertDataType.SEVERE_ACCELERATION) {
              alertImage = "t_force_acceleration.png";
            } else if (subCategory == alertDataType.HILL_DOWNWARDS) {
              alertImage = "t_hill_downwards.png";
            } else if (subCategory == alertDataType.HILL_UPWARDS) {
              alertImage = "t_hill_upwards.png";
            } else if (subCategory == alertDataType.INTERSECTION) {
              alertImage = "t_intersection.png";
            } else if (subCategory == alertDataType.LANE_MERGE) {
              alertImage = "t_lane_merge.png";
            } else if (subCategory == alertDataType.LOW_GEAR_AREA) {
              alertImage = "t_low_gear_area.png";
            } else if (subCategory == alertDataType.NARROW_ROAD) {
              alertImage = "t_narrow_road.png";
            } else if (subCategory == alertDataType.MOBILE_USE) {
              alertImage = "t_mob_use.png";
            } else if (subCategory == alertDataType.NO_OVERTAKING) {
              alertImage = "t_no_overtaking.png";
            } else if (subCategory == alertDataType.OVER_SPEED) {
              alertImage = "t_over_speed.png";
            } else if (subCategory == alertDataType.P_CROSSING) {
              alertImage = "t_pedestrian_crossing.png";
            } else if (subCategory == alertDataType.R_CROSSING) {
              alertImage = "t_railway_crossing.png";
            } else if (subCategory == alertDataType.SCHOOL_ZONE) {
              alertImage = "t_school_zone.png";
            } else if (subCategory == alertDataType.STOP_SIGN) {
              alertImage = "t_stop.png";
            } else if (subCategory == alertDataType.SEVERE_BRAKING) {
              alertImage = "t_sudden_break.png";
            } else if (subCategory == alertDataType.TRAFFIC_LIGHT) {
              alertImage = "t_traffic_light.png";
            } else if (subCategory == alertDataType.WINDING_ROAD) {
              alertImage = "t_winding_road.png";
            } else if (subCategory == "YIELD") {
              alertImage = "t_yield.png";
            } else if (subCategory == alertDataType.PRIORITY) {
              alertImage = "t_priority.png";
            } else if (subCategory == alertDataType.ICY_CONDITIONS) {
              alertImage = "t_icy_conditions.png";
            } else if (subCategory == alertDataType.WIND) {
              alertImage = "t_wind.png";
            } else if (subCategory == alertDataType.HILL) {
              alertImage = "t_hill.png";
            } else if (subCategory == alertDataType.SLIPPERY_ROADS) {
              alertImage = "t_slippery_road.png";
            } else if (subCategory == alertDataType.RISK_OF_GROUNDING) {
              alertImage = "t_risk_of_grouping.png";
            } else if (subCategory == alertDataType.SEVERE_CORNERING) {
              alertImage = "sCornering.svg";
            } else if (subCategory == alertDataType.ROUND_ABOUT) {
              alertImage = "Round-about.svg";
            } else if (subCategory == alertDataType.NO_OVERTAKING_TRUCKS) {
              alertImage = "No-overtaking-trucks.svg";
            } else {
              alertImage = "not-avl.png";
            }
            if (category == alertDataType.START_DATA) {
              alertImage = "start.png";
              tripIcon = "alert_start.png";
            } else if (category == alertDataType.END_DATA) {
              // console.log(true,"sasdfghjb")
              alertImage = "finish.png";
              tripIcon = "alert_end.png";
            } else {
              if (riskValueTemp <= 90 && riskValueTemp > 70) {
                tripIcon = "alert_risk_amber.png";
              } else if (riskValueTemp <= 70) {
                tripIcon = "alert_start.png";
              } else {
                tripIcon = "alert_risk_red.png";
              }
            }
            console.log(tripEndLatLong,"tripEndLatLong")
            if (tripEndLatLong == "" && resultJSONData.length == 1) {
              tripEndLatLong = tripStartLatLong;
            }

            var deviceDataObject = {
              alertImage      : alertImage,
              tripIcon        : tripIcon,
              category        : category,
              subCategory     : subCategory,
              setspeedValue   : setspeedValue,
              timeVal         : rideTimeTemp,
              distanceVal     : alertKMTemp,
              speedVal        : speedTemp,
              riskValue       : riskValueTemp,
              pointOfInterest : pointOfInterest,
              zipcodeVal      : zipCodeTemp,
            };
            
            if (
              (subCategory == "" &&
                (category == "START_DATA" || category == "END_DATA")) ||
              (subCategory != "" && category == "ALERT_DATA")
            ) {
              if (
                category != "STRESS_STRAIN_DATA" ||
                category != "MANUAL_DATA"
              ) {
              
                tripRiskList.push(deviceDataObject);
               
              }
            }
          } catch (errMess) {
            // console.log("rdCount  3: ", rdCount + " errMess: " + errMess);
          }
        }
        setRiskDataTable(tripRiskList);
       
        const isDataAvailable =
          tripRiskList && tripRiskList.length > 0
            ? true
            : tripRiskList && tripRiskList.length <= 0
              ? false
              : null;

        setRiskAlertsDataDisplay(isDataAvailable);
        setAlertCount(alertDataCount);
        

        if (startLng != 0 && startLat != 0 && endLng != 0 && endLat != 0) {
          centerLatLong = {           
            lat: parseFloat(startLat),
            lng: parseFloat(startLng),
          };
        } else if (startLng != 0 && startLat != 0) {
          centerLatLong = {
            lat: parseFloat(startLat),
            lng: parseFloat(startLng),
          };
        }
     
        const platform = new H.service.Platform({
          apikey: "pAuj6jqBlpXZUMzd30mtgujRlfWV2jA6qZJPF3JP5is",
        });
        const defaultLayers = platform.createDefaultLayers();

        var map = new H.Map(
          document.getElementById("mapContainerDisplay"),
          defaultLayers.vector.normal.map.setMin(3),
          {
            center: centerLatLong,
            zoom: 11,
            pixelRatio: window.devicePixelRatio || 2,
            pview: "ind",
            style: "fleet",
          }
        );
        map.getViewModel().setLookAtData({ tilt: 40, heading: 180 });

        var ui = H.ui.UI.createDefault(map, defaultLayers);

        var routingParameters = {
          routingMode: "fast",
          transportMode: "car",
          origin: tripStartLatLong,
          destination: tripEndLatLong,
          zoomControl: true,
          via: new H.service.Url.MultiValueQueryParameter(tripRiskLatLongList),
          return: "polyline",
        };
        
        var resultContainer = document.getElementById("panel"); 
       

        var onResult = function (result) {
          // ensure that at least one route was found

          if (result.routes.length) {
            let sectionTemp = 0;
            let noOfSection = result.routes[0].sections.length;

            result.routes[0].sections.forEach((section) => {
              // Create a linestring to use as a point source for the route line
              let linestring = H.geo.LineString.fromFlexiblePolyline(
                section.polyline
              );

              // Create a polyline to display the route:
              let routeOutline = new H.map.Polyline(linestring, {
                style: {
                  lineWidth: 8,
                  strokeColor: "#007788",
                  lineTailCap: "arrow-tail",
                  lineHeadCap: "arrow-head",
                },
              });

              // Create a patterned polyline:
              var routeArrows = new H.map.Polyline(linestring, {
                style: {
                  lineWidth: 8,
                  fillColor: "white",
                  strokeColor: "#ffff",
                  lineDash: [0, 1],
                  lineTailCap: "arrow-tail",
                  lineHeadCap: "arrow-head",
                },
              });

              // create a group that represents the route line and contains
              // outline and the pattern
              var routeLine = new H.map.Group();
              routeLine.addObjects([routeOutline, routeArrows]);

              // Define a variable holding SVG mark-up that defines an icon image:
              var svgMarkup =
                '<svg width="24" height="24" ' +
                'xmlns="http://www.w3.org/2000/svg">' +
                '<rect stroke="white" fill="#1b468d" x="1" y="1" width="12" ' +
                'height="12" /><text x="7" y="11" font-size="8pt" ' +
                'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
                'fill="white">P</text></svg>';

              var alertSubCategory = "";
              var imageFileName = "";

              let startMarker;
              let endMarker;
              var icon;
              var tooltipContent = "";
              var bubble;
              if (sectionTemp == 0) {
                alertSubCategory = tripRiskSigList[sectionTemp];

                if (alertSubCategory.alertType == "START_DATA") {
                  imageFileName = "start.png";
                } else if (alertSubCategory.alertType == "MOBILE_SCREEN") {
                  imageFileName = "mobileScreen.svg";
                } else {
                  imageFileName = "start.png";
                }

                svgMarkup = require("../../assets/images/" + imageFileName);
                icon = new H.map.Icon(svgMarkup, {
                  size: { w: 40, h: 40 },
                });
                startMarker = new H.map.Marker(
                  section.departure.place.location,
                  {
                    icon: icon,
                  }
                );

                sectionTemp = sectionTemp + 1;
                alertSubCategory = tripRiskSigList[sectionTemp];
                if (alertSubCategory == undefined) {
                  alertSubCategory = { alertType: "" };
                }
                if (alertSubCategory.alertType == alertDataType.END_DATA) {
                  imageFileName = "finish.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.START_DATA
                ) {
                  imageFileName = "start.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.ANIMAL_CROSSING
                ) {
                  imageFileName = "t_animal_crossing.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.CAUTION
                ) {
                  imageFileName = "t_caution.png";
                } else if (alertSubCategory.alertType == alertDataType.CURVE) {
                  imageFileName = "t_curves.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.HILL_DOWNWARDS
                ) {
                  imageFileName = "t_hill_downwards.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.HILL_UPWARDS
                ) {
                  imageFileName = "t_hill_upwards.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.INTERSECTION
                ) {
                  imageFileName = "t_intersection.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.LANE_MERGE
                ) {
                  imageFileName = "t_lane_merge.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.LOW_GEAR_AREA
                ) {
                  imageFileName = "t_low_gear_area.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.NARROW_ROAD
                ) {
                  imageFileName = "t_narrow_road.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.MOBILE_USE
                ) {
                  imageFileName = "t_no_mob_use.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.NO_OVERTAKING
                ) {
                  imageFileName = "t_no_overtaking.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.OVER_SPEED
                ) {
                  imageFileName = "t_over_speed.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.P_CROSSING
                ) {
                  imageFileName = "t_pedestrian_crossing.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.R_CROSSING
                ) {
                  imageFileName = "t_railway_crossing.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.SCHOOL_ZONE
                ) {
                  imageFileName = "t_school_zone.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.STOP_SIGN
                ) {
                  imageFileName = "t_stop.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.SUDDEN_BRAKING
                ) {
                  imageFileName = "t_sudden_break.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.TRAFFIC_LIGHT
                ) {
                  imageFileName = "t_traffic_light.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.WINDING_ROAD
                ) {
                  imageFileName = "t_winding_road.png";
                } else if (alertSubCategory.alertType == alertDataType.YIELD) {
                  imageFileName = "t_yield.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.PRIORITY
                ) {
                  imageFileName = "t_priority.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.ICY_CONDITIONS
                ) {
                  imageFileName = "t_icy_conditions.png";
                } else if (alertSubCategory.alertType == alertDataType.WIND) {
                  imageFileName = "t_wind.png";
                } else if (alertSubCategory.alertType == alertDataType.HILL) {
                  imageFileName = "t_hill.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.RISK_OF_GROUNDING
                ) {
                  imageFileName = "t_risk_of_grouping.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.SLIPPERY_ROADS
                ) {
                  imageFileName = "t_slippery_road.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.ROUND_ABOUT
                ) {
                  imageFileName = "Round-about.svg";
                } else if (
                  alertSubCategory.alertType ==
                  alertDataType.NO_OVERTAKING_TRUCKS
                ) {
                  imageFileName = "No-overtaking-trucks.svg";
                }

                if (alertSubCategory.alertType == alertDataType.OVER_SPEED) {
                  imageFileName = "overspeed.svg";
                } else if (alertSubCategory.alertType == "MOBILE_USE") {
                  imageFileName = "mobilecallImage.svg";
                } else if (alertSubCategory.alertType == "MOBILE_SCREEN") {
                  imageFileName = "mobileScreen.svg";
                }

                if (imageFileName.length > 0) {
                  svgMarkup = require("../../assets/images/" + imageFileName);
                  icon = new H.map.Icon(svgMarkup, { size: { w: 40, h: 40 } });
                  endMarker = new H.map.Marker(section.arrival.place.location, {
                    icon: icon,
                  });
                  tooltipContent =
                    '<div style="min-width:180px;> <div style="display:flex">Alert :' +
                    ValueFormat.capitalizeFirstLetterAndFormat(
                      alertSubCategory.alertType
                    ) +
                    "</div>" +
                    '<div style="display:flex">Speed :' +
                    ValueFormat.capitalizeFirstLetterAndFormat(
                      alertSubCategory.speed
                    ) +
                    "</div>" +
                    '<div style="display:flex">Time :' +
                    ValueFormat.capitalizeFirstLetterAndFormat(
                      alertSubCategory.time
                    ) +
                    "</div>" +
                    "</div>";
                  endMarker.setData(tooltipContent);
                  endMarker.addEventListener(
                    "pointerenter",
                    function (evt) {
                      // console.log("routline event : ", evt)
                      // event target is the marker itself, group is a parent event target
                      // for all objects that it contains
                      bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
                        // read custom data
                        content: evt.target.getData(),
                      });
                      // show info bubble
                      ui.addBubble(bubble);
                    },
                    false
                  );
                  endMarker.addEventListener(
                    "pointerleave",
                    function (evt) {
                      bubble.close();
                    },
                    false
                  );
                }

                map.addObjects([routeLine, startMarker, endMarker]);
              }
             
              else {
                sectionTemp = sectionTemp + 1;

                // Create an icon, an object holding the latitude and longitude, and a marker:
                alertSubCategory = tripRiskSigList[sectionTemp];
                if (alertSubCategory.alertType == alertDataType.START_DATA) {
                  imageFileName = "start.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.END_DATA
                ) {
                  imageFileName = "finish.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.ANIMAL_CROSSING
                ) {
                  imageFileName = "t_animal_crossing.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.CAUTION
                ) {
                  imageFileName = "t_caution.png";
                } else if (alertSubCategory.alertType == alertDataType.CURVE) {
                  imageFileName = "t_curves.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.FORCE_ACCELERATION
                ) {
                  imageFileName = "t_force_acceleration.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.HILL_DOWNWARDS
                ) {
                  imageFileName = "t_hill_downwards.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.HILL_UPWARDS
                ) {
                  imageFileName = "t_hill_upwards.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.INTERSECTION
                ) {
                  imageFileName = "t_intersection.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.LANE_MERGE
                ) {
                  imageFileName = "t_lane_merge.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.LOW_GEAR_AREA
                ) {
                  imageFileName = "t_low_gear_area.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.NARROW_ROAD
                ) {
                  imageFileName = "t_narrow_road.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.MOBILE_USE
                ) {
                  imageFileName = "t_no_mob_use.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.NO_OVERTAKING
                ) {
                  imageFileName = "t_no_overtaking.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.OVER_SPEED
                ) {
                  imageFileName = "t_over_speed.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.P_CROSSING
                ) {
                  imageFileName = "t_pedestrian_crossing.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.R_CROSSING
                ) {
                  imageFileName = "t_railway_crossing.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.SCHOOL_ZONE
                ) {
                  imageFileName = "t_school_zone.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.STOP_SIGN
                ) {
                  imageFileName = "t_stop.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.SUDDEN_BRAKING
                ) {
                  imageFileName = "t_sudden_break.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.TRAFFIC_LIGHT
                ) {
                  imageFileName = "t_traffic_light.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.WINDING_ROAD
                ) {
                  imageFileName = "t_winding_road.png";
                } else if (alertSubCategory.alertType == alertDataType.YIELD) {
                  imageFileName = "t_yield.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.PRIORITY
                ) {
                  imageFileName = "t_priority.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.ICY_CONDITIONS
                ) {
                  imageFileName = "t_icy_conditions.png";
                } else if (alertSubCategory.alertType == alertDataType.WIND) {
                  imageFileName = "t_wind.png";
                } else if (alertSubCategory.alertType == alertDataType.HILL) {
                  imageFileName = "t_hill.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.RISK_OF_GROUNDING
                ) {
                  imageFileName = "t_risk_of_grouping.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.SLIPPERY_ROADS
                ) {
                  imageFileName = "t_slippery_road.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.ROUND_ABOUT
                ) {
                  imageFileName = "Round-about.svg";
                } else if (
                  alertSubCategory.alertType ==
                  alertDataType.NO_OVERTAKING_TRUCKS
                ) {
                  imageFileName = "No-overtaking-trucks.svg";
                }

                if (alertSubCategory.alertType == alertDataType.OVER_SPEED) {
                  imageFileName = "overspeed.svg";
                } else if (alertSubCategory.alertType == "MOBILE_USE") {
                  imageFileName = "mobilecallImage.svg";
                } else if (alertSubCategory.alertType == "MOBILE_SCREEN") {
                  imageFileName = "mobileScreen.svg";
                }

                if (imageFileName.length > 0) {
                  svgMarkup = require("../../assets/images/" + imageFileName);
                  icon = new H.map.Icon(svgMarkup, { size: { w: 40, h: 40 } });
                  endMarker = new H.map.Marker(section.arrival.place.location, {
                    icon: icon,
                  });
                  tooltipContent =
                    '<div style="min-width:180px;> <div style="display:flex">Alert :' +
                    ValueFormat.capitalizeFirstLetterAndFormat(
                      alertSubCategory.alertType
                    ) +
                    "</div>" +
                    '<div style="display:flex">Speed :' +
                    ValueFormat.capitalizeFirstLetterAndFormat(
                      alertSubCategory.speed
                    ) +
                    "</div>" +
                    '<div style="display:flex">Time :' +
                    ValueFormat.capitalizeFirstLetterAndFormat(
                      alertSubCategory.time
                    ) +
                    "</div>" +
                    "</div>";

                  endMarker.setData(tooltipContent);
                  endMarker.addEventListener(
                    "pointerenter",
                    function (evt) {
                      // console.log("routline event : ", evt)
                      // event target is the marker itself, group is a parent event target
                      // for all objects that it contains
                      bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
                        // read custom data
                        content: evt.target.getData(),
                      });
                      // show info bubble
                      ui.addBubble(bubble);
                    },
                    false
                  );
                  endMarker.addEventListener(
                    "pointerleave",
                    function (evt) {
                      bubble.close();
                    },
                    false
                  );

                  map.addObjects([routeLine, endMarker]);
                } else {
                  endMarker = new H.map.Marker(section.arrival.place.location);

                  map.addObjects([routeLine, endMarker]);
                }
              }
              
              var boundsData = new H.geo.Rect(
                startLat,
                startLng,
                endLat,
                endLng
              );
              map.getViewModel().setLookAtData({
                bounds: boundsData,
                position: { lat: startLat, lng: startLng },
                zoom: 11,
              });
            });
          }
        };
        var router = platform.getRoutingService(null, 8);
        router.calculateRoute(routingParameters, onResult, function (error) {
          var hmError = error.message;
          if (hmError.length > 0) {
            console.log("Heremap Error: " + error.message);
          }
        });
      
        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        // Clean up resources
        return () => {
          map.dispose();
        };
      }
    } else {
      var code = resultJSON.error.code;
        if (code == "E1206") {    
          setSpeedData("failed")     
          setScreenUseData("failed")
          setCallUseData("failed")
          setAcbTableData("failed");
        }
        
      console.log("Status failed");
    }
  }; 
  
  useEffect(() => {
    console.log(mobileScreenUse,"mobileScreenDataResult")
    setHistogramData();   
    setAcbTableData();    
    getSummaryDeatilsData();
    getRiskAlertsDataList();   
  }, [locationParams]);
  useEffect(() => {
    getRideDeatilsData();
  }, [locationParams, redRiskDistanceData]);

  const typeMapping = {
    "Severe acceleration": "Acc",
    "Severe cornering": "Cor",
    "Severe braking": "Brak",
  };
 
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleOpen = () => setDropdownOpen((prevState) => !prevState);

  const handleRedRiskDistanceData = (data) => {
    setRedRiskDistanceData(data);
  };

  const [fuelEfficiencyData, setFuelEfficiencyData] = useState(0);
  const [fuelConsumptionData, setFuelConsumptionData] = useState(0);
  const [maxSpeedData, setMaxSpeedData] = useState(0);
  const [avgSpeedData, setAvgSpeedData] = useState(0);
  const [idleHoursData, setIdleHoursData] = useState(0);

  const handlefuelEfficiencyData = (data) => {
    setFuelEfficiencyData(data);
  };
  const handlefuelConsumptionData = (data) => {
    setFuelConsumptionData(data);
  };
  const handlemaxSpeedData = (data) => {
    setMaxSpeedData(data);
  };
  const handleavgSpeedData = (data) => {
    setAvgSpeedData(data);
  };
  const handleidleHoursData = (data) => {
    setIdleHoursData(data);
  };  

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 460) {
       
        setChartWidthSpeedChart("93vw");
      } else if (window.innerWidth >= 461 && window.innerWidth <= 991) {
       
        setChartWidthSpeedChart("96vw");
      } else {
       
        setChartWidthSpeedChart("25vw");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); 
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

 

  return (
    <React.Fragment>
      {showLoader > 0 && <Loader />}
      <div className="page-content" id="content-to-export">
        <Container fluid>
          <div id="container1">
            <Breadcrumbs title="Ride Details" breadcrumbItem="Ride Details" />
            <Row className="mt-2" style={{marginBottom:"0px"}}>
              <Col sm={6} className="sub_heading">
                {loginType == "ADMIN" || loginType == "SUPER_ADMIN" ? (
                 
                  <h5 className="heading_New_data">{rideNameData}</h5>
                ) : (
                  <h5 className="heading_New_data">{rideNameData}</h5>
                )}
              </Col>
              <Col sm={6}>
                <div className="d-flex justify-content-end">
                <Dropdown isOpen={dropdownOpen} toggle={toggleOpen}>
                  <DropdownToggle  style={{
                                      backgroundColor: "transparent", 
                                      border: "none",                 
                                      padding: 0,                     
                                      boxShadow: "none"               
                  }}>
                                      <img
                                        src={DownloadIcon}
                                        alt="icons2"
                                        style={{
                                          height: "20px",
                                          
                                        }}
                                       
                                      />
                  </DropdownToggle>

        <DropdownMenu>
          {/* Export PDF Option */}
          <DropdownItem
            onClick={() => {
             
              ExportScreenDownloadPdf.generatePDF(containerIds, loginType === "ADMIN" || loginType === "SUPER_ADMIN"
                ? userFirstName + "'s ride detail"
                : userName + " th detail")
            }}
          >
            Export Screen
          </DropdownItem>

          {/* View PDF Option */}
          <DropdownItem
            onClick={() => {
            
              ViewPdf({
                // topWidgetData,
                // topWidgetDataPdf,
                // safetyData,
                // drivingStatus,
                // dayNightPercentage,
                // otherRiskData,
                distributionData,
                // driverStateList,
                driverStyleList,
                // stressStrainData,
                riskDataList,
                // overSpeedTableData,
                // mobileUseTableData,
                // mobileScreenTableData,
                riskDataTable,
                stressData,
              });
            }}
          >
            Download PDF
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
                </div>
              </Col>
            </Row>
           
            <Row style={{marginTop:"-10px"}}>
              <Col xs={12}>
              <CardBody style={{ padding: "0px" }}>              
                    <div
                      className="mb-4"                      
                    >
                      <div
                        style={{
                          height: "auto",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                          padding: "10px",
                        }}
                      >
                        {/* {displayText.DRIVER_BEHAVIOUR_DESCRIPTION} */}
                      </div>
                      <Table bordered style={TableStyle.table}>
                        <thead>                         
                          <tr>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Ride<br/>Details
                              
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving<br/> Score
                             
                            </th>
                           
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving<br/>Risk
                             
                            </th>

                           
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving Risk
                              <br />
                              Data
                              
                            </th>
                          
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving <br />
                              Behaviour
                              
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving Behaviour <br />
                              Data
                             
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Crash <br />
                              Probability
                             
                            </th>
                           
                           
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Acc/Brak/Cor
                              <br />
                              Counts
                             
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Over Speed
                              <br />
                              Data
                              
                            </th>

                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Mobile
                              <br />
                              Usage
                              
                            </th>

                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              High Risk<br />
                              Data
                              
                            </th>

                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Others
                             
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Context
                             
                            </th>
                          </tr>
                        </thead>
                        {/* {dataLength > 0 && ( */}
                          <tbody>
                            {dataLength > 0 ? (
                            (apiData || []).map((apiData, indexTable) => (
                              <tr key={indexTable}>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                 <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                 
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "12px",
                                        fontSize: "11px",
                                      }}
                                    >
                                  <Link
                                    // to={`/ridedetails?userId=${encodeURIComponent(
                                    //   Converter.encrypt(apiData.userId)
                                    // )}&divisionId=${encodeURIComponent(
                                    //   Converter.encrypt(apiData.divisionId)
                                    // )}&rideId=${encodeURIComponent(
                                    //   Converter.encrypt(apiData.rideId)
                                    // )}&rideCategory=${encodeURIComponent(
                                    //   Converter.encrypt(apiData.rideCategory)
                                    // )}`}
                                  >
                                    <p
                                      style={{
                                        fontSize: "11px",
                                        color: "#5156BE",
                                        marginBottom: "0px",
                                        fontWeight: 700,
                                        cursor:"pointer"
                                      }}
                                    >{`${apiData.rideName}`}</p>
                                  </Link>
                                  </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                          marginLeft: "-2px",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.tKiloMeter + " Kms"}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.tTravelTime + " Mins"}
                                    </div>
                                  </div>  
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                   
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        // marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {formatDate(apiData.startTime)} | {formatTime(apiData.startTime)}
                                    </div>
                                  </div>                               
                                </td>
                               
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                  
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 60,
                                        width: 60,
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <CircularProgressbar
                                        value={apiData.calculatedDrivingScore}
                                        text={`${apiData.calculatedDrivingScore}%`}
                                        styles={buildStyles({
                                          width: "10px",
                                          textSize: "27px",
                                          fontWeight: 700,
                                          pathColor:
                                            apiData.calculatedDrivingScoreValue >=
                                              0 &&
                                            apiData.calculatedDrivingScoreValue <= 80
                                              ? "#E21212"
                                              : apiData.calculatedDrivingScoreValue >=
                                                  81 &&
                                                apiData.calculatedDrivingScoreValue <=
                                                  93
                                              ? "#FFBF00"
                                              : "#19543e",
                                          trailColor: "#0095FF",
                                          textColor: "#5156BE",
                                        })}
                                        className="progress-bar-rideList"
                                      ></CircularProgressbar>
                                    </div>
                                   
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                     justifyContent:"center",
                                     marginTop:"20px"
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={steeringWheel}
                                        alt="icons2"
                                        style={{ height: "20px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",                                        
                                        fontSize: "11px",
                                        marginLeft:"5px"
                                        
                                      }}
                                    >
                                      {apiData.drivingCategory}
                                    </div>
                                  </div>
                                </td>                              
                                <td
                                  className="text-nowrap"
                                  data-tooltip-id={`categoryTooltip_${indexTable}`}
                                  style={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    borderRight: "0px",
                                    fontWeight: 700,
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: `${ValueFormat.formatDecimalIfRounded(
                                      apiData.drivingScore
                                    )}`,
                                  }} 
                                />
                                
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    borderLeft: "0px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIAnticipation}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Anticipation
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {`${ValueFormat.formatDecimalIfRounded(
                                        apiData.overallAnticipation
                                      )}`}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBISelfConfidence}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "7px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Self Confidence
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.overallSelfConfidence
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingSkill}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving Skill
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.overallDrivingSkill
                                      )}
                                    </div>
                                  </div>
                                </td>                                
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    borderRight: "0px",
                                    fontWeight: 700,
                                    fontSize: "11px",
                                  }}
                                >
                                  <div style={{fontSize: "13px"}}>{`${apiData.drivingBehaviourScore}`}</div>
                                </td>
                                <td
                                  className="text-nowrap"
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    borderLeft: "0px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingStyle}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving Style
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {`${ValueFormat.formatDecimalIfRounded(
                                        apiData.DSTP
                                      )}`}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingState}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving State
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.DSP
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIMobileCallUse}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "9px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Mobile Usage
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.mobileUsage}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "10px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Over Speed
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.totalKmSPoint
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIStressStrain}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "10px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Acc/Brak/Cor
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.ABCPoint
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  className="text-nowrap"
                                  style={{
                                    textAlign: "left",
                                    // fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 60,
                                        width: 60,
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <CircularProgressbar
                                        value={apiData.cppLevel}
                                        text={`${apiData.cppLevel}`}
                                        styles={buildStyles({
                                          width: "10px",
                                          textSize: "27px",
                                          fontWeight: 700,
                                          pathColor:apiData.cppLevelColor,                                          
                                          trailColor: "#0095FF",
                                          textColor: "#5156BE",
                                        })}
                                        className="progress-bar-rideList"
                                      ></CircularProgressbar>
                                    </div>
                                    
                                   
                                  </div>
                                  <div style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                  <div
                                      style={{
                                       
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginTop:"15px",
                                        marginBottom:"0px"
                                      }}
                                    ><p style={{fontSize: "11px"}}>{"CPP - "}{Math.round(apiData?.cpp)}</p>

                                    </div>
                                    </div>
                                  <div style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                      
                                      <div
                                      style={{
                                       
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginTop:"-5px",
                                        marginBottom:"0px"
                                      }}
                                    ><p style={{fontSize: "11px"}}>L0 is Safe</p>

                                    </div>
                                    
                                      
                                    </div>
                                    <div style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    <div
                                      style={{
                                       
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginTop:"-5px"
                                      }}
                                    ><p style={{fontSize: "11px"}}>L6 is Unsafe</p>

                                    </div>
                                    </div>                                  
                                </td>
                                
                               
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={AccelerationIcon}
                                          alt="totalAccelerationCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Acc
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalAccelerationCount}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={BreakingIcon}
                                          alt="totalBrakingCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Brak
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalBrakingCount}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={CorneringIcon}
                                          alt="totalCorneringCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Cor
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalCorneringCount}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CountImage}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      
                                      {apiData.totalOverSpeedCount}{" "}{"Count"}
                                    </div>
                                  </div>           

                                
                                  
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalOverSpeedDuration}{" "}{"Mins"}
                                    </div>
                                  </div>                                 
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                   <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={ScreenCountIcon}
                                        alt="icons2"
                                        style={{ height: "24px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileScreenScreenOnCount}{" "}{"Count"}
                                    </div>
                                    </div>

                                    <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileScreenScreenOnDuration}{" "}{"Mins"}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CallCountIconNewOne}
                                        alt="icons2"
                                        style={{ height: "26px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileUseInAcceptedCount}{" "}{"Count"}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileUseInAcceptedDuration}{" "}{"Mins"}
                                    </div>
                                  </div>                                 
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CountImage}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.riskSlot10count}{" "}{"Count"}
                                    </div>
                                  </div>           

                                  
                                  
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                          marginLeft: "-2px",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.riskSlot10Distance}{" "}{"Meters"}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                     {apiData.riskSlot10Duration}{" "}{"Sec"}
                                    </div>
                                  </div>
                                 
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div style={{display:"flex",marginBottom:"0px"}}>
                                    <div>
                                  <img
                                    data-tooltip-id={`time_${indexTable}`}
                                    data-tooltip-html={`Start Time: ${apiData.startTime} <br/> End Time: ${apiData.endTime}`}
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                  />
                                  </div>
                                  <div style={{display:"flex",justifyContent:"center",flexDirection:"column",fontSize:"11px"}}>RD</div>
                                          </div>
                                 <div style={{display:"flex",marginTop: "10px",marginBottom:"0px"}}>
                                 <div>
                                  <img
                                    data-tooltip-id={`battery_${indexTable}`}
                                    data-tooltip-html={`Battery Consumption | ${apiData.batterylevelTotalValue}%`}
                                    src={battery}
                                    alt="icons1"
                                    style={{                                     
                                      height: "25px",
                                    }}
                                  />
                                  </div>
                                  <div style={{display:"flex",justifyContent:"center",flexDirection:"column",fontSize:"11px"}}>BC</div>
                                  </div>
                                  <div style={{display:"flex",marginTop: "10px",marginBottom:"0px"}}>
                                    <div>
                                  <Link
                                    to="/journeyView"
                                    state={{
                                      userId: apiData.userId,
                                      divisionId: apiData.divisionId,
                                      rideId: apiData.rideId,
                                      rideNo: apiData.rideNo,
                                    }}
                                    className="m-0 p-0"
                                  >
                                  <img
                                    src={DetailViewd}
                                    alt="icons1"
                                    style={{
                                      // marginTop: "15px",
                                      height: "25px",
                                      cursor:"pointer"
                                    }}
                                  />
                                  </Link>
                                  </div>
                                  <div style={{display:"flex",justifyContent:"center",flexDirection:"column",fontSize:"11px",marginLeft:"2px"}}>JV</div>
                                  </div>                                   
                                  
                                 <div>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          {apiData.event}
                                        </p>

                                 </div>
                                  
                                 
                                  <ReactTooltip
                                    id={`time_${indexTable}`}
                                    place="top"
                                    effect="solid"
                                    backgroundColor="black"
                                    multiline={true}
                                    style={{ textAlign: "left" }}
                                  />
                                  <ReactTooltip
                                    id={`event_${indexTable}`}
                                    place="top"
                                    effect="solid"
                                    backgroundColor="black"
                                    content={apiData.longEvent}
                                  />
                                  <ReactTooltip
                                    id={`battery_${indexTable}`}
                                    place="top"
                                    effect="solid"
                                    backgroundColor="black"
                                    multiline={true}
                                    style={{ textAlign: "left" }}
                                  />
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                 
                                  <div style={{display:"flex"}}>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            fontWeight:700
                                            // marginTop: "10px",
                                          }}
                                        >
                                          U{" - "}
                                        </p>
                                        
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px"
                                           
                                          }}
                                        >
                                        {apiData.urbanKilometers}{" "}{"Kms"}
                                        </p>

                                 </div>
                                  <div style={{display:"flex",marginTop: "10px"}}>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            // marginTop: "10px",
                                            fontWeight:700
                                          }}
                                        >
                                          R{" - "}
                                        </p>
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px"
                                           
                                          }}
                                        >
                                        {apiData.ruralKilometers}{" "}{"Kms"}
                                        </p>

                                 </div>
                                  
                                 <div style={{display:"flex",marginTop: "10px"}}>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",                                            
                                            fontWeight:700
                                          }}
                                        >
                                          H{" - "}
                                        </p>
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px"
                                           
                                          }}
                                        >
                                        {apiData.highwayKilometers}{" "}{"Kms"}
                                        </p>

                                 </div>

                                 <div style={{display:"flex",marginTop: "10px"}}>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",                                            
                                            fontWeight:700
                                          }}
                                        >
                                          D{" - "}
                                        </p>
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px"
                                           
                                          }}
                                        >
                                        {apiData.day}{" "}{"%"}
                                        </p>

                                 </div>         
                                 <div style={{display:"flex",marginTop: "10px"}}>
                                 
                                  <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",                                            
                                            fontWeight:700
                                          }}
                                        >
                                          N{" - "}
                                        </p>
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px"
                                           
                                          }}
                                        >
                                        {apiData.night}{" "}{"%"}
                                        </p>

                                 </div>                                  
                                 
                                  
                                </td>
                              </tr>
                            ))) : dataLength === 0 ? (
                              <tr>
                              {" "}
                              <td colSpan="13">
                                <div
                                  style={{
                                    color: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.DATA_NOT_AVAILABLE}
                                </div>
                              </td>
                            </tr>

                            ) : dataLength === null ? (
                              <tr>
                              {" "}
                              <td colSpan="13">
                                <div
                                  style={{
                                    color: "green",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.FETCHING_DATA}
                                </div>
                              </td>
                            </tr>

                            ) : ""}
                          </tbody>
                        {/* )} */}
                      </Table>

                     
                    </div>
                 

                
              </CardBody>
              </Col>
            </Row>         
            <Row style={{marginTop:"-15px"}}  >            
             <SpeedandRiskViolation
                redRiskDistanceDataas={handleRedRiskDistanceData}
                handlefuelEfficiencyData={handlefuelEfficiencyData}
                handlefuelConsumptionData={handlefuelConsumptionData}
                handlemaxSpeedData={handlemaxSpeedData}
                handleavgSpeedData={handleavgSpeedData}
                handleidleHoursData={handleidleHoursData}
              /> 

             <Col xs={3}>
      <Card style={{ height: 410 ,}}>
           
              <CardHeader 
              className="cardHeader" style={{padding:"5px"}}>
                
                 
                    <h6 className="card-title">
                    Speed vs Risk Alerts                     
                     
                    </h6>    
                  
              </CardHeader>
              {rideDataDisplay === true ? (
                <CardBody 
                // style={{ height: '615px' }}
                >
                  <ReactEcharts
                    style={{ height: "345px", width: chartWidthSpeedChart }}
                    option={lineOption}
                  />                 
                </CardBody>
              ) : rideDataDisplay === false ? (
                <CardBody>
                  <p className="data_not_available">
                    {displayText.DATA_NOT_AVAILABLE}
                  </p>
                  <ReactEcharts
                    style={{ height: "335px", width: chartWidthSpeedChart }}
                    option={lineOption}
                  />                 
                </CardBody>
              ) : (
                <CardBody>
                  <p className="fetch_data">{displayText.FETCHING_DATA}</p>
                  <ReactEcharts
                    style={{ height: "335px",width: chartWidthSpeedChart }}
                    option={lineOption}
                  />                 
                </CardBody>
              )}
            </Card>
      </Col>
              
              <Col xl={3} >
                <Card style={{ height: 410}}>
                  <CardHeader className="cardHeader" style={{padding:"5px"}}>
                    <h4 className="card-title" >Risk Histogram</h4>
                  </CardHeader>
                  {histogramData === true ? (
                    <CardBody style={{padding:"2px"}}>
                      <LineColumnAreaTwo DistributionData={distributionData} />
                    </CardBody>
                  ) : histogramData === false ? (
                    <CardBody style={{padding:"2px"}}>
                      <p className="data_not_available">
                        {displayText.DATA_NOT_AVAILABLE}
                      </p>
                      <LineColumnAreaTwo DistributionData={distributionData} />
                    </CardBody>
                  ) : histogramData === "failed" ? (
                    <CardBody style={{padding:"2px"}}>
                      <p className="data_not_available">{displayText.ER_1206}</p>
                      <LineColumnAreaTwo DistributionData={distributionData} />
                    </CardBody>
                  ) : (
                    <CardBody style={{padding:"2px"}}>
                      <p className="fetch_data">{displayText.FETCHING_DATA}</p>
                      <LineColumnAreaTwo DistributionData={distributionData} />
                    </CardBody>
                  )}
                </Card>
              </Col>
              
            </Row>
            <Row style={{marginTop:"-10px"}}>
              <Col xl={8}>
                <Card style={{ height: 480 }}>
                  <CardHeader className="cardHeader" style={{padding:"5px"}}>
                    <h4 className="card-title">Map View - Risk Alert</h4>
                  
                  </CardHeader>
                  <CardBody>
                    {mapDisplay === null ? (
                      <p className="fetch_data">{displayText.FETCHING_DATA}</p>
                    ) : mapDisplay === false ? (
                      <p className="data_not_available">
                        {displayText.DATA_NOT_AVAILABLE}
                      </p>
                    ) : null}
                    <Row>
                      <Col
                        style={{
                          height: "400px",
                          position: "relative",
                          overflow: "hidden",
                        }}
                        xl={12}
                        id="mapContainerDisplay"
                      />

                      <Col
                        style={{
                          height: "400px",
                          position: "relative",
                          overflow: "hidden",
                          display: "none",
                        }}
                        xl={12}
                        id="panel"
                      />
                    </Row>

                  
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                <Card style={{ height: 480 }}>
                  <CardHeader className="cardHeader" style={{padding:"5px"}}>
                    <h4 className="card-title">
                      Risk Alerts
                      <span style={{ fontSize: "11px", fontWeight: 400 }}>
                        {" "}
                        (Total Alerts - {alertCount})
                      </span>
                    </h4>
                  </CardHeader>
                  {riskAlertsDataDisplay == "failed" ? (
                    <CardBody>
                      <Table className="table table-hover mb-0">
                        <thead className="sticky-header">
                        <tr>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                              <img
                                          src={TripIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           data-tooltip-id="riskAlertTripData00111111909090"
                                        />
                                        <ReactTooltip
                                id="riskAlertTripData00111111909090"
                                place="top"
                                content="Trip"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                             <img
                                          src={alertIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="riskAlertAlertData0001010101010199"
                                        />
                                        <ReactTooltip
                                id="riskAlertAlertData0001010101010199"
                                place="top"
                                content="Alert"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                             <img
                                          src={clock}
                                          alt="icons2"
                                          style={{ height: "25px" }}

                                          data-tooltip-id="riskAlertclockData00011313131414"
                                        />
                                        <ReactTooltip
                                id="riskAlertclockData00011313131414"
                                place="top"
                                content="HH:MM:SS"
                                style={{ background: "black" }}
                              />
                             
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                               <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="riskAlertLocationData01112122323"
                                      />
                                      <ReactTooltip
                                id="riskAlertLocationData01112122323"
                                place="top"
                                content="Kms"
                                style={{ background: "black" }}
                              />
                             
                            </th>
                            
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                              data-tooltip-id="pointOfinterest110101"
                            >
                              <img
                                          src={poiIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                
                              <ReactTooltip
                                id="pointOfinterest110101"
                                place="top"
                                content="Meters"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                              <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           data-tooltip-id="riskAlertSpeedData022020202"
                                        />
                                         <ReactTooltip
                                id="riskAlertSpeedData022020202"
                                place="top"
                                content="Kms/hr"
                                style={{ background: "black" }}
                              />
                              
                            </th>

                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                              <img
                                          src={zipCodeIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="riskAlertZipCode033030303"
                                        />
                                         <ReactTooltip
                                id="riskAlertZipCode033030303"
                                place="top"
                                content="Zipcode"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                        </thead>
                      </Table>
                      <p className="data_not_available">{displayText.ER_1206}</p>
                    </CardBody>
                  ) : riskAlertsDataDisplay == false ? (
                    <CardBody>
                      <Table className="table table-hover mb-0">
                        <thead className="sticky-header">
                        <tr>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                              <img
                                          src={TripIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           data-tooltip-id="riskAlertTripData00111111"
                                        />
                                        <ReactTooltip
                                id="riskAlertTripData00111111"
                                place="top"
                                content="Trip"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                             <img
                                          src={alertIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="riskAlertAlertData0001010101010"
                                        />
                                        <ReactTooltip
                                id="riskAlertAlertData0001010101010"
                                place="top"
                                content="Alert"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                             <img
                                          src={clock}
                                          alt="icons2"
                                          style={{ height: "25px" }}

                                          data-tooltip-id="riskAlertclockData0001131313"
                                        />
                                        <ReactTooltip
                                id="riskAlertclockData0001131313"
                                place="top"
                                content="HH:MM:SS"
                                style={{ background: "black" }}
                              />
                             
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                               <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="riskAlertLocationData0111212"
                                      />
                                      <ReactTooltip
                                id="riskAlertLocationData0111212"
                                place="top"
                                content="Kms"
                                style={{ background: "black" }}
                              />
                             
                            </th>
                           
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                              data-tooltip-id="pointOfinterest1101"
                            >
                              <img
                                          src={poiIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                
                              <ReactTooltip
                                id="pointOfinterest1101"
                                place="top"
                                content="Meters"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                              <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           data-tooltip-id="riskAlertSpeedData02202"
                                        />
                                         <ReactTooltip
                                id="riskAlertSpeedData02202"
                                place="top"
                                content="Kms/hr"
                                style={{ background: "black" }}
                              />
                              
                            </th>

                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                              <img
                                          src={zipCodeIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="riskAlertZipCode03303"
                                        />
                                         <ReactTooltip
                                id="riskAlertZipCode03303"
                                place="top"
                                content="Zipcode"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                        </thead>
                      </Table>
                      <p className="data_not_available">
                        {displayText.DATA_NOT_AVAILABLE}
                      </p>
                    </CardBody>
                  ) : riskAlertsDataDisplay == null ? (
                    <CardBody>
                      <Table className="table table-hover mb-0">
                        <thead className="sticky-header">
                        <tr>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                              <img
                                          src={TripIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           data-tooltip-id="riskAlertTripData00111"
                                        />
                                        <ReactTooltip
                                id="riskAlertTripData00111"
                                place="top"
                                content="Trip"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                             <img
                                          src={alertIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="riskAlertAlertData0001010"
                                        />
                                        <ReactTooltip
                                id="riskAlertAlertData0001010"
                                place="top"
                                content="Alert"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                             <img
                                          src={clock}
                                          alt="icons2"
                                          style={{ height: "25px" }}

                                          data-tooltip-id="riskAlertclockData0001"
                                        />
                                        <ReactTooltip
                                id="riskAlertclockData0001"
                                place="top"
                                content="HH:MM:SS"
                                style={{ background: "black" }}
                              />
                             
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                               <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="riskAlertLocationData011"
                                      />
                                      <ReactTooltip
                                id="riskAlertLocationData011"
                                place="top"
                                content="Kms"
                                style={{ background: "black" }}
                              />
                             
                            </th>
                            
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                              data-tooltip-id="pointOfinterest11"
                            >
                              <img
                                          src={poiIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                
                              <ReactTooltip
                                id="pointOfinterest11"
                                place="top"
                                content="Meters"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                              <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           data-tooltip-id="riskAlertSpeedData022"
                                        />
                                         <ReactTooltip
                                id="riskAlertSpeedData022"
                                place="top"
                                content="Kms/hr"
                                style={{ background: "black" }}
                              />
                              
                            </th>

                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                              <img
                                          src={zipCodeIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="riskAlertZipCode033"
                                        />
                                         <ReactTooltip
                                id="riskAlertZipCode033"
                                place="top"
                                content="Zipcode"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                        </thead>
                      </Table>
                      <p className="fetch_data" style={{ height: "350px" }}>
                        {displayText.FETCHING_DATA}
                      </p>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <div
                        className="table-responsive"
                        style={{ height: "380px" }}
                      >
                        <Table className="table table-hover mb-0">
                          <thead className="sticky-header">
                          <tr>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                              <img
                                          src={TripIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           data-tooltip-id="riskAlertTripData001"
                                        />
                                        <ReactTooltip
                                id="riskAlertTripData001"
                                place="top"
                                content="Trip"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                             <img
                                          src={alertIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="riskAlertAlertData000"
                                        />
                                        <ReactTooltip
                                id="riskAlertAlertData000"
                                place="top"
                                content="Alert"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                             <img
                                          src={clock}
                                          alt="icons2"
                                          style={{ height: "25px" }}

                                          data-tooltip-id="riskAlertclockData00"
                                        />
                                        <ReactTooltip
                                id="riskAlertclockData00"
                                place="top"
                                content="HH:MM:SS"
                                style={{ background: "black" }}
                              />
                             
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                               <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="riskAlertLocationData01"
                                      />
                                      <ReactTooltip
                                id="riskAlertLocationData01"
                                place="top"
                                content="Kms"
                                style={{ background: "black" }}
                              />
                             
                            </th>
                           
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                              data-tooltip-id="pointOfinterest1"
                            >
                              <img
                                          src={poiIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                
                              <ReactTooltip
                                id="pointOfinterest1"
                                place="top"
                                content="Meters"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                              <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           data-tooltip-id="riskAlertSpeedData02"
                                        />
                                         <ReactTooltip
                                id="riskAlertSpeedData02"
                                place="top"
                                content="Kms/hr"
                                style={{ background: "black" }}
                              />
                              
                            </th>

                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                              <img
                                          src={zipCodeIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="riskAlertZipCode03"
                                        />
                                         <ReactTooltip
                                id="riskAlertZipCode03"
                                place="top"
                                content="Zipcode"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                          </thead>
                          <tbody style={{ overflowY: "scroll" }}>
                            {(riskDataTable || []).map((apiData) => (
                              <tr
                                style={{
                                  fontSize: "12px",
                                  verticalAlign: "middle",
                                }}
                                key={apiData}
                                onClick={passLatlog}
                              >
                                <td style={{ verticalAlign: "middle" }}>
                                  <img
                                    src={require("../../assets/images/TripIcons/" +
                                      apiData.tripIcon)}
                                    width={12}
                                    alt="image"
                                  />{" "}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  <img
                                    src={require("../../assets/images/" +
                                      apiData.alertImage)}
                                    width={25}
                                    alt="image"
                                  />{" "}
                                </td>

                                <td style={{ verticalAlign: "middle" }}>
                                  {apiData.timeVal}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {apiData.distanceVal}
                                </td>
                               
                                <td style={{ verticalAlign: "middle" }}>
                                  {apiData.pointOfInterest}
                                </td>
                                <td style={{ textAlign: "end" }}>
                                  <div className=" w-50">
                                    {apiData.speedVal}
                                  </div>
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {apiData.zipcodeVal == 0
                                    ? "-"
                                    : apiData.zipcodeVal}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
         
            <Row >
              

              <Col sm={6} lg={2}>
            <Card>
              <CardHeader className="cardHeader" style={{padding:"5px"}}>
                <h4 className="card-title">Overspeed</h4>
              </CardHeader>
              {speedData === true ? (
                <CardBody
                  style={{ height: 310, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                            <img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="overSpeedClockData"
                                  />
                                  <ReactTooltip
                        id="overSpeedClockData"
                        place="top"                       
                        content={"HH:MM"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                                  </th>
                          {/* <th style={{ fontSize: "11px", fontWeight: 600, }}> */}
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                            <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="overSpeedDistanceDataNewOne"
                                      />
                                      <ReactTooltip
                        id="overSpeedDistanceDataNewOne"
                        place="top"                       
                        content={"Kms"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                                      </th>
                              {/* </th> */}
                          <th style={{ fontSize: "11px", fontWeight: 600, }} data-tooltip-id="OverSpeedIconLimitDataNewOne11">
                          <img
                                          src={OverSpeedIconLimit}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           
                                        /> | <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           
                                        />

                       <ReactTooltip
                        id="OverSpeedIconLimitDataNewOne11"
                        place="top"                       
                        content={"Kms/hr"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                     
                            </th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {(overSpeedTableList || []).map((apiData, key) => (
                          <tr key={key}>
                            <td style={{ fontSize: "11px" }}>{dayjs(apiData.date).format("HH:mm")}</td>
                            <td style={{ fontSize: "11px" }}>
                              {apiData.distance}
                            </td>
                            <td style={{ fontSize: "11px" }}>
                              {apiData.speedLimit} | {apiData.speed}
                            </td>
                          
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              ) : speedData === false ? (
                <CardBody
                  style={{
                    height: 310,
                    overflow: "auto",
                    paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px'
                  }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                         <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                            <img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="overSpeedClockDataNot"
                                  />
                                  <ReactTooltip
                        id="overSpeedClockDataNot"
                        place="top"                       
                        content={"HH:MM"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                                  </th>
                          {/* <th style={{ fontSize: "11px", fontWeight: 600, }}> */}
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                            <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="overSpeedDistanceDataNotNewOne"
                                      />
                                      <ReactTooltip
                        id="overSpeedDistanceDataNotNewOne"
                        place="top"                       
                        content={"Kms"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                                      </th>
                              {/* </th> */}
                          <th style={{ fontSize: "11px", fontWeight: 600, }} data-tooltip-id="OverSpeedIconLimitDataNotNewOne11">
                          <img
                                          src={OverSpeedIconLimit}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           
                                        /> | <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           
                                        />

                       <ReactTooltip
                        id="OverSpeedIconLimitDataNotNewOne11"
                        place="top"                       
                        content={"Kms/hr"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                    
                            </th>
                         
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="data_not_available"
                      style={{
                        height: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {displayText.DATA_NOT_AVAILABLE}
                    </p>
                  </div>
                </CardBody>
              ) : speedData === "failed" ? (
                <CardBody
                  style={{ height: 310, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                          <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                            <img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="overSpeedClockDataER_1206"
                                  />
                                  <ReactTooltip
                        id="overSpeedClockDataER_1206"
                        place="top"                       
                        content={"HH:MM"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                                  </th>
                          
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                            <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="overSpeedDistanceDataER_1206NewOne"
                                      />
                                      <ReactTooltip
                        id="overSpeedDistanceDataER_1206NewOne"
                        place="top"                       
                        content={"Kms"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                                      </th>
                              {/* </th> */}
                          <th style={{ fontSize: "11px", fontWeight: 600, }} data-tooltip-id="OverSpeedIconLimitDataER_1206NewOne11">
                          <img
                                          src={OverSpeedIconLimit}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           
                                        /> | <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           
                                        />

                       <ReactTooltip
                        id="OverSpeedIconLimitDataER_1206NewOne11"
                        place="top"                       
                        content={"Kms/hr"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                     
                            </th>
                          
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="fetch_data"
                      style={{
                        height: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {displayText.ER_1206}
                    </p>
                  </div>
                </CardBody>
              ) : (
                <CardBody
                  style={{ height: 310, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                          <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                            <img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="overSpeedClockDataFETCHING_DATA"
                                  />
                                  <ReactTooltip
                        id="overSpeedClockDataFETCHING_DATA"
                        place="top"                       
                        content={"HH:MM"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                                  </th>
                         
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                            <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="overSpeedDistanceDataFETCHING_DATANewOne"
                                      />
                                      <ReactTooltip
                        id="overSpeedDistanceDataFETCHING_DATANewOne"
                        place="top"                       
                        content={"Kms"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                                      </th>
                              {/* </th> */}
                          <th style={{ fontSize: "11px", fontWeight: 600, }} data-tooltip-id="OverSpeedIconLimitDataFETCHING_DATANewOne11">
                          <img
                                          src={OverSpeedIconLimit}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           
                                        /> | <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           
                                        />

                       <ReactTooltip
                        id="OverSpeedIconLimitDataFETCHING_DATANewOne11"
                        place="top"                       
                        content={"Kms/hr"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                     
                            </th>
                          
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="fetch_data"
                      style={{
                        height: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {displayText.FETCHING_DATA}
                    </p>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>

              <Col sm={6} lg={2}>
            <Card>
              <CardHeader className="cardHeader" style={{padding:"5px"}}>
                <h4 className="card-title">Mobile Call Use</h4>
              </CardHeader>
              {callUseData === true ? (
                <CardBody
                  style={{ height: 310, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                         <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="mobileCallUseIconLimitData"
                                    
                                    
                                    
                                  />
                                  <ReactTooltip
                        id="mobileCallUseIconLimitData"
                        place="top"                       
                        content={"HH:MM"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                                  </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="mobileCallUseIconLimitDataDistance"
                                      />
                                      <ReactTooltip
                        id="mobileCallUseIconLimitDataDistance"
                        place="top"                       
                        content={"Kms"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      /></th>
                              </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="mobileCallUseIconLimitDataOverSpeed"
                                        />
                                        <ReactTooltip
                        id="mobileCallUseIconLimitDataOverSpeed"
                        place="top"                       
                        content={"Kms/hr"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                            </th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {(mobileCallUseDataList || []).map((apiData, key) => (
                          <tr key={key}>
                            <td style={{ fontSize: "11px" }}>{dayjs(apiData.date).format("HH:mm")}</td>
                            <td style={{ fontSize: "11px" }}>
                              {apiData.distance}
                            </td>
                            <td style={{ fontSize: "11px" }}>
                              {apiData.speed}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              ) : callUseData === false ? (
                <CardBody
                  style={{ height: 310, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                         <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="mobileCallUseIconLimitDataNot"
                                    
                                    
                                    
                                  />
                                  <ReactTooltip
                        id="mobileCallUseIconLimitDataNot"
                        place="top"                       
                        content={"HH:MM"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                                  </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="mobileCallUseIconLimitDataDistanceNot"
                                      />
                                      <ReactTooltip
                        id="mobileCallUseIconLimitDataDistanceNot"
                        place="top"                       
                        content={"Kms"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      /></th>
                              </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="mobileCallUseIconLimitDataOverSpeedNot"
                                        />
                                        <ReactTooltip
                        id="mobileCallUseIconLimitDataOverSpeedNot"
                        place="top"                       
                        content={"Kms/hr"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                            </th>
                         
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="data_not_available"
                      style={{
                        height: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {displayText.DATA_NOT_AVAILABLE}
                    </p>
                  </div>
                </CardBody>
              ) : callUseData === "failed" ? (
                <CardBody
                  style={{ height: 310, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="mobileCallUseIconLimitDataNotER_1206"
                                    
                                    
                                    
                                  />
                                  <ReactTooltip
                        id="mobileCallUseIconLimitDataNotER_1206"
                        place="top"                       
                        content={"HH:MM"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                                  </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="mobileCallUseIconLimitDataDistanceNotER_1206"
                                      />
                                      <ReactTooltip
                        id="mobileCallUseIconLimitDataDistanceNotER_1206"
                        place="top"                       
                        content={"Kms"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      /></th>
                              </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="mobileCallUseIconLimitDataOverSpeedNotER_1206"
                                        />
                                        <ReactTooltip
                        id="mobileCallUseIconLimitDataOverSpeedNotER_1206"
                        place="top"                       
                        content={"Kms/hr"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                            </th>
                          
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="data_not_available"

                    >
                      {displayText.ER_1206}
                    </p>
                  </div>
                </CardBody>
              ) : (
                <CardBody
                  style={{ height: 310, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="mobileCallUseIconLimitDataNotFetching"
                                    
                                    
                                    
                                  />
                                  <ReactTooltip
                        id="mobileCallUseIconLimitDataNotFetching"
                        place="top"                       
                        content={"HH:MM"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                                  </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="mobileCallUseIconLimitDataDistanceNotFetching"
                                      />
                                      <ReactTooltip
                        id="mobileCallUseIconLimitDataDistanceNotFetching"
                        place="top"                       
                        content={"Kms"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      /></th>
                              </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="mobileCallUseIconLimitDataOverSpeedNotFetching"
                                        />
                                        <ReactTooltip
                        id="mobileCallUseIconLimitDataOverSpeedNotFetching"
                        place="top"                       
                        content={"Kms/hr"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                            </th>
                          
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="fetch_data"
                      style={{
                        height: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {displayText.FETCHING_DATA}
                    </p>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>


              <Col sm={6} lg={2}>
            <Card>
              <CardHeader className="cardHeader" style={{padding:"5px"}}>
                <h4 className="card-title">Mobile Screen Use</h4>
              </CardHeader>
              {screenUseData === true ? (
                <CardBody
                  style={{ height: 310, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="mobileScreenUseIconLimitData"
                                    
                                    
                                    
                                  />
                                  <ReactTooltip
                        id="mobileScreenUseIconLimitData"
                        place="top"                       
                        content={"HH:MM"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                                  </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="mobileScreenUseIconLimitDataDistance"
                                      />
                                      <ReactTooltip
                        id="mobileScreenUseIconLimitDataDistance"
                        place="top"                       
                        content={"Kms"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      /></th>
                              </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="mobileScreenUseIconLimitDataOverSpeed"
                                        />
                                        <ReactTooltip
                        id="mobileScreenUseIconLimitDataOverSpeed"
                        place="top"                       
                        content={"Kms/hr"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                            </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        {(mobileScreenUse || []).map((apiData, key) => (
                          <tr key={key}>
                            <td style={{ fontSize: "11px" }}>{dayjs(apiData.date).format("HH:mm")}</td>
                            <td style={{ fontSize: "11px" }}>
                              {apiData.distance}
                            </td>
                            <td style={{ fontSize: "11px" }}>
                              {apiData.speed}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              ) : screenUseData === false ? (
                <CardBody
                  style={{ height: 310, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                         <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="mobileScreenUseIconLimitDataNotER_1206"
                                    
                                    
                                    
                                  />
                                  <ReactTooltip
                        id="mobileScreenUseIconLimitDataNotER_1206"
                        place="top"                       
                        content={"HH:MM"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                                  </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="mobileScreenUseIconLimitDataDistanceNotER_1206"
                                      />
                                      <ReactTooltip
                        id="mobileScreenUseIconLimitDataDistanceNotER_1206"
                        place="top"                       
                        content={"Kms"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      /></th>
                              </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="mobileScreenUseIconLimitDataOverSpeedNotER_1206"
                                        />
                                        <ReactTooltip
                        id="mobileScreenUseIconLimitDataOverSpeedNotER_1206"
                        place="top"                       
                        content={"Kms/hr"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                            </th>
                          
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="data_not_available"
                      style={{
                        height: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {displayText.DATA_NOT_AVAILABLE}
                    </p>
                  </div>
                </CardBody>
              ) : screenUseData === "failed" ? (
                <CardBody
                  style={{ height: 310, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="mobileScreenUseIconLimitDataNot"
                                    
                                    
                                    
                                  />
                                  <ReactTooltip
                        id="mobileScreenUseIconLimitDataNot"
                        place="top"                       
                        content={"HH:MM"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                                  </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="mobileScreenUseIconLimitDataDistanceNot"
                                      />
                                      <ReactTooltip
                        id="mobileScreenUseIconLimitDataDistanceNot"
                        place="top"                       
                        content={"Kms"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      /></th>
                              </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="mobileScreenUseIconLimitDataOverSpeedNot"
                                        />
                                        <ReactTooltip
                        id="mobileScreenUseIconLimitDataOverSpeedNot"
                        place="top"                       
                        content={"Kms/hr"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                            </th>
                          
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="data_not_available"

                    >
                      {displayText.ER_1206}
                    </p>
                  </div>
                </CardBody>
              ) : (
                <CardBody
                  style={{ height: 310, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="mobileScreenUseIconLimitDataNotFetching"
                                    
                                    
                                    
                                  />
                                  <ReactTooltip
                        id="mobileScreenUseIconLimitDataNotFetching"
                        place="top"                       
                        content={"HH:MM"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                                  </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="mobileScreenUseIconLimitDataDistanceNotFetching"
                                      />
                                      <ReactTooltip
                        id="mobileScreenUseIconLimitDataDistanceNotFetching"
                        place="top"                       
                        content={"Kms"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      /></th>
                              </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="mobileScreenUseIconLimitDataOverSpeedNotFetching"
                                        />
                                        <ReactTooltip
                        id="mobileScreenUseIconLimitDataOverSpeedNotFetching"
                        place="top"                       
                        content={"Kms/hr"}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                            </th>
                         
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="fetch_data"
                      style={{
                        height: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {displayText.FETCHING_DATA}
                    </p>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>

              <Col xl={2} lg={2}>
                <Card style={{ height: 350 }}>
                  <CardHeader className="cardHeader" style={{padding:"5px"}}>
                    <h4 className="card-title">
                      Acceleration / Cornering / Braking
                    </h4>
                  </CardHeader>
                  {acbTableData === true ? (
                    <CardBody
                      style={{ height: 300, overflow: "auto", padding: "0px" }}
                    >
                      <div>
                        <Table className="table mb-0">
                          <thead
                            style={{
                              zIndex: 1,
                              position: "sticky",
                              top: "0",
                              background: "white",
                            }}
                          >
                           <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="accBrakCorn01"
                                  />
                                  <ReactTooltip
                                id="accBrakCorn01"
                                place="top"
                                content="HH:MM"
                                style={{ background: "black" }}
                              /></th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                        src={UBIStressStrain}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                         data-tooltip-id="accBrakCornTitle01"
                                      />
                                       <ReactTooltip
                                id="accBrakCornTitle01"
                                place="top"
                                content="Type"
                                style={{ background: "black" }}
                              /></th>
                              </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={subTypeIcons}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="accBrakCornTitleSubType01"
                                        />
                                        <ReactTooltip
                                id="accBrakCornTitleSubType01"
                                place="top"
                                content="Sub Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="accBrakCornTitleSubType01Kms"
                                        />
                                        <ReactTooltip
                                id="accBrakCornTitleSubType01Kms"
                                place="top"
                                content="Kms/hr"
                                style={{ background: "black" }}
                              />
                            </th>
                         
                        </tr>
                          </thead>
                          <tbody>
                            {(stressData || []).map((apiData, key) => (
                              <tr key={key}>
                                <td style={{ fontSize: "11px" }}>
                                  {dayjs(apiData.date).format("HH:mm")}
                                </td>
                                <td style={{ fontSize: "11px" }}>
                                  {typeMapping[apiData.type] || apiData.type}
                                </td>
                                <td style={{ fontSize: "11px" }}>
                                  {apiData.subCategoryLevel
                                    ? apiData.subCategoryLevel
                                      .charAt(0)
                                      .toUpperCase() +
                                    apiData.subCategoryLevel
                                      .slice(1)
                                      .toLowerCase()
                                    : ""}
                                </td>
                                <td style={{ fontSize: "11px" }}>
                                  {apiData.speed}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  ) : acbTableData === false ? (
                    <CardBody
                      style={{ height: 390, overflow: "auto", padding: "0px" }}
                    >
                      <div>
                        <Table className="table mb-0">
                          <thead
                            style={{
                              zIndex: 1,
                              position: "sticky",
                              top: "0",
                              background: "white",
                            }}
                          >
                            <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="accBrakCorn01Not"
                                  />
                                  <ReactTooltip
                                id="accBrakCorn01Not"
                                place="top"
                                content="HH:MM"
                                style={{ background: "black" }}
                              /></th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                        src={UBIStressStrain}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                         data-tooltip-id="accBrakCornTitle01Not"
                                      />
                                       <ReactTooltip
                                id="accBrakCornTitle01Not"
                                place="top"
                                content="Type"
                                style={{ background: "black" }}
                              /></th>
                              </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={subTypeIcons}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="accBrakCornTitleSubType01Not"
                                        />
                                        <ReactTooltip
                                id="accBrakCornTitleSubType01Not"
                                place="top"
                                content="Sub Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="accBrakCornTitleSubType01KmsNot"
                                        />
                                        <ReactTooltip
                                id="accBrakCornTitleSubType01KmsNot"
                                place="top"
                                content="Kms/hr"
                                style={{ background: "black" }}
                              />
                            </th>
                         
                        </tr>
                          </thead>
                        </Table>
                        <p
                          className="data_not_available"
                          style={{
                            height: 390,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {displayText.DATA_NOT_AVAILABLE}
                        </p>
                      </div>
                    </CardBody>
                  ) : acbTableData === "failed" ? (
                    <CardBody
                      style={{ height: 390, overflow: "auto", padding: "0px" }}
                    >
                      <div>
                        <Table className="table mb-0">
                          <thead
                            style={{
                              zIndex: 1,
                              position: "sticky",
                              top: "0",
                              background: "white",
                            }}
                          >
                             <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="accBrakCorn01Er_1206"
                                  />
                                  <ReactTooltip
                                id="accBrakCorn01Er_1206"
                                place="top"
                                content="HH:MM"
                                style={{ background: "black" }}
                              /></th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                        src={UBIStressStrain}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                         data-tooltip-id="accBrakCornTitle01Er_1206"
                                      />
                                       <ReactTooltip
                                id="accBrakCornTitle01Er_1206"
                                place="top"
                                content="Type"
                                style={{ background: "black" }}
                              /></th>
                              </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={subTypeIcons}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="accBrakCornTitleSubType01Er_1206"
                                        />
                                        <ReactTooltip
                                id="accBrakCornTitleSubType01Er_1206"
                                place="top"
                                content="Sub Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="accBrakCornTitleSubType01KmsEr_1206"
                                        />
                                        <ReactTooltip
                                id="accBrakCornTitleSubType01KmsEr_1206"
                                place="top"
                                content="Kms/hr"
                                style={{ background: "black" }}
                              />
                            </th>
                          
                        </tr>
                          </thead>
                        </Table>
                        <p
                          className="data_not_available"
                          style={{
                            height: 390,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {displayText.ER_1206}
                        </p>
                      </div>
                    </CardBody>
                  ) : (
                    <CardBody
                      style={{ height: 380, overflow: "auto", padding: "0px" }}
                    >
                      <div>
                        <Table className="table mb-0">
                          <thead
                            style={{
                              zIndex: 1,
                              position: "sticky",
                              top: "0",
                              background: "white",
                            }}
                          >
                             <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="accBrakCorn01Er_1206Fetch"
                                  />
                                  <ReactTooltip
                                id="accBrakCorn01Er_1206Fetch"
                                place="top"
                                content="HH:MM"
                                style={{ background: "black" }}
                              /></th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                        src={UBIStressStrain}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                         data-tooltip-id="accBrakCornTitle01Er_1206Fetch"
                                      />
                                       <ReactTooltip
                                id="accBrakCornTitle01Er_1206Fetch"
                                place="top"
                                content="Type"
                                style={{ background: "black" }}
                              /></th>
                              </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={subTypeIcons}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="accBrakCornTitleSubType01Er_1206Fetch"
                                        />
                                        <ReactTooltip
                                id="accBrakCornTitleSubType01Er_1206Fetch"
                                place="top"
                                content="Sub Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="accBrakCornTitleSubType01KmsEr_1206Fetch"
                                        />
                                        <ReactTooltip
                                id="accBrakCornTitleSubType01KmsEr_1206Fetch"
                                place="top"
                                content="Kms/hr"
                                style={{ background: "black" }}
                              />
                            </th>
                          
                        </tr>
                          </thead>
                        </Table>
                        <p
                          className="fetch_data"
                          style={{
                            height: 380,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {displayText.FETCHING_DATA}
                        </p>
                      </div>
                    </CardBody>
                  )}
                </Card>
              </Col>
              <Col xl={2} lg={2}>
                <Card style={{ height: 350 }}>
                  <CardHeader className="cardHeader" style={{padding:"5px"}}>
                    <h4 className="card-title">Driver State Detection</h4>
                  </CardHeader>
                  <CardBody
                    style={{
                      padding: "20px 10px",
                    }}
                  >
                    {driverStateDetectionData === true ? (
                      ""
                    ) : driverStateDetectionData === false ? (
                      <p className="data_not_available mb-2">
                        {displayText.DATA_NOT_AVAILABLE}
                      </p>
                    ) : driverStateDetectionData === "failed" ? (
                      <p className="data_not_available mb-2">
                        {displayText.ER_1206}
                      </p>
                    ) : (
                      <p className="fetch_data mb-2">
                        {displayText.FETCHING_DATA}
                      </p>
                    )}
                    <div className="table-responsive">
                      <Table
                        id="riskstyles"
                        className="table table-stripe mb-0 text-center"
                      >
                        <thead>
                          <tr>
                            
                            <th
                              className="pt-0"
                              style={{
                                textAlign: "start",
                                fontSize: "11px",
                                fontWeight: 600,
                                padding: "0px 20px 12px 13px",
                                whiteSpace:"nowrap"
                              }}
                            >
                              <img
                                          src={UBIDrivingState}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="DriverStateDetectionNormalIconDriverStateNew01"

                                        />
                                        <ReactTooltip
                                id="DriverStateDetectionNormalIconDriverStateNew01"
                                place="top"
                                content="Driver State"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              className="pt-0"
                              style={{
                                textAlign: "start",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                              <img
                                        src={CountImage}
                                        alt="icons2"
                                        style={{ height: "25px"}}
                                         data-tooltip-id="DriverStateDetectionNormalIconDriverCount"
                                      />
                                      <ReactTooltip
                                id="DriverStateDetectionNormalIconDriverCount"
                                place="top"
                                content="Count"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              className="pt-0"
                              style={{
                                textAlign: "start",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                             <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="DriverStateDetectionNormalIconDriverKms"
                                      />
                                      <ReactTooltip
                                id="DriverStateDetectionNormalIconDriverKms"
                                place="top"
                                content="Kms"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              className="pt-0"
                              style={{
                                textAlign: "start",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                             <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px"}}
                                         data-tooltip-id="DriverStateDetectionNormalIconDriverMins"
                                      />
                                      <ReactTooltip
                                id="DriverStateDetectionNormalIconDriverMins"
                                place="top"
                                content="Mins"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          
                          <tr>
                           
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              <img
                                src={NormalImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                                data-tooltip-id="driverStateNormalIcon"

                              />
                              <ReactTooltip
                                id="driverStateNormalIcon"
                                place="top"
                                content="Normal"
                                style={{ background: "black" }}
                              />
                             
                            </td>

                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.driverNormal}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.distanceStateNormal}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.durationStateNormal}
                            </td>
                          </tr>
                          <tr>
                           
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              <img
                                src={TiredImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                                 data-tooltip-id="driverStateTiredIcon"
                              />

<ReactTooltip
                                id="driverStateTiredIcon"
                                place="top"
                                content="Tired"
                                style={{ background: "black" }}
                              />
                              
                            </td>

                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.driverTried}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.distanceStateTired}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.durationStateTired}
                            </td>
                          </tr>
                          <tr>
                            
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              <img
                                src={AngryImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                                data-tooltip-id="driverStateAngryIcon"
                              />

<ReactTooltip
                                id="driverStateAngryIcon"
                                place="top"
                                content="Angry"
                                style={{ background: "black" }}
                              />
                              
                            </td>

                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.driverAngry}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.distanceStateAngry}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.durationStateAngry}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>             

              <Col xl={2} lg={2}>
                <Card style={{ height: 350 }}>
                  <CardHeader className="cardHeader" style={{padding:"5px"}}>
                    <h4 className="card-title">
                      Driving Style Estimation
                    </h4>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 10px" }}>
                    {drivingStyleDataCount === true ? (
                      ""
                    ) : drivingStyleDataCount === false ? (
                      <p className="data_not_available mb-2">
                        {displayText.DATA_NOT_AVAILABLE}
                      </p>
                    ) : drivingStyleDataCount === "failed" ? (
                      <p className="data_not_available mb-2">
                        {displayText.ER_1206}
                      </p>
                    ) : (
                      <p className="fetch_data mb-2">
                        {displayText.FETCHING_DATA}
                      </p>
                    )}
                    <div className="table-responsive">
                      <Table
                        id="riskstyles"
                        className="table table-stripe mb-0 text-center"
                      >
                        <thead>
                        <tr>
                            
                            <th
                              className="pt-0"
                              style={{
                                textAlign: "start",
                                fontSize: "11px",
                                fontWeight: 600,
                                padding: "0px 20px 12px 13px",
                                whiteSpace:"nowrap"
                              }}
                            >
                             <img
                                            src={UBIDrivingStyle}
                                            alt="icons2"
                                            style={{ height: "25px" }}
                                            data-tooltip-id="DriverStyleDetectionNormalIconDriverStyle"
                                          />
                                          <ReactTooltip
                                id="DriverStyleDetectionNormalIconDriverStyle"
                                place="top"
                                content="Driving Style"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              className="pt-0"
                              style={{
                                textAlign: "start",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                              <img
                                        src={CountImage}
                                        alt="icons2"
                                        style={{ height: "25px"}}
                                         data-tooltip-id="DriverStyleDetectionNormalIconDriverCount"
                                      />
                                      <ReactTooltip
                                id="DriverStyleDetectionNormalIconDriverCount"
                                place="top"
                                content="Count"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              className="pt-0"
                              style={{
                                textAlign: "start",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                             <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="DriverStyleDetectionNormalIconDriverKms"
                                      />
                                      <ReactTooltip
                                id="DriverStyleDetectionNormalIconDriverKms"
                                place="top"
                                content="Kms"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              className="pt-0"
                              style={{
                                textAlign: "start",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                             <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px"}}
                                         data-tooltip-id="DriverStyleDetectionNormalIconDriverMins"
                                      />
                                      <ReactTooltip
                                id="DriverStyleDetectionNormalIconDriverMins"
                                place="top"
                                content="Mins"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                         
                          <tr>
                           
                            <td
                              style={{ textAlign: "start", fontSize: "12px" }}
                            >
                              <img
                                src={NormalImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                                data-tooltip-id="driverStyleNormalIcon"
                              />

                             <ReactTooltip
                                id="driverStyleNormalIcon"
                                place="top"
                                content="Normal"
                                style={{ background: "black" }}
                              />
                              
                            </td>

                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.drivingNormal}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.distanceStyleNormal}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.durationStyleNormal}
                            </td>
                          </tr>
                          <tr>
                            
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              <img
                                src={SportiveImge}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                              data-tooltip-id="driverStyleSportiveIcon"
                              />

<ReactTooltip
                                id="driverStyleSportiveIcon"
                                place="top"
                                content="Sportive"
                                style={{ background: "black" }}
                              />
                              
                            </td>

                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.drivingSportive}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.distanceStyleSportive}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.durationStyleSportive}
                            </td>
                          </tr>
                          <tr>
                            
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              <img
                                src={EcoImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                                data-tooltip-id="driverStyleEcoIcon"
                             />
                              <ReactTooltip
                                id="driverStyleEcoIcon"
                                place="top"
                                content="Eco"
                                style={{ background: "black" }}
                              />
                              
                            </td>

                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.drivingEco}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.distanceStyleEco}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.durationStyleEco}
                            </td>
                          </tr>
                          <tr>
                            
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              <img
                                src={ChaoticImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                                 data-tooltip-id="driverStyleChaoticIcon"
                              />

<ReactTooltip
                                id="driverStyleChaoticIcon"
                                place="top"
                                content="Chaotic"
                                style={{ background: "black" }}
                              />
                              
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.drivingChaotic}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.distanceStyleChaotic}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.durationStyleChaotic}
                            </td>
                          </tr>
                          <tr>
                            
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              <img
                                src={AggressiveImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                                data-tooltip-id="driverStyleAggressiveIcon"
                             />

<ReactTooltip
                                id="driverStyleAggressiveIcon"
                                place="top"
                                content="Aggressive"
                                style={{ background: "black" }}
                              />
                              
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.drivingAggressive}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.distanceStyleAggressive}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.durationStyleAggressive}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              
            </Row>        
         
          
            <Row style={{marginTop:"-10px"}}>
              <Col xl={12} lg={12}>
                <Card>
                  <CardHeader className="cardHeader" style={{padding:"5px"}}>
                    <h4 className="card-title">
                      Risk Alerts{" "}
                      <span style={{ fontWeight: 400, fontSize: "12px" }}>
                        (Total Alerts - {totalAlerts})
                      </span>
                    </h4>
                  </CardHeader>
                </Card>
              </Col>
            </Row>

            <Row>
              {riskDataListleng === true ? (
                ""
              ) : riskDataListleng === false ? (
                <p className="data_not_available mb-2">
                  {displayText.DATA_NOT_AVAILABLE}
                </p>
              ) : riskDataListleng === "failed" ? (
                <p className="data_not_available mb-2">{displayText.ER_1206}</p>
              ) : (
                <p className="fetch_data mb-2">{displayText.FETCHING_DATA}</p>
              )}
              {(riskDataList || []).map((widgets, key) => (
                <Col xl={3} md={4} sm={6} xs={12} key={key}>
                  <Row style={{marginBottom:"-11px"}}>
{widgets.map((widget) => (
  <Col xl={6} md={6} sm={6} xs={12} key={key}>

                  <Card className="card-h-100" style={{width:"100%"}}>
                    <CardBody style={{ padding: "5px" }}>
                      <Row className="align-items-center">
                        <Col xs={12}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              textAlign:"left"
                            }}
                          >
                            <div className="text-nowrap" style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                              <img
                                src={widget.image}
                                alt="cardOneImg"
                                height="25px"
                              />
                            </div>

                            <div style={{display:"flex",justifyContent:"center",flexDirection:"column", width:"70%"}}>
                              <span
                               
                                style={{ fontSize: "10px",textAlign:"left"}}
                              >
                                {widget.title}
                              </span>
                             
                            </div>
                            <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                            {/* <h4> */}
                               
                               <span
                                 className="counter-value"
                                 style={{
                                   textAlign: "center",
                                   alignItems: "center",
                                 }}
                               >
                                 <CountUp
                                   start={0}
                                   end={widget.overallData}
                                   
                                   duration={1}
                                 />
                                
                               </span>
                              
                             {/* </h4> */}
                            </div>
                            </div>
                           
                          
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  </Col>
                  ))}
                  </Row>
                </Col>
              ))}
            </Row>
            
          </div>
        </Container>
      </div>
      <ToastContainer style={{ width: "auto" }} />
    </React.Fragment>
  );
};

// Logout.propTypes = {
//   history: PropTypes.object,
// }
export default RideDetails;
