// import React, { useRef, useState } from "react";
// import {
//   Form,
//   FormFeedback,
//   FormGroup,
//   Input,
//   Label,
//   Button,
//   Table,
// } from "reactstrap";
// import { ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
// import Modal from "react-modal";
// import Loader from "../../../components/Common/loader";
// import { color } from "echarts";
// import { ToastContainer } from "react-toastify";
// import ToastService from "../../../components/Common/toastService";
// import userController from "../../../controller/User/UserController";
// import AddGroupIcons from "../../../../src/assets/images/new-employee.svg";
// import { Tooltip as ReactTooltip } from "react-tooltip";
// import "../../../components/CommonForBoth/TopbarDropdown/ProfileMenu.css";
// import ConformationPopup from "../../../components/Common/ConfirmationPopup";
// import { PiDownloadSimple } from "react-icons/pi";
// import { saveAs } from 'file-saver';
// import custom from '../../../assets/xlsx/SampleCustom.xlsx'
// import mobilenumber from '../../../assets/xlsx/SampleMobileNumber.xlsx'
// import emailaddress from '../../../assets/xlsx/SampleEmailAddress.xlsx'
// import imei from '../../../assets/xlsx/SampleImei.xlsx'
// import * as XLSX from 'xlsx';

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     width: "auto",
//     maxWidth: '50%',
//     maxHeight: "90%",
//     borderRadius: 10,
//     transform: "translate(-50%, -50%)",
//     padding: "0px",
//   },
// };
// const customStylesBulk = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     width: "55%",
//     maxHeight: "90%",
//     borderRadius: 10,
//     transform: "translate(-50%, -50%)",
//     padding: "0px",
//   },
// };

// export default function BulkUserModal() {
//   const [userNameType, setUserNameType] = useState("CUSTOM");
//   const [twoFa, setTwoFa] = useState("NO");
//   const [bulkUserModal, setBulkUserModal] = useState(false);
//   const [userNameValidationMsg, setUserNameValidationMsg] = useState("");
//   const [showLoader, setShowLoader] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [confirmModal, setConfirmModal] = useState(false);
//   const [closeConfirmModal, setCloseConfirmModal] = useState(false);
//   const [popupConfirmModal, setPopupConfirmModal] = useState(false);
//   const [cancelConfirmModal, setCancelConfirmModal] = useState(false);
//   const [noteBulk, setNoteBulk] = useState(false);
//   const [isValid, setIsValid] = useState(true);
//   const fileInputRef = useRef(null);
//   const [customValidation, setCustomValidation] = useState("ALPHA_NUMERIC");
//   const [count, setCount] = useState(0);
  
//   const createBulkUser = async (cFile) => {
//     const formData = new FormData();
//     formData.append("file", cFile);
//     // console.log("setting file formdata", cFile);
//     try {
//       const result = await userController.createBulkUserRegistration(
//         userNameType,
//         twoFa,
//         formData
//       );
//       const resultJSON = JSON.parse(result);
//       const status = resultJSON.status;
//       if (status === "SUCCESS") {
//         ToastService.successmsg("User Registered Successfully!");
//         setErrorMessage("");
//         setBulkUserModal(false);
//         setUserNameType("CUSTOM");
//         setTwoFa("NO");
//       } else if (status === "FAILED") {
//         ToastService.errormsg(resultJSON.error.message);
//       } else if (status === 412) {
//         ToastService.errormsg(resultJSON.validationErrorMessage);
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       // ToastService.errormsg("An error occurred. Please try again.");
//     }
//   };

//   function openBulkUserModal() {
//     // if(count > 1){
//     setNoteBulk(false);
//     setBulkUserModal(true);
//     resetFormField();
//     setPopupConfirmModal(false);
//   //   setCount(0);
//   //   } else {
//   //     ToastService.errormsg("Please download any file to proceed")
//   //   }
//   }
//   function resetFormField() {
//     setUserNameType("CUSTOM");
//     setTwoFa("NO");
//     setErrorMessage("");
//     setIsValid(true);
//     setCustomValidation("ALPHA_NUMERIC");
//   }

//   // function handleSubmit(event) {
//   //   event.preventDefault(); // Prevent default form submission behavior
//   //   // Validate file
//   //   const file = event.target.file.files[0];
//   //   if (!file) {
//   //     setIsValid(false);
//   //     setErrorMessage("Upload a CSV file.");
//   //     if (fileInputRef.current) {
//   //       fileInputRef.current.value = null;
//   //     }
//   //     return;
//   //   } else if (file.name.split(".").pop() !== "xlsx") {
//   //     setIsValid(false);
//   //     setErrorMessage("Invalid file type. Please upload a xlsx file.");
//   //     // Clear the file input field
//   //     if (fileInputRef.current) {
//   //       fileInputRef.current.value = null;
//   //     }
//   //     return;
//   //   } else {
//   //     setIsValid(true);
//   //     parseExcelFile(file);
//   //   }
//   // }

//   function handleSubmit(event) {
//     event.preventDefault(); // Prevent default form submission behavior
  
//     // Validate file
//     const file = event.target.file.files[0];
//     if (!file) {
//       setIsValid(false);
//       setErrorMessage("Upload an XLSX file.");
//       if (fileInputRef.current) {
//         fileInputRef.current.value = null;
//       }
//       return;
//     } else if (file.name.split(".").pop().toLowerCase() !== "xlsx") {
//       setIsValid(false);
//       setErrorMessage("Invalid file type. Please upload an XLSX file.");
//       // Clear the file input field
//       if (fileInputRef.current) {
//         fileInputRef.current.value = null;
//       }
//       return;
//     } else {
//       setIsValid(true);
//       parseExcelFile(file);
//     }
//   }
  

//   function inputOnchange(event) {
//     setErrorMessage("");
//   }

//   function handleFormCancel(event) {
//     event.preventDefault();
//     setConfirmModal(true);
//     openPopup("Cancel");
//   }
//   function handleCloseConfirmYes() {
//     setConfirmModal(false);
//     setBulkUserModal(false);
//   }
//   function handleCloseConfirmNo() {
//     setConfirmModal(false);
//   }

//   function handleUserNameTypeChange(value) {
//     setUserNameType(value); // Update the selected username type
//     // Reset the error message when the username type changes
//     // setUserNameValidationMsg("");
//     setErrorMessage("");
//   }

//   const openConfirmationModal = () => {
//     setPopupConfirmModal(true);
//   };

//   const closeConfirmationModal = () => {
//     setCancelConfirmModal(false);
//     setPopupConfirmModal(false);
//   };

//   const openCancelConfirmModal = () => {
//     setCancelConfirmModal(true);
//     openPopup("Cancel");
//   };

//   const closeCancelConfirmationModal = () => {
//     setCancelConfirmModal(false);
//   };

//   // function parseCSVFile(file) {
//   //   setUserNameValidationMsg("");
//   //   const reader = new FileReader();
//   //   let emptyRowCount = 0;
//   //   let errorCount = 0;
//   //   let validCount = 0;
//   //   let totalCount = 0;
//   //   let headerError = 0;
//   //   let errorMessages = []; // Array to store error messages
//   //   let usernameRegex; // Declare usernameRegex here
//   //   const uniqueUsernames = new Set();

//   //   // Define expected headers
//   //   const expectedHeaders = ["firstName", "username", "deviceType"];

//   //   reader.onload = (event) => {
//   //     const csvData = event.target.result;
//   //     const lines = csvData.split("\n");
//   //     const userData = [];

//   //     // Extract headers from the CSV file
//   //     const headers = lines[0].split(",");

//   //     // Check if headers match expected headers
//   //     if (
//   //       !(
//   //         headers.length === expectedHeaders.length &&
//   //         expectedHeaders.every(
//   //           (value, index) => value === headers[index].trim()
//   //         )
//   //       )
//   //     ) {
//   //       headerError++;
//   //     }

//   //     // Initialize usernameRegex based on userNameType
//   //     switch (userNameType) {
//   //       case "CUSTOM":
//   //         setErrorMessage(
//   //           "Custom username must have min-8 and max-15 characters"
//   //         );
//   //         usernameRegex = /^[a-zA-Z0-9]{8,15}$/;
//   //         break;
//   //       case "EMAIL_ADDRESS":
//   //         setErrorMessage("Email should be like (e.g., app@motivai.tech)");
//   //         usernameRegex = /^[^\s@]{1,50}@[^\s@]+\.[^\s@]+$/;
//   //         break;
//   //       case "IMEI_NUMBER":
//   //         setErrorMessage("IMEI Number must have min-10 and max-40 characters");
//   //         usernameRegex = /^[a-zA-Z0-9]{10,40}$/;
//   //         break;
//   //       case "MOBILE_NUMBER":
//   //         setErrorMessage(
//   //           "Mobile number must have 10 digits (e.g: 9876543210)"
//   //         );
//   //         usernameRegex = /^\d{10}$/;
//   //         break;
//   //       default:
//   //         usernameRegex = /.*/; // Default regex to match any value
//   //     }

//   //     for (let i = 1; i < lines.length; i++) {
//   //       const data = lines[i].split(",");
//   //       if (data.length === headers.length) {
//   //         const firstName = data[0].trim();
//   //         const username = data[1].trim();
//   //         const deviceType = data[2].trim();

//   //         if (!firstName && !username && !deviceType) {
//   //           emptyRowCount++;
//   //         } else if (!username || !deviceType) {
//   //           errorCount++;
//   //         } else if (!usernameRegex.test(username)) {
//   //           errorCount++;
//   //         } else if (uniqueUsernames.has(username)) {
//   //           errorCount++; // Increment error count if duplicate username found
//   //         } else if (
//   //           !(
//   //             deviceType === "Ios" ||
//   //             deviceType === "Android" ||
//   //             deviceType === "IOS" ||
//   //             deviceType === "ANDROID" ||
//   //             deviceType === "ios" ||
//   //             deviceType === "android" || 
//   //             deviceType === "iOS"
//   //           )
//   //         ) {
//   //           errorCount++;
//   //         } else {
//   //           validCount++;
//   //         }

//   //         // Add username to the set
//   //         uniqueUsernames.add(username);

//   //         totalCount++; // Increment total count for every processed rowF
//   //         const user = {
//   //           firstName: firstName,
//   //           username: username,
//   //           deviceType: deviceType,
//   //         };
//   //         userData.push(user);
//   //       }
//   //     }

//   //     // console.log(`empty row count: ${emptyRowCount}`);
//   //     // console.log(`error Count: ${errorCount}`);
//   //     // console.log(`valid Count: ${validCount}`);
//   //     // console.log(`total count: ${totalCount}`);

//   //     // Check conditions for form submission
//   //     if (errorCount < 1 && validCount > 0 && headerError < 1) {
//   //       createBulkUser(file);
//   //       setTimeout(() => 3500);
//   //     } else if (headerError > 0) {
//   //       errorMessages = [
//   //         "Please check file header, It should be like firstName, username, deviceType in the first row of csv file.",
//   //       ];
//   //     } else {
//   //       errorMessages = [
//   //         "File contains errors. Please check and try again",
//   //         "May be due to empty fields.",
//   //         "May be due to invalid username type",
//   //         "May be due to duplicate usernames.",
//   //         "May be due to invalid device type",
//   //       ];
//   //     }
//   //     setErrorMessage(errorMessages);
//   //   };

//   //   reader.readAsText(file);
//   // }

//   // function parseCSVFile(file) {
//   //   setUserNameValidationMsg("");
//   //   const reader = new FileReader();
//   //   let errorCount = 0;
//   //   let validCount = 0;
//   //   let headerError = 0;
//   //   let errorMessages = []; // Array to store error messages
//   //   let usernameRegex; // Declare usernameRegex here
//   //   const uniqueUsernames = new Set();
//   //   const validDeviceTypes = ["ios", "android"];
//   //   const nameRegex = /^[a-zA-Z\s]+$/;

//   //   // Define expected headers
//   //   const expectedHeaders = ["firstName", "username", "deviceType"];

//   //   reader.onload = (event) => {
//   //     const csvData = event.target.result;
//   //     const lines = csvData.trim().split("\n");
//   //     const userData = [];

//   //     // Extract headers from the CSV file
//   //     const headers = lines[0].split(",");

//   //     // Check if headers match expected headers
//   //     if (!(headers.length === expectedHeaders.length && expectedHeaders.every((value, index) => value === headers[index].trim()))) {
//   //       errorMessages.push("Please check file header. It should be like firstName, username, deviceType in the first row of the CSV file.");
//   //       setErrorMessage(errorMessages);
//   //       return;
//   //     }

//   //     // Check if file is empty
//   //     if (lines.length == 1) {
//   //       if (!errorMessages.includes("File contains an empty row")) {
//   //         errorMessages.push("File cannot be empty");
//   //         errorCount++;
//   //       }
//   //     }

//   //     // Initialize usernameRegex based on userNameType and set corresponding error message
//   //     if (userNameType === "CUSTOM" && customValidation == "ALPHA_NUMERIC") {
//   //       usernameRegex = /^[a-zA-Z0-9]{8,15}$/;
//   //     } else if (userNameType === "CUSTOM" && customValidation == "NUMERIC") {
//   //       usernameRegex = /^[0-9]{8,10}$/;
//   //     } else if (userNameType === "EMAIL_ADDRESS") {
//   //       usernameRegex = /^[^\s@]{1,50}@[^\s@]+\.[^\s@]+$/;
//   //     } else if (userNameType === "IMEI_NUMBER") {
//   //       // usernameRegex = /^[0-9]{10,20}$/;
//   //       usernameRegex = /^\d{15}$/;
//   //     } else if (userNameType === "MOBILE_NUMBER") {
//   //       usernameRegex = /^\d{10}$/;
//   //     } else {
//   //       usernameRegex = /.*/;
//   //     }

//   //     for (let i = 1; i < lines.length; i++) {
//   //       const data = lines[i].split(",");
//   //       const firstName = data[0].trim(); // Trim to remove leading/trailing whitespace
//   //       const username = data[1].trim();
//   //       const deviceType = data[2].trim();

//   //       // Check for empty rows
//   //       if (!username) {
//   //         if (!errorMessages.includes("Username column contains empty values")) {
//   //           errorMessages.push("Username column contains empty values");
//   //           errorCount++;
//   //         }
//   //       } else if (!deviceType) {
//   //         if (!errorMessages.includes("Device type column contains empty values")) {
//   //           errorMessages.push("Device type column contains empty values");
//   //           errorCount++;
//   //         }
//   //       } else if (uniqueUsernames.has(username)) {
//   //         if (!errorMessages.includes("Username column contains duplicate values")) {
//   //           errorMessages.push("Username column contains duplicate values");
//   //           errorCount++;
//   //         }
//   //       } else if (!usernameRegex.test(username)) {
//   //         let errorMessage;
//   //         if (userNameType === "CUSTOM" && customValidation == "ALPHA_NUMERIC") {
//   //           errorMessage = "Invalid Custom Username: Must be 8 to 15 characters long and include both letters and numbers.";
//   //         } else if (userNameType === "CUSTOM" && customValidation == "NUMERIC") {
//   //           errorMessage = "Invalid Custom Username: Must be 8 to 10 characters long and include only numbers.";
//   //         } else if (userNameType === "EMAIL_ADDRESS") {
//   //           errorMessage = "Invalid Email Address: Email should be like (e.g., app@motivai.tech)";
//   //         } else if (userNameType === "MOBILE_NUMBER") {
//   //           errorMessage = "Invalid Mobile Number: Mobile number must have 10 digits (e.g: 9876543210)";
//   //         } else if (userNameType === "IMEI_NUMBER") {
//   //           errorMessage = "Invalid IMEI Number: IMEI Number must have min-10 and max-20 characters";
//   //         }

//   //         if (errorMessage && !errorMessages.includes(errorMessage)) {
//   //           errorMessages.push(errorMessage);
//   //           errorCount++;
//   //         }
//   //       } else if (!validDeviceTypes.includes(deviceType.toLowerCase())) {
//   //         if (!errorMessages.includes("Device type must be either 'iOS' or 'Android'")) {
//   //           errorMessages.push("Device type must be either 'iOS' or 'Android'");
//   //           errorCount++;
//   //         }
//   //       }  else if(!nameRegex.test(firstName)) {        
                //  if(!errorMessages.includes("First name must contain only alphabets and spaces")) {
                //     errorMessages.push("First name must contain only alphabets and spaces"); errorCount++; } 
                // } else {
//   //         validCount++;
//   //       }

//   //       // Add username to the set
//   //       uniqueUsernames.add(username);

//   //       const user = {
//   //         firstName: firstName,
//   //         username: username,
//   //         deviceType: deviceType,
//   //       };
//   //       userData.push(user);
//   //     }

//   //     // Check conditions for form submission
//   //     if (errorCount < 1 && validCount > 0 && headerError < 1) {
//   //       createBulkUser(file);
//   //       setTimeout(() => 3500);
//   //     }
//   //     setErrorMessage(errorMessages);
//   //   };

//   //   reader.readAsText(file);
//   // }


//   function parseExcelFile(file) {
//     setUserNameValidationMsg("");
//     let errorCount = 0;
//     let validCount = 0;
//     let headerError = 0;
//     let errorMessages = []; // Array to store error messages
//     let usernameRegex; // Declare usernameRegex here
//     const uniqueUsernames = new Set();
//     const validDeviceTypes = ["ios", "android"];

//     // Define expected headers
//     const expectedHeaders = ["firstName", "username", "deviceType"];

//     const reader = new FileReader();

//     reader.onload = (event) => {
//       const data = new Uint8Array(event.target.result);
//       const workbook = XLSX.read(data, { type: 'array' });
//       const sheetName = workbook.SheetNames[0];
//       const worksheet = workbook.Sheets[sheetName];
//       const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
//       const userData = [];

//       if (jsonData.length === 0) {
//         errorMessages.push("File cannot be empty");
//         errorCount++;
//         setErrorMessage(errorMessages);
//         return;
//       }

//       const headers = jsonData[0];

//       // Check if headers match expected headers
//       if (!(headers.length === expectedHeaders.length && expectedHeaders.every((value, index) => value === headers[index].trim()))) {
//         errorMessages.push("Please check file header. It should be like firstName, username, deviceType in the first row of the file.");
//         setErrorMessage(errorMessages);
//         return;
//       }

//       // Initialize usernameRegex based on userNameType and set corresponding error message
//       if (userNameType === "CUSTOM" && customValidation === "ALPHA_NUMERIC") {
//         usernameRegex = /^[a-zA-Z0-9]{8,15}$/;
//       } else if (userNameType === "CUSTOM" && customValidation === "NUMERIC") {
//         usernameRegex = /^[0-9]{8,10}$/;
//       } else if (userNameType === "EMAIL_ADDRESS") {
//         usernameRegex = /^[^\s@]{1,50}@[^\s@]+\.[^\s@]+$/;
//       } else if (userNameType === "IMEI_NUMBER") {
//         usernameRegex = /^\d{15}$/;
//       } else if (userNameType === "MOBILE_NUMBER") {
//         usernameRegex = /^\d{10}$/;
//       } else {
//         usernameRegex = /.*/;
//       }

//       for (let i = 1; i < jsonData.length; i++) {
//         const data = jsonData[i];
//         const firstName = data[0] ? data[0].trim() : ''; // Trim to remove leading/trailing whitespace
//         const username = data[1] ? data[1].trim() : '';
//         const deviceType = data[2] ? data[2].trim() : '';

//         // Check for empty rows
//         if (!username) {
//           if (!errorMessages.includes("Username column contains empty values")) {
//             errorMessages.push("Username column contains empty values");
//             errorCount++;
//           }
//         } else if (!deviceType) {
//           if (!errorMessages.includes("Device type column contains empty values")) {
//             errorMessages.push("Device type column contains empty values");
//             errorCount++;
//           }
//         } else if (uniqueUsernames.has(username)) {
//           if (!errorMessages.includes("Username column contains duplicate values")) {
//             errorMessages.push("Username column contains duplicate values");
//             errorCount++;
//           }
//         } else if (!usernameRegex.test(username)) {
//           let errorMessage;
//           if (userNameType === "CUSTOM" && customValidation === "ALPHA_NUMERIC") {
//             errorMessage = "Invalid Custom Username: Must be 8 to 15 characters long and include both letters and numbers.";
//           } else if (userNameType === "CUSTOM" && customValidation === "NUMERIC") {
//             errorMessage = "Invalid Custom Username: Must be 8 to 10 characters long and include only numbers.";
//           } else if (userNameType === "EMAIL_ADDRESS") {
//             errorMessage = "Invalid Email Address: Email should be like (e.g., app@motivai.tech)";
//           } else if (userNameType === "MOBILE_NUMBER") {
//             errorMessage = "Invalid Mobile Number: Mobile number must have 10 digits (e.g: 9876543210)";
//           } else if (userNameType === "IMEI_NUMBER") {
//             errorMessage = "Invalid IMEI Number: IMEI Number must have 15 digits.";
//           }

//           if (errorMessage && !errorMessages.includes(errorMessage)) {
//             errorMessages.push(errorMessage);
//             errorCount++;
//           }
//         } else if (!validDeviceTypes.includes(deviceType.toLowerCase())) {
//           if (!errorMessages.includes("Device type must be either 'iOS' or 'Android'")) {
//             errorMessages.push("Device type must be either 'iOS' or 'Android'");
//             errorCount++;
//           }
//         } else {
//           validCount++;
//         }

//         // Add username to the set
//         uniqueUsernames.add(username);

//         const user = {
//           firstName: firstName,
//           username: username,
//           deviceType: deviceType,
//         };
//         userData.push(user);
//       }

//       // Check conditions for form submission
//       if (errorCount < 1 && validCount > 0 && headerError < 1) {
//         createBulkUser(file);
//         setTimeout(() => 3500);
//       }
//       setErrorMessage(errorMessages);
//     };

//     reader.readAsArrayBuffer(file);
//   }


//   // _____________________________

//   const [popupData, setPopUpData] = useState({
//     Purpose: "",
//     message: "",
//     DataDetails: "",
//   });

//   const openPopup = (PopupType, Data) => {
//     if (PopupType == "Cancel") {
//       setPopUpData({
//         ...popupData,
//         Purpose: "Cancel",
//         message: "you want to cancel ?",
//       });
//     } else {
//       setPopUpData({
//         ...popupData,
//         Purpose: "Delete",
//         message:
//           "This action cannot be undone. The station will be removed from the list.",
//         DataDetails: Data ? Data : "",
//       });
//     }
//   };

//   const customSample = [
//     ["firstName", "username", "deviceType"],
//     ["John", "Johny001", "Android"],
//     ["Smith", "Smith002", "iOS"],
//     ["Chris", "Chris003", "Android"]
//   ];

//   const downloadCSV = (filename, data) => {
//     const csvContent = data.map(e => e.join(",")).join("\n");
//     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
//     saveAs(blob, filename);
//   };
//   // _______________________________________________

//   return (
//     <div>
//       <Modal
//         isOpen={popupConfirmModal}
//         style={{ overflowY: "scroll", ...customStyles }}
//         overlayClassName="custom-modal-overlay"
//       >
//         <ModalHeader
//           toggle={openCancelConfirmModal}
//           style={{
//             padding: "20px",
//             paddingBottom: "10px",
//             background: "#5156be1a",
//             alignItems: "center",
//           }}
//         >
//           <h5 style={{ fontSize: "20px" }}>NOTE</h5>
//         </ModalHeader>
//         <br />
//         {/* <div className="line-divider"></div> */}
//         <ModalBody className="p-2">
//           <div>
//             <ol>
//               <li>Username type should be same for all users</li>
//               <li>
//                 First row of your csv file should have the following headings
//                 respectively.
//                 <ul>
//                   <li>firstName</li>
//                   <li>username</li>
//                   <li>deviceType</li>
//                 </ul>
//               </li>
//               <li>
//                 Empty fields in username and deviceType (other than firstName)
//                 will be considered as Invalid.
//               </li>
//               <li>Device type value should be iOS or Android.</li>
//               <li>
//                 Ensure that the username is not already in use by any of your
//                 existing users.
//               </li>
//               <li>
//                 Default Password for Custom usertype will be{" "}
//                 <strong>Test@123</strong>.
//               </li>
//               <li>Please click the button below to download the sample file. Use the downloaded file to upload bulk users. Do not use your own file as it will cause an error.</li>
//               {/* <div className="d-flex align-items-center">
//                 <div className="me-2">
//                   <input type="radio" id="custom" name="userType" value="CUSTOM" onChange={handleRadioChange} className="me-1 mt-1" />
//                   <label htmlFor="custom" className="">Custom</label>
//                 </div>
//                 <div className="me-2">
//                   <input type="radio" id="mobileNumber" name="userType" value="MOBILE_NUMBER" onChange={handleRadioChange} className="me-1 mt-1" />
//                   <label htmlFor="mobileNumber">Mobile Number</label>
//                 </div>
//                 <div className="me-2">
//                   <input type="radio" id="emailAddress" name="userType" value="EMAIL_ADDRESS" onChange={handleRadioChange} className="me-1 mt-1" />
//                   <label htmlFor="emailAddress">Email Address</label>
//                 </div>
//                 <div>
//                   <input type="radio" id="imeiNumber" name="userType" value="IMEI_NUMBER" onChange={handleRadioChange} className="me-1 mt-1" />
//                   <label htmlFor="imeiNumber">IMEI Number</label>
//                 </div>
//               </div> */}
//               <div className="d-flex align-items-center gap-2 my-2 flex-wrap">
//                 <a href={custom} download="Custom.xlsx">
//                   <Button type="button" color="success" className="p-1" onClick={() => setCount(prev => prev + 1)}>Custom</Button>
//                 </a>
//                 <a href={mobilenumber} download="Mobile Number.xlsx">
//                   <Button type="button" color="success" className="p-1" onClick={() => setCount(prev => prev + 1)}>Mobile Number</Button>
//                 </a>
//                 <a href={emailaddress} download="Email Address.xlsx">
//                   <Button type="button" color="success" className="p-1" onClick={() => setCount(prev => prev + 1)}>Email Address</Button>
//                 </a>
//                 <a href={imei} download="IMEI Number.xlsx">
//                   <Button type="button" color="success" className="p-1" onClick={() => setCount(prev => prev + 1)}>IMEI Number</Button>
//                 </a>
//               </div>
//               {/* <li>
//                 <Button type="button" className="p-0" color="link" style={{ marginBottom: "2px" }}
//                   // onClick={() => downloadCSV('Sample.csv', customSample)}
//                   onClick={handleDownload}
//                 >
//                   Click here!
//                 </Button> to download a sample CSV file, and ensure the username format is as follows.
//               </li> */}
//               <li className="mb-1">After downloading the file, please fill in the data you want to upload in the first sheet named "Upload Sheet". The second sheet, named "Sample Sheet", is provided for your reference.</li>
//               <Table bordered style={{ width: '400px' }}>
//                 <thead>
//                   <tr>
//                     <th className="p-2" style={{ width: '200px' }}>Username Type</th>
//                     <th className="p-2" style={{ width: '200px' }}>Sample Username</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td className="p-2" style={{ width: '200px' }}>Custom</td>
//                     <td className="p-2" style={{ width: '200px' }}>johny001</td>
//                   </tr>
//                   <tr>
//                     <td className="p-2" style={{ width: '200px' }}>Email Address</td>
//                     <td className="p-2" style={{ width: '200px' }}>johny001@domain.com</td>
//                   </tr>
//                   <tr>
//                     <td className="p-2" style={{ width: '200px' }}>IMEI Number</td>
//                     <td className="p-2" style={{ width: '200px' }}>3557890098766670</td>
//                   </tr>
//                   <tr>
//                     <td className="p-2" style={{ width: '200px' }}>Mobile Number</td>
//                     <td className="p-2" style={{ width: '200px' }}>7700000001</td>
//                   </tr>
//                 </tbody>
//               </Table>
//             </ol>
//             <p style={{ marginLeft: "20px" }}>
//               Please ensure that you adhere to all the aforementioned
//               instructions accurately.
//             </p>
//             <div className="d-flex align-items-center justify-content-center">
//               <button
//                 style={{ marginBottom: "15px" }}
//                 className="btn btn-primary"
//                 onClick={openBulkUserModal}
//               >
//                 Proceed
//               </button>
//             </div>
//           </div>
//         </ModalBody>
//       </Modal>

//       <Modal
//         isOpen={cancelConfirmModal}
//         // onRequestClose={closeConfirmationModal}
//         toggle={closeCancelConfirmationModal}
//         contentLabel="Cancelpopup Modal"
//         style={{
//           content: {
//             height: "190px",
//             width: "340px",
//             margin: "auto",
//             borderRadius: "10px",
//             padding: "0px",
//             backgroundColor: "#fff",
//           },
//           overlay: { backgroundColor: "rgba(0,0,0,0.5)" }, // /* Black background with opacity */
//         }}
//       >
//         <ModalHeader
//           style={{
//             height: "40px",
//             backgroundColor: "#5156BE",
//             width: "100%",
//             display: "felx",
//             justifyContent: "center",
//           }}
//         >
//           <span style={{ fontWeight: 700, fontSize: "20px", color: "#fff" }}>
//             Confirm Action{" "}
//           </span>
//         </ModalHeader>

//         <ConformationPopup
//           className="text-right d-flex align-items-center "
//           toggle={closeCancelConfirmationModal}
//           Data={popupData}
//           formSubmit={closeConfirmationModal}
//           cancelSubmit={closeCancelConfirmationModal}
//         />
//       </Modal>

//       <ToastContainer style={{ width: "auto" }} />
//       {/* <button
//         className="button m-2"
//         onClick={() => setNoteBulk(true)}
//         style={{
//           backgroundColor: "white",
//           color: "black",
//           border: "1px solid #555555",
//         }}
//       >
//         <i className="fa fa-plus" style={{ fontSize: "10px" }}></i>
//         &nbsp;Add Bulk User
//       </button> */}
//       <div
//         className="my-3 d-flex align-items-center justify-content-center"
//         style={{ height: "40px" }}
//       >
//         <button
//           type="button"
//           className="btn btn-soft-secondary btn-sm m-1"
//           // onClick={() => openConfirmationModal}

//           onClick={openConfirmationModal}
//         >
//           <img
//             src={AddGroupIcons}
//             alt="addGroupIcons"
//             style={{ height: "25px", width: "25px" }}
//             data-tooltip-id="AddBulkUserTool"
//           />
//           <ReactTooltip
//             id="AddBulkUserTool"
//             place="top"
//             content="Add Bulk User"
//             style={{ background: "black" }}
//           />
//         </button>
//       </div>

//       <Modal
//         isOpen={bulkUserModal}
//         style={{ overflowY: "scroll", ...customStylesBulk }}
//         overlayClassName="custom-modal-overlay"
//       >
//         <Form onSubmit={handleSubmit}>
//           <ModalHeader
//             toggle={handleFormCancel}
//             style={{
//               padding: "20px",
//               paddingBottom: "10px",
//               background: "#5156be1a",
//               alignItems: "center",
//             }}
//           >
//             <h5 style={{ fontSize: "20px" }}>Add Bulk User</h5>
//           </ModalHeader>
//           {/* <div className="line-divider"></div> */}
//           {/* <br/> */}
//           <ModalBody
//             style={{
//               padding: "20px",
//             }}
//           >
//             <FormGroup>
//               <Label>Username Type</Label>
//               <select
//                 className="form-select"
//                 value={userNameType}
//                 onChange={(e) => handleUserNameTypeChange(e.target.value)}
//               >
//                 <option value={"CUSTOM"}>Custom</option>
//                 <option value={"EMAIL_ADDRESS"}>Email Address</option>
//                 <option value={"IMEI_NUMBER"}>IMEI Number</option>
//                 <option value={"MOBILE_NUMBER"}>Mobile Number</option>
//               </select>
//               {(userNameType === "CUSTOM" && customValidation === "ALPHA_NUMERIC") ? (
//                 <p style={{ marginTop: "4px" }}>
//                   Note: Custom username must have min-8 and max-15 characters
//                 </p>
//               ) : (userNameType === "CUSTOM" && customValidation === "NUMERIC") ? (
//                 <p style={{ marginTop: "4px" }}>
//                   Note: Custom username must have min-8 and max-10 characters
//                 </p>
//               ) : userNameType === "IMEI_NUMBER" ? (
//                 <p style={{ marginTop: "4px" }}>
//                   Note: IMEI Number must have min-10 and max-20 characters{" "}
//                 </p>
//               ) : userNameType === "EMAIL_ADDRESS" ? (
//                 <p style={{ marginTop: "4px" }}>
//                   Note: Email should be like (e.g: app@motivai.tech)
//                 </p>
//               ) : userNameType === "MOBILE_NUMBER" ? (
//                 <p style={{ marginTop: "4px" }}>
//                   Note: Mobile number must have 10 digits (e.g: 9876543210)
//                 </p>
//               ) : null}
//               {userNameValidationMsg && (
//                 <p style={{ color: "red" }}>{userNameValidationMsg}</p>
//               )}
//             </FormGroup>
//             {userNameType == "CUSTOM" && (
//               <FormGroup>
//                 <Label>Custom Username Format</Label>
//                 <select
//                   className="form-select"
//                   value={customValidation}
//                   onChange={(e) => setCustomValidation(e.target.value)}
//                 >
//                   <option value={"ALPHA_NUMERIC"}>Alphanumeric</option>
//                   <option value={"NUMERIC"}>Numeric</option>
//                 </select>
//               </FormGroup>
//             )}
//             {userNameType !== "IMEI_NUMBER" && (
//               <FormGroup>
//                 <Label>Enable 2FA (Two-Factor authentication)</Label>
//                 <select
//                   className="form-select"
//                   value={twoFa}
//                   onChange={(e) => setTwoFa(e.target.value)}
//                 >
//                   <option value={"NO"}>No</option>
//                   <option value={"YES"}>Yes</option>
//                 </select>
//               </FormGroup>
//             )}
//             <FormGroup className="mb-3">
//               {showLoader && <Loader />}
//               <Label for="csvFile">Upload XLSX file</Label>
//               <Input
//                 type="file"
//                 name="file"
//                 id="csvFile"
//                 accept=".xlsx"
//                 onChange={inputOnchange}
//                 ref={fileInputRef}
//                 invalid={!isValid}
//               />
//               {errorMessage && (
//                 <div style={{ color: "red" }}>
//                   {Array.isArray(errorMessage) && errorMessage.length > 1 ? (
//                     <ul>
//                       {errorMessage.map((msg, index) => (
//                         <li key={index}>{msg}</li>
//                       ))}
//                     </ul>
//                   ) : (
//                     errorMessage
//                   )}
//                 </div>
//               )}
//             </FormGroup>
//           </ModalBody>
//           <ModalFooter className="d-flex align-items-center justify-content-center">
//             <button
//               style={{ marginBottom: "15px" }}
//               className="btn btn-primary btn-sm "
//               type="submit"
//             >
//               Submit
//             </button>
//             <button
//               style={{ marginLeft: "15px", marginBottom: "15px" }}
//               className="btn btn-secondary btn-sm "
//               onClick={handleFormCancel}
//             >
//               Cancel
//             </button>
//           </ModalFooter>
//         </Form>
//       </Modal>

//       <Modal
//         isOpen={confirmModal}
//         toggle={handleCloseConfirmNo}
//         style={{
//           content: {
//             height: "190px",
//             width: "340px",
//             margin: "auto",
//             borderRadius: "10px",
//             padding: "0px",
//           },
//           overlay: { backgroundColor: "rgba(0,0,0,0.5)" }, // /* Black background with opacity */
//         }}
//       >
//         <ModalHeader
//           style={{
//             height: "40px",
//             backgroundColor: "#5156BE",
//             width: "100%",
//             display: "felx",
//             justifyContent: "center",
//           }}
//         >
//           <span style={{ fontWeight: 700, fontSize: "20px", color: "#fff" }}>
//             Confirm Action{" "}
//           </span>
//         </ModalHeader>
//         <ConformationPopup
//           Data={popupData}
//           formSubmit={handleCloseConfirmYes}
//           cancelSubmit={handleCloseConfirmNo}
//         />
//       </Modal>
//     </div>
//   );
// }

// ------------------------------------------------------------------------------------------------ //

import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Button,
  Table,
} from "reactstrap";
import { ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Modal from "react-modal";
import Loader from "../../../components/Common/loader";
import { color } from "echarts";
import { ToastContainer } from "react-toastify";
import ToastService from "../../../components/Common/toastService";
import userController from "../../../controller/User/UserController";
import AddGroupIcons from "../../../../src/assets/images/new-employee.svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "../../../components/CommonForBoth/TopbarDropdown/ProfileMenu.css";
import ConformationPopup from "../../../components/Common/ConfirmationPopup";
import { PiDownloadSimple } from "react-icons/pi";
import { saveAs } from 'file-saver';
import { SC_DIVISION_ID } from "../../../constant/StorageConstant";
import { deviceOptionConfig, optionConfig } from "../../../config/OptionConfig";
 
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "auto",
    maxWidth: '50%',
    maxHeight: "90%",
    borderRadius: 10,
    transform: "translate(-50%, -50%)",
    padding: "0px",
  },
};
const customStylesBulk = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "55%",
    maxHeight: "90%",
    borderRadius: 10,
    transform: "translate(-50%, -50%)",
    padding: "0px",
  },
};
 
export default function BulkUserModal({setReLoadGet}) {
  const [userNameType, setUserNameType] = useState("MOBILE_NUMBER");
  const [twoFa, setTwoFa] = useState("NO");
  const [bulkUserModal, setBulkUserModal] = useState(false);
  const [userNameValidationMsg, setUserNameValidationMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [closeConfirmModal, setCloseConfirmModal] = useState(false);
  const [popupConfirmModal, setPopupConfirmModal] = useState(false);
  const [cancelConfirmModal, setCancelConfirmModal] = useState(false);
  const [noteBulk, setNoteBulk] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const fileInputRef = useRef(null);
  const [customValidation, setCustomValidation] = useState("ALPHA_NUMERIC");

  const [availableOptions, setAvailableOptions] = useState([]);
  const [avaiableDeviceTypeOptions, setAvaiableDeviceTypeOptions] = useState([]);
    const validDivIds = ["divi00000000000000000000", "prod0000divi000000000001"];
    const divId = localStorage.getItem(SC_DIVISION_ID);

  useEffect(() => {
    if (validDivIds.includes(divId)) {
      setAvailableOptions(optionConfig.MOBILE_NUMBER);
      setAvaiableDeviceTypeOptions(deviceOptionConfig.ANDROID_IOS);
    } else {
      setAvailableOptions(optionConfig.CUSTOM_MOBILE_NUMBER_EMAIL_ADDRESS_IMEI_NUMBER);
      setAvaiableDeviceTypeOptions(deviceOptionConfig.ANDROID_IOS_IOS_SDK_ANDROID_SDK);
    }
  },[]);
 
  const createBulkUser = async (cFile) => {
    setShowLoader(true);
    const formData = new FormData();
    formData.append("file", cFile);
    // console.log("setting file formdata", cFile);
    try {
      const result = await userController.createBulkUserRegistration(
        userNameType,
        twoFa,
        formData
      );
      const resultJSON = JSON.parse(result);
      const status = resultJSON.status;
      if (status === "SUCCESS") {
        ToastService.successmsg("User Added Successfully!");
        setErrorMessage("");
        setBulkUserModal(false);
        setUserNameType("MOBILE_NUMBER");
        setTwoFa("NO");
        setReLoadGet(prev => prev + 1);
      } else if (status === "FAILED") {
        ToastService.errormsg(resultJSON.error.message);
      } else if (status === 412) {
        ToastService.errormsg(resultJSON.validationErrorMessage);
      }
    } catch (error) {
      console.error("Error:", error);
      ToastService.errormsg("An error occurred. Please try again.");
    } finally {
      setShowLoader(false);
    }
  };
 
  function openBulkUserModal() {
    setNoteBulk(false);
    setBulkUserModal(true);
    resetFormField();
    setPopupConfirmModal(false);
  }
  function resetFormField() {
    setUserNameType("MOBILE_NUMBER");
    setTwoFa("NO");
    setErrorMessage("");
    setIsValid(true);
    setCustomValidation("ALPHA_NUMERIC");
  }
 
  function handleSubmit(event) {
    event.preventDefault(); // Prevent default form submission behavior
    // Validate file
    const file = event.target.file.files[0];
    if (!file) {
      setIsValid(false);
      setErrorMessage("Upload a CSV file.");
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      return;
    } else if (file.name.split(".").pop() !== "csv") {
      setIsValid(false);
      setErrorMessage("Invalid file type. Please upload a CSV file.");
      // Clear the file input field
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      return;
    } else {
      setIsValid(true);
      parseCSVFile(file);
    }
  }
 
  function inputOnchange(event) {
    setErrorMessage("");
  }
 
  function handleFormCancel(event) {
    event.preventDefault();
    setConfirmModal(true);
    openPopup("Cancel");
  }
  function handleCloseConfirmYes() {
    setConfirmModal(false);
    setBulkUserModal(false);
  }
  function handleCloseConfirmNo() {
    setConfirmModal(false);
  }
 
  function handleUserNameTypeChange(value) {
    setUserNameType(value); // Update the selected username type
    // Reset the error message when the username type changes
    // setUserNameValidationMsg("");
    setErrorMessage("");
  }
 
  const openConfirmationModal = () => {
    setPopupConfirmModal(true);
  };
 
  const closeConfirmationModal = () => {
    setCancelConfirmModal(false);
    setPopupConfirmModal(false);
  };
 
  const openCancelConfirmModal = () => {
    setCancelConfirmModal(true);
    openPopup("Cancel");
  };
 
  const closeCancelConfirmationModal = () => {
    setCancelConfirmModal(false);
  };
 
  // function parseCSVFile(file) {
  //   setUserNameValidationMsg("");
  //   const reader = new FileReader();
  //   let emptyRowCount = 0;
  //   let errorCount = 0;
  //   let validCount = 0;
  //   let totalCount = 0;
  //   let headerError = 0;
  //   let errorMessages = []; // Array to store error messages
  //   let usernameRegex; // Declare usernameRegex here
  //   const uniqueUsernames = new Set();
 
  //   // Define expected headers
  //   const expectedHeaders = ["firstName", "username", "deviceType"];
 
  //   reader.onload = (event) => {
  //     const csvData = event.target.result;
  //     const lines = csvData.split("\n");
  //     const userData = [];
 
  //     // Extract headers from the CSV file
  //     const headers = lines[0].split(",");
 
  //     // Check if headers match expected headers
  //     if (
  //       !(
  //         headers.length === expectedHeaders.length &&
  //         expectedHeaders.every(
  //           (value, index) => value === headers[index].trim()
  //         )
  //       )
  //     ) {
  //       headerError++;
  //     }
 
  //     // Initialize usernameRegex based on userNameType
  //     switch (userNameType) {
  //       case "CUSTOM":
  //         setErrorMessage(
  //           "Custom username must have min-8 and max-15 characters"
  //         );
  //         usernameRegex = /^[a-zA-Z0-9]{8,15}$/;
  //         break;
  //       case "EMAIL_ADDRESS":
  //         setErrorMessage("Email should be like (e.g., app@motivai.tech)");
  //         usernameRegex = /^[^\s@]{1,50}@[^\s@]+\.[^\s@]+$/;
  //         break;
  //       case "IMEI_NUMBER":
  //         setErrorMessage("IMEI Number must have min-10 and max-40 characters");
  //         usernameRegex = /^[a-zA-Z0-9]{10,40}$/;
  //         break;
  //       case "MOBILE_NUMBER":
  //         setErrorMessage(
  //           "Mobile number must have 10 digits (e.g: 9876543210)"
  //         );
  //         usernameRegex = /^\d{10}$/;
  //         break;
  //       default:
  //         usernameRegex = /.*/; // Default regex to match any value
  //     }
 
  //     for (let i = 1; i < lines.length; i++) {
  //       const data = lines[i].split(",");
  //       if (data.length === headers.length) {
  //         const firstName = data[0].trim();
  //         const username = data[1].trim();
  //         const deviceType = data[2].trim();
 
  //         if (!firstName && !username && !deviceType) {
  //           emptyRowCount++;
  //         } else if (!username || !deviceType) {
  //           errorCount++;
  //         } else if (!usernameRegex.test(username)) {
  //           errorCount++;
  //         } else if (uniqueUsernames.has(username)) {
  //           errorCount++; // Increment error count if duplicate username found
  //         } else if (
  //           !(
  //             deviceType === "Ios" ||
  //             deviceType === "Android" ||
  //             deviceType === "IOS" ||
  //             deviceType === "ANDROID" ||
  //             deviceType === "ios" ||
  //             deviceType === "android" ||
  //             deviceType === "iOS"
  //           )
  //         ) {
  //           errorCount++;
  //         } else {
  //           validCount++;
  //         }
 
  //         // Add username to the set
  //         uniqueUsernames.add(username);
 
  //         totalCount++; // Increment total count for every processed rowF
  //         const user = {
  //           firstName: firstName,
  //           username: username,
  //           deviceType: deviceType,
  //         };
  //         userData.push(user);
  //       }
  //     }
 
  //     // console.log(`empty row count: ${emptyRowCount}`);
  //     // console.log(`error Count: ${errorCount}`);
  //     // console.log(`valid Count: ${validCount}`);
  //     // console.log(`total count: ${totalCount}`);
 
  //     // Check conditions for form submission
  //     if (errorCount < 1 && validCount > 0 && headerError < 1) {
  //       createBulkUser(file);
  //       setTimeout(() => 3500);
  //     } else if (headerError > 0) {
  //       errorMessages = [
  //         "Please check file header, It should be like firstName, username, deviceType in the first row of csv file.",
  //       ];
  //     } else {
  //       errorMessages = [
  //         "File contains errors. Please check and try again",
  //         "May be due to empty fields.",
  //         "May be due to invalid username type",
  //         "May be due to duplicate usernames.",
  //         "May be due to invalid device type",
  //       ];
  //     }
  //     setErrorMessage(errorMessages);
  //   };
 
  //   reader.readAsText(file);
  // }
 
  function parseCSVFile(file) {
    setUserNameValidationMsg("");
    const reader = new FileReader();
    let errorCount = 0;
    let validCount = 0;
    let headerError = 0;
    let errorMessages = []; // Array to store error messages
    let usernameRegex; // Declare usernameRegex here
    const uniqueUsernames = new Set();
    const validDeviceTypes = ["ios", "android", "android sdk", "ios sdk"];
    const validVehicleTypes = ["2w", "4w" ];
    const nameRegex = /^[a-zA-Z\s]*$/;
 
    // Define expected headers
    const expectedHeaders = ["firstName", "username", "deviceType", "vehicleType"];
 
    reader.onload = (event) => {
      const csvData = event.target.result;
      const lines = csvData.trim().split("\n");
      const userData = [];
 
      // Extract headers from the CSV file
      const headers = lines[0].split(",");
 
      // Check if headers match expected headers
      if (!(headers.length === expectedHeaders.length && expectedHeaders.every((value, index) => value === headers[index].trim()))) {
        errorMessages.push("Please check file header. It should be like firstName, username, deviceType, vehicleType in the first row of the CSV file.");
        setErrorMessage(errorMessages);
        return;
      }
 
      // Check if file is empty
      if (lines.length == 1) {
        if (!errorMessages.includes("File contains an empty row")) {
          errorMessages.push("File cannot be empty");
          errorCount++;
        }
      }
 
      // Initialize usernameRegex based on userNameType and set corresponding error message
      if (userNameType === "CUSTOM" && customValidation == "ALPHA_NUMERIC") {
        usernameRegex = /^[a-zA-Z0-9]{8,15}$/;
      } else if (userNameType === "CUSTOM" && customValidation == "NUMERIC") {
        usernameRegex = /^[0-9]{8,10}$/;
      } else if (userNameType === "EMAIL_ADDRESS") {
        usernameRegex = /^[^\s@]{1,50}@[^\s@]+\.[^\s@]+$/;
      } else if (userNameType === "IMEI_NUMBER") {
        usernameRegex = /^[a-zA-Z0-9]{10,20}$/;
      } else if (userNameType === "MOBILE_NUMBER") {
        usernameRegex = /^\d{10}$/;
      } else {
        usernameRegex = /.*/;
      }
 
      for (let i = 1; i < lines.length; i++) {
        const data = lines[i].split(",");
        const firstName = data[0].trim(); // Trim to remove leading/trailing whitespace
        const username = data[1].trim();
        const deviceType = data[2].trim();
        const vehicleType = data[3].trim();
 
        // Check for empty rows
        if (!username) {
          if (!errorMessages.includes("Username column contains empty values")) {
            errorMessages.push("Username column contains empty values");
            errorCount++;
          }
        } else if (!deviceType) {
          if (!errorMessages.includes("Device type column contains empty values")) {
            errorMessages.push("Device type column contains empty values");
            errorCount++;
          }
        } else if (uniqueUsernames.has(username)) {
          if (!errorMessages.includes("Username column contains duplicate values")) {
            errorMessages.push("Username column contains duplicate values");
            errorCount++;
          }
        } else if (!usernameRegex.test(username)) {
          let errorMessage;
          if (userNameType === "CUSTOM" && customValidation == "ALPHA_NUMERIC") {
            errorMessage = "Invalid Custom Username: Must be 8 to 15 characters long and include both letters and numbers.";
          } else if (userNameType === "CUSTOM" && customValidation == "NUMERIC") {
            errorMessage = "Invalid Custom Username: Must be 8 to 10 characters long and include only numbers.";
          } else if (userNameType === "EMAIL_ADDRESS") {
            errorMessage = "Invalid Email Address: Email should be like (e.g., app@motivai.tech)";
          } else if (userNameType === "MOBILE_NUMBER") {
            errorMessage = "Invalid Mobile Number: Mobile number must have 10 digits (e.g: 9876543210)";
          } else if (userNameType === "IMEI_NUMBER") {
            errorMessage = "Invalid IMEI Number: IMEI Number must have min-10 and max-20 characters";
          }
 
          if (errorMessage && !errorMessages.includes(errorMessage)) {
            errorMessages.push(errorMessage);
            errorCount++;
          }
        } else if (!validDeviceTypes.includes(deviceType.toLowerCase())) {
          if (!errorMessages.includes("Device type must be either iOS / Android / iOS SDK / Android SDK")) {
            errorMessages.push("Device type must be either iOS / Android / iOS SDK / Android SDK");
            errorCount++;
          }
        } else if (!validVehicleTypes.includes(vehicleType.toLowerCase())) {
          if (!errorMessages.includes("Vehicle type must be either 2W or 4W")) {
            errorMessages.push("Vehicle type must be either 2W or 4W");
            errorCount++;
          }
        } else if(!nameRegex.test(firstName)) {        
          if(!errorMessages.includes("First name must contain only alphabets and spaces")) {
             errorMessages.push("First name must contain only alphabets and spaces"); errorCount++; } 
        } else {
             validCount++;
        } 
          
 
        // Add username to the set
        uniqueUsernames.add(username);
 
        const user = {
          firstName: firstName,
          username: username,
          deviceType: deviceType,
          vehicleType: vehicleType,
        };
        userData.push(user);
      }
 
      // Check conditions for form submission
      if (errorCount < 1 && validCount > 0 && headerError < 1) {
        createBulkUser(file);
        setTimeout(() => 3500);
      }
      setErrorMessage(errorMessages);
    };
 
    reader.readAsText(file);
  }
  // _____________________________
 
  const [popupData, setPopUpData] = useState({
    Purpose: "",
    message: "",
    DataDetails: "",
  });
 
  const openPopup = (PopupType, Data) => {
    if (PopupType == "Cancel") {
      setPopUpData({
        ...popupData,
        Purpose: "Cancel",
        message: "you want to cancel ?",
      });
    } else {
      setPopUpData({
        ...popupData,
        Purpose: "Delete",
        message:
          "This action cannot be undone. The station will be removed from the list.",
        DataDetails: Data ? Data : "",
      });
    }
  };
 
  const customSample = [
    ["firstName", "username", "deviceType", "vehicleType"],
    ["John", "Johny001", "Android", "2W"],
    ["Smith", "Smith002", "iOS", "4W"],
    ["Chris", "Chris003", "Android", "2W"],
    ["Antonio", "Antonio004", "Android SDK", "2W"],
    ["Alexei", "Alexei005", "iOS SDK", "2W"]
  ];

  const mobileSample = [
    ["firstName", "username", "deviceType", "vehicleType"],
    ["John", "70000000001", "Android", "2W"],
    ["Smith", "8000000001", "iOS", "4W"],
    ["Devid", "9000000001", "Android SDK", "2W"],
    ["William", "6000000002", "iOS SDK", "2W"]
  ];

  let sample;
  if(validDivIds.includes(divId)){
    sample = mobileSample
  } else {
    sample = customSample
  }
 
  const downloadCSV = (filename, data) => {
    const csvContent = data.map(e => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, filename);
  };
  // _______________________________________________
 
  return (
    <div>
      {showLoader && <Loader />}
      <Modal
        isOpen={popupConfirmModal}
        style={{ overflowY: "scroll", ...customStyles }}
        overlayClassName="custom-modal-overlay"
      >
        <ModalHeader
          toggle={openCancelConfirmModal}
          style={{
            padding: "20px",
            paddingBottom: "10px",
            background: "#5156be1a",
            alignItems: "center",
          }}
        >
          <h5 style={{ fontSize: "20px" }}>NOTE</h5>
        </ModalHeader>
        <br />
        {/* <div className="line-divider"></div> */}
        <ModalBody className="p-2">
          <div>
            <ol>
              <li>Username type should be same for all users</li>
              <li>
                First row of your csv file should have the following headings
                respectively.
                <ul>
                  <li>firstName</li>
                  <li>username</li>
                  <li>deviceType</li>
                </ul>
              </li>
              <li>
                Empty fields in username and deviceType (other than firstName)
                will be considered as Invalid.
              </li>
              <li>Device type value should be iOS / Android / iOS SDK / Android SDK.</li>
              <li>Vehicle type value should be 2W or 4W.</li>
              <li>
                Ensure that the username is not already in use by any of your
                existing users.
              </li>
              {!validDivIds.includes(divId) && (
              <li>
                Default Password for Custom usertype will be{" "}
                <strong>Test@123</strong>.
              </li>
              )}
              <li>
                <Button type="button" className="p-0" color="link" style={{ marginBottom: "2px" }} onClick={() => downloadCSV('Sample.csv', sample)}>
                  Click here!
                </Button> to download a sample CSV file, and ensure the username format is as follows.
              </li>
              <Table bordered style={{ width: '400px' }}>
                <thead>
                  <tr>
                    <th className="p-2" style={{ width: '200px' }}>Username Type</th>
                    <th className="p-2" style={{ width: '200px' }}>Sample Username</th>
                  </tr>
                </thead>
                <tbody>
                  {!validDivIds.includes(divId) && (
                    <>
                  <tr>
                    <td className="p-2" style={{ width: '200px' }}>Custom</td>
                    <td className="p-2" style={{ width: '200px' }}>johny001</td>
                  </tr>
                  <tr>
                    <td className="p-2" style={{ width: '200px' }}>Email Address</td>
                    <td className="p-2" style={{ width: '200px' }}>johny001@domain.com</td>
                  </tr>
                  <tr>
                    <td className="p-2" style={{ width: '200px' }}>IMEI Number</td>
                    <td className="p-2" style={{ width: '200px' }}>3557890098766670</td>
                  </tr>
                  </>
                  )}
                  <tr>
                    <td className="p-2" style={{ width: '200px' }}>Mobile Number</td>
                    <td className="p-2" style={{ width: '200px' }}>7700000001</td>
                  </tr>
                </tbody>
              </Table>
            </ol>
            <p style={{ marginLeft: "20px" }}>
              Please ensure that you adhere to all the aforementioned
              instructions accurately.
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <button
                style={{ marginBottom: "15px" }}
                className="btn btn-primary"
                onClick={openBulkUserModal}
              >
                Proceed
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
 
      <Modal
        isOpen={cancelConfirmModal}
        // onRequestClose={closeConfirmationModal}
        toggle={closeCancelConfirmationModal}
        contentLabel="Cancelpopup Modal"
        style={{
          content: {
            height: "190px",
            width: "340px",
            margin: "auto",
            borderRadius: "10px",
            padding: "0px",
            backgroundColor: "#fff",
          },
          overlay: { backgroundColor: "rgba(0,0,0,0.5)" }, // /* Black background with opacity */
        }}
      >
        <ModalHeader
          style={{
            height: "40px",
            backgroundColor: "#5156BE",
            width: "100%",
            display: "felx",
            justifyContent: "center",
          }}
        >
          <span style={{ fontWeight: 700, fontSize: "20px", color: "#fff" }}>
            Confirm Action{" "}
          </span>
        </ModalHeader>
 
        <ConformationPopup
          className="text-right d-flex align-items-center "
          toggle={closeCancelConfirmationModal}
          Data={popupData}
          formSubmit={closeConfirmationModal}
          cancelSubmit={closeCancelConfirmationModal}
        />
      </Modal>
 
      <ToastContainer style={{ width: "auto" }} />
      {/* <button
        className="button m-2"
        onClick={() => setNoteBulk(true)}
        style={{
          backgroundColor: "white",
          color: "black",
          border: "1px solid #555555",
        }}
      >
        <i className="fa fa-plus" style={{ fontSize: "10px" }}></i>
        &nbsp;Add Bulk User
      </button> */}
      <div>
      <div
        className="d-flex align-items-center justify-content-center"
        // style={{ height: "40px" }}
      >
        <button
          type="button"
          className="btn btn-sm m-1"
          // style={{display:"none"}}
          // onClick={() => openConfirmationModal}
 
          onClick={openConfirmationModal}
        >
          <img
            src={AddGroupIcons}
            alt="addGroupIcons"
            style={{ height: "25px", width: "25px" }}
            data-tooltip-id="AddBulkUserTool"
          />
          {/* <ReactTooltip
            id="AddBulkUserTool"
            place="bottom"
            content="Add Bulk User"
            style={{ background: "black" }}
          /> */}
        </button>
        
      </div>
      <p style={{fontSize:"8px",marginBottom:"0px",whiteSpace:"nowrap",marginTop:"0px",marginBottom:"0px",textAlign:"center"}}>Add Bulk<br/>User</p>
      {/* <p style={{fontSize:"8px",marginBottom:"0px",fontWeight:700,whiteSpace:"nowrap",marginTop:"0px",marginBottom:"0px"}}>User</p> */}
      </div>
 
      <Modal
        isOpen={bulkUserModal}
        style={{ overflowY: "scroll", ...customStylesBulk }}
        overlayClassName="custom-modal-overlay"
      >
        <Form onSubmit={handleSubmit}>
          <ModalHeader
            toggle={handleFormCancel}
            style={{
              padding: "20px",
              paddingBottom: "10px",
              background: "#5156be1a",
              alignItems: "center",
            }}
          >
            <h5 style={{ fontSize: "20px" }}>Add Bulk User</h5>
          </ModalHeader>
          {/* <div className="line-divider"></div> */}
          {/* <br/> */}
          <ModalBody
            style={{
              padding: "20px",
            }}
          >
            <FormGroup>
              <Label>Username Type</Label>
              <select
                className="form-select"
                value={userNameType}
                onChange={(e) => handleUserNameTypeChange(e.target.value)}
              >
              {availableOptions.includes('CUSTOM') && <option value="CUSTOM">Custom</option>}
              {availableOptions.includes('EMAIL_ADDRESS') && <option value="EMAIL_ADDRESS">Email Address</option>}
              {availableOptions.includes('IMEI_NUMBER') && <option value="IMEI_NUMBER">IMEI Number</option>}
              {availableOptions.includes('MOBILE_NUMBER') && <option value="MOBILE_NUMBER">Mobile Number</option>}
              </select>
              {(userNameType === "CUSTOM" && customValidation === "ALPHA_NUMERIC")? (
                <p style={{ marginTop: "4px" }}>
                  Note: Custom username must have min-8 and max-15 characters
                </p>
              ) : (userNameType === "CUSTOM" && customValidation === "NUMERIC")? (
                <p style={{ marginTop: "4px" }}>
                  Note: Custom username must have min-8 and max-10 characters
                </p>
              ) : userNameType === "IMEI_NUMBER" ? (
                <p style={{ marginTop: "4px" }}>
                  Note: IMEI Number must have min-10 and max-20 characters{" "}
                </p>
              ) : userNameType === "EMAIL_ADDRESS" ? (
                <p style={{ marginTop: "4px" }}>
                  Note: Email should be like (e.g: app@motivai.tech)
                </p>
              ) : userNameType === "MOBILE_NUMBER" ? (
                <p style={{ marginTop: "4px" }}>
                  Note: Mobile number must have 10 digits (e.g: 9876543210)
                </p>
              ) : null}
              {userNameValidationMsg && (
                <p style={{ color: "red" }}>{userNameValidationMsg}</p>
              )}
            </FormGroup>
            {userNameType == "CUSTOM" && (
              <FormGroup>
                <Label>Custom Username Format</Label>
                <select
                  className="form-select"
                  value={customValidation}
                  onChange={(e) => setCustomValidation(e.target.value)}
                >
                  <option value={"ALPHA_NUMERIC"}>Alphanumeric</option>
                  <option value={"NUMERIC"}>Numeric</option>
                </select>
              </FormGroup>
            )}
            {userNameType !== "IMEI_NUMBER" && (
              <FormGroup>
                <Label>Enable 2FA (Two-Factor authentication)</Label>
                <select
                  className="form-select"
                  value={twoFa}
                  onChange={(e) => setTwoFa(e.target.value)}
                >
                  <option value={"NO"}>No</option>
                  <option value={"YES"}>Yes</option>
                </select>
              </FormGroup>
            )}
            <FormGroup className="mb-3">
              <Label for="csvFile">Upload CSV file</Label>
              <Input
                type="file"
                name="file"
                id="csvFile"
                accept=".csv"
                onChange={inputOnchange}
                ref={fileInputRef}
                invalid={!isValid}
              />
              {errorMessage && (
                <div style={{ color: "red" }}>
                  {Array.isArray(errorMessage) && errorMessage.length > 1 ? (
                    <ul>
                      {errorMessage.map((msg, index) => (
                        <li key={index}>{msg}</li>
                      ))}
                    </ul>
                  ) : (
                    errorMessage
                  )}
                </div>
              )}
            </FormGroup>
          </ModalBody>
          <ModalFooter className="d-flex align-items-center justify-content-center">
            <button
              style={{ marginBottom: "15px" }}
              className="btn btn-primary btn-sm "
              type="submit"
            >
              Submit
            </button>
            <button
              style={{ marginLeft: "15px", marginBottom: "15px" }}
              className="btn btn-secondary btn-sm "
              onClick={handleFormCancel}
            >
              Cancel
            </button>
          </ModalFooter>
        </Form>
      </Modal>
 
      <Modal
        isOpen={confirmModal}
        toggle={handleCloseConfirmNo}
        style={{
          content: {
            height: "190px",
            width: "340px",
            margin: "auto",
            borderRadius: "10px",
            padding: "0px",
          },
          overlay: { backgroundColor: "rgba(0,0,0,0.5)" }, // /* Black background with opacity */
        }}
      >
        <ModalHeader
          style={{
            height: "40px",
            backgroundColor: "#5156BE",
            width: "100%",
            display: "felx",
            justifyContent: "center",
          }}
        >
          <span style={{ fontWeight: 700, fontSize: "20px", color: "#fff" }}>
            Confirm Action{" "}
          </span>
        </ModalHeader>
        <ConformationPopup
          Data={popupData}
          formSubmit={handleCloseConfirmYes}
          cancelSubmit={handleCloseConfirmNo}
        />
      </Modal>
    </div>
  );
}
