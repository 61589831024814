// import React, { useState } from 'react';
// import html2canvas from 'html2canvas';
// import { jsPDF } from 'jspdf';
// import Loader from '../Common/loader';
// import { Tooltip as ReactTooltip } from 'react-tooltip';
// import { useLocation } from "react-router-dom";

// export default function ExportScreenPdf({ containerIds, fileName }) {
//   const [showLoader, setShowLoader] = useState(0);
//   const location = useLocation();
//   let widthMinus = 90;
//   if (location.pathname == "/adminDashboard/RiskSummary") {
//     widthMinus = 90;
//   } else {
//     widthMinus = 90;
//   }

//   const generatePDF = async () => {
//     setShowLoader(1);

//     try {
//       // Create a new jsPDF instance
//       const pdf = new jsPDF();

//       // Set margins for the entire document
//       const marginLeft = 5;
//       const marginTop = 5;
//       const pageWidth = pdf.internal.pageSize.getWidth();
//       const pageHeight = pdf.internal.pageSize.getHeight();

//       // Iterate through the containers and add each to a separate page
//       for (let i = 0; i < containerIds.length; i++) {
//         const containerId = containerIds[i];

//         // Capture the HTML element to a canvas
//         const canvas = await html2canvas(document.getElementById(containerId), {
//           scale: 1, // Optional: You can adjust the scale if needed
//           useCORS: true,
//         });

//         // Convert the canvas to a data URL
//         const imgData = canvas.toDataURL('image/png');

//         // Add a new page for each container except the first one
//         if (i > 0) {
//           pdf.addPage();
//         }

//         // Add the image to the PDF with margins
//         pdf.addImage(imgData, 'JPEG', marginLeft, marginTop, pageWidth - 2 * marginLeft, pageHeight - widthMinus * marginTop);
        
//       }

//       // Save or display the PDF
//       pdf.save(fileName + ".pdf");
//     } finally {
//       setShowLoader(0);
//     }
//   };

//   return (
//     <div>
//       {showLoader > 0 && <Loader />}
//       <button className='btn btn-primary btn-sm' style={{whiteSpace:'nowrap'}} onClick={generatePDF} data-tooltip-id="es">
//         Export Screen
//       </button>
//       <ReactTooltip id="es" place="bottom" content="Export Screen" style={{ zIndex: "1000", position: 'absolute' }} />
//     </div>
//   );
// }






// import React, { useState } from 'react';
// import html2canvas from 'html2canvas';
// import { jsPDF } from 'jspdf';
// import Loader from '../Common/loader';
// import { Tooltip as ReactTooltip } from 'react-tooltip';
// import { useLocation } from "react-router-dom";

// export default function ExportScreenPdf({ containerIds, fileName }) {
//   const [showLoader, setShowLoader] = useState(0);
//   const location = useLocation();

//   const generatePDF = async () => {
//     setShowLoader(1);

//     try {
//       // Create a new jsPDF instance
//       const pdf = new jsPDF();

//       // Define margins
//       const leftMargin = 8;
//       const rightMargin = 5;
//       const topMargin = 7;
//       const bottomMargin = 7;

//       // Get the PDF page width and height
//       const pdfPageWidth = pdf.internal.pageSize.getWidth();
//       const pdfPageHeight = pdf.internal.pageSize.getHeight();

//       // Calculate the available width and height for the image
//       const contentWidth = pdfPageWidth - leftMargin - rightMargin;
//       const contentHeight = pdfPageHeight - topMargin - bottomMargin;

//       // Iterate through the containers and add each to a separate page
//       for (let i = 0; i < containerIds.length; i++) {
//         const containerId = containerIds[i];
//         const element = document.getElementById(containerId);

//         if (element) {
//           // Get the element's dimensions
//           const elementWidth = element.clientWidth;
//           const elementHeight = element.clientHeight;

//           // Capture the HTML element to a canvas
//           const canvas = await html2canvas(element, {
//             scale: 3, // Higher scale for better quality
//             useCORS: true,
//             width: elementWidth,
//             height: elementHeight,
//             windowWidth: elementWidth,
//             windowHeight: elementHeight,
//           });

//           // Convert the canvas to a data URL
//           const imgData = canvas.toDataURL('image/png');

//           // Calculate the scaling ratio to fit within available width and height
//           const widthRatio = contentWidth / elementWidth;
//           const heightRatio = contentHeight / elementHeight;
//           const ratio = Math.min(widthRatio, heightRatio);

//           // Calculate scaled image dimensions
//           const imgWidth = elementWidth * ratio;
//           const imgHeight = elementHeight * ratio;

//           // Add a new page for each container except the first one
//           if (i > 0) {
//             pdf.addPage();
//           }

//           // Add the image to the PDF with specified margins
//           pdf.addImage(imgData, 'PNG', leftMargin, topMargin, imgWidth, imgHeight);
//         }
//       }

//       // Save or display the PDF
//       pdf.save(fileName + ".pdf");
//     } finally {
//       setShowLoader(0);
//     }
//   };

//   return (
//     <div>
//       {showLoader > 0 && <Loader />}
//       <button className='btn btn-primary btn-sm' style={{whiteSpace:'nowrap'}} onClick={generatePDF} data-tooltip-id="es">
//         Export Screen
//       </button>
//       <ReactTooltip id="es" place="bottom" content="Export Screen" style={{ zIndex: "1000", position: 'absolute' }} />
//     </div>
//   );
// }





import React, { useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import Loader from '../Common/loader';
// import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useLocation } from "react-router-dom";

const ExportScreenDownloadPdf = {
  generatePDF: async (containerIds, fileName, pageSize = 'A2') => {
    // setShowLoader(1);

    try {
      // Create a new jsPDF instance
      const pdf = new jsPDF({
        orientation: 'portrait', // or 'landscape'
        unit: 'mm',
        format: pageSize,
      });

      // Define margins
      const leftMargin = 8;
      const rightMargin = 5;
      const topMargin = 7;
      const bottomMargin = 7;

      // Get the PDF page width and height
      const pdfPageWidth = pdf.internal.pageSize.getWidth();
      const pdfPageHeight = pdf.internal.pageSize.getHeight();

      // Calculate the available width and height for the image
      const contentWidth = pdfPageWidth - leftMargin - rightMargin;
      const contentHeight = pdfPageHeight - topMargin - bottomMargin;

      // Iterate through the containers and add each to a separate page
      for (let i = 0; i < containerIds.length; i++) {
        const containerId = containerIds[i];
        const element = document.getElementById(containerId);

        if (element) {
          // Get the element's dimensions
          const elementWidth = element.clientWidth;
          const elementHeight = element.clientHeight;

          // Capture the HTML element to a canvas
          const canvas = await html2canvas(element, {
            scale: 2, // Higher scale for better quality
            useCORS: true,
            width: elementWidth,
            height: elementHeight,
            windowWidth: elementWidth,
            windowHeight: elementHeight,
          });

          // Convert the canvas to a data URL
          const imgData = canvas.toDataURL('image/png');

          // Calculate the scaling ratio to fit within available width and height
          const widthRatio = contentWidth / elementWidth;
          const heightRatio = contentHeight / elementHeight;
          const ratio = Math.min(widthRatio, heightRatio);

          // Calculate scaled image dimensions
          const imgWidth = elementWidth * ratio;
          const imgHeight = elementHeight * ratio;

          // Calculate the x-coordinate to center the image
          const x = leftMargin + (contentWidth - imgWidth) / 2;
          const y = topMargin; // Top margin is fixed

          // Add a new page for each container except the first one
          if (i > 0) {
            pdf.addPage();
          }

          // Add the image to the PDF with specified margins and centered horizontally
          pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);
        }
      }

      // Save or display the PDF
      pdf.save(fileName + ".pdf");
    } finally {
      setShowLoader(0);
    }
  }
}
export default ExportScreenDownloadPdf;

// export default function ExportScreenPdf({ containerIds, fileName, pageSize = 'A2' }) {
//   const [showLoader, setShowLoader] = useState(0);
//   const location = useLocation();

//   const generatePDF = async (containerIds, fileName, pageSize = 'A2') => {
//     // setShowLoader(1);

//     try {
//       // Create a new jsPDF instance
//       const pdf = new jsPDF({
//         orientation: 'portrait', // or 'landscape'
//         unit: 'mm',
//         format: pageSize,
//       });

//       // Define margins
//       const leftMargin = 8;
//       const rightMargin = 5;
//       const topMargin = 7;
//       const bottomMargin = 7;

//       // Get the PDF page width and height
//       const pdfPageWidth = pdf.internal.pageSize.getWidth();
//       const pdfPageHeight = pdf.internal.pageSize.getHeight();

//       // Calculate the available width and height for the image
//       const contentWidth = pdfPageWidth - leftMargin - rightMargin;
//       const contentHeight = pdfPageHeight - topMargin - bottomMargin;

//       // Iterate through the containers and add each to a separate page
//       for (let i = 0; i < containerIds.length; i++) {
//         const containerId = containerIds[i];
//         const element = document.getElementById(containerId);

//         if (element) {
//           // Get the element's dimensions
//           const elementWidth = element.clientWidth;
//           const elementHeight = element.clientHeight;

//           // Capture the HTML element to a canvas
//           const canvas = await html2canvas(element, {
//             scale: 2, // Higher scale for better quality
//             useCORS: true,
//             width: elementWidth,
//             height: elementHeight,
//             windowWidth: elementWidth,
//             windowHeight: elementHeight,
//           });

//           // Convert the canvas to a data URL
//           const imgData = canvas.toDataURL('image/png');

//           // Calculate the scaling ratio to fit within available width and height
//           const widthRatio = contentWidth / elementWidth;
//           const heightRatio = contentHeight / elementHeight;
//           const ratio = Math.min(widthRatio, heightRatio);

//           // Calculate scaled image dimensions
//           const imgWidth = elementWidth * ratio;
//           const imgHeight = elementHeight * ratio;

//           // Calculate the x-coordinate to center the image
//           const x = leftMargin + (contentWidth - imgWidth) / 2;
//           const y = topMargin; // Top margin is fixed

//           // Add a new page for each container except the first one
//           if (i > 0) {
//             pdf.addPage();
//           }

//           // Add the image to the PDF with specified margins and centered horizontally
//           pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);
//         }
//       }

//       // Save or display the PDF
//       pdf.save(fileName + ".pdf");
//     } finally {
//       setShowLoader(0);
//     }
//   };

//   return (
//     <div>
//       {showLoader > 0 && <Loader />}
//       <button className='btn btn-primary btn-sm' style={{whiteSpace:'nowrap'}} onClick={generatePDF} data-tooltip-id="es">
//         Export Screen
//       </button>
//       {/* <ReactTooltip id="es" place="bottom" content="Export Screen" style={{ zIndex: "1000", position: 'absolute' }} /> */}
//     </div>
//   );
// }
