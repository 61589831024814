import React, { useState,useEffect } from "react"
import ReactApexChart from "react-apexcharts"
import ReactEcharts from "echarts-for-react"

const LineColumnArea = ({drivingDataList,xAxisDataList}) => {
  
  const options = {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [1.4],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    colors: ["#5156BE", "#0B43F1", "#34c38f","#F28C28"],

    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },

    markers: {
      size: 0,
    },
    legend: {      
      offsetY: 4,
      fontSize: "70px !important",
      labels: {
        colors: "#0095FF",
        fontSize: "7px",
        useSeriesColors: false,
        style: {
          color: "#0095FF", // Set your preferred legend text color here
          fontSize: "7px",
          fontFamily: "Segoe UI",
        },
      },
    },
    xaxis: {
      categories:xAxisDataList,
      show:true,
      title: {
        text: "Ride",
        position: "bottom",

      }, style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: "Segoe UI",
        fontWeight: 500,
        cssClass: "apexcharts-xaxis-title",
      },
      floating: false,
      labels: {
        show: true,
        rotate: -10,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: true,
  
        style: {
          fontWeight: 400,
          fontSize: "9px",
          fontFamily: "Segoe UI",
          cssClass: "apexcharts-xaxis-label",
        },
        offsetX: 0,
        offsetY: 0,
        categories: [],
      },
    },
 
    
    yaxis: {
      min:0, 
      max:100,
      show:true,      
      title: {
        text: "Score",
        textStyle: {
          fontFamily: "IBM Plex Sans, sans-serif",
        },
      },
      
    },
    // tooltip: {
    //   shared: true,
    //   intersect: false,
    //   custom: function ({ series, seriesIndex, dataPointIndex }) {
    //     console.log("series tooltip :",series)
    //     const customData = drivingDataList.rideName;
    //     return (
    //       '<div class="arrow_box" style="padding:10px;">' +
    //       "<span>" +
    //       "Score " +
    //       ": " +
    //       series[seriesIndex][dataPointIndex] +
    //       "%" +
    //       "</span>" +
    //       "<br/>" +
    //       "</div>"
    //     );
    //   },
    // y: {
    //   formatter: function (y) {
    //     console.log("series tootltip: " , y)
    //     if (typeof y !== "undefined") {
    //       return y.toFixed(0) + "%"
    //     }
    //     return y
    //   },
    // },
    // },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          // console.log("series tootltip: " , y)
          if (typeof y !== "undefined") {
            return y.toFixed(0) + "%"
          }
          return y
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  }
  const modifiedDrivingDataList = drivingDataList.map(data => ({
    name: `${data.name} (${data.username})`,
    data: data.data
  }));

  const multiUserDrivingOption = {
    xAxis: {
      type: 'category',
    },
    yAxis: {
      type: 'value'
    },
    legend:{
      show:true
    },
    series:modifiedDrivingDataList
  };

  const [multiLineData, setMultiLineData] = useState(multiUserDrivingOption);

useEffect(()=>{
  setMultiLineData({
    xAxis: {
      type: 'category',
    },
    yAxis: {
      type: 'value'
    },
    legend:{
      show:true
    },
    series:modifiedDrivingDataList
  })

},[drivingDataList])


  return (
    <div className="custom-xaxis-text custom-yaxis-text apexcharts-legend-text">
    <ReactApexChart
      options={options}
      series={modifiedDrivingDataList}
      type="line"
      height="441px"
    />
    {/* <ReactEcharts style={{ height: "395px" }} option={multiLineData} /> */}
    </div>
  )
}

export default LineColumnArea
